import { hasValue, stringIsPositiveInteger } from '@lego/mst-error-utilities';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DatePicker } from '@mui/lab';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { format, isValid } from 'date-fns';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import { SimpleDialog } from '../../../components/shared/SimpleDialog';
import { useTranslation } from '../../../utility/i18n/translation';
import { useGMSnackbar } from '../../../utility/snackbar';
import { AddDimmingDialogMutation } from './__generated__/AddDimmingDialogMutation.graphql';

const TEXT_MAX_CHARS = 30;

export type DimmingMutationSharedVariables = Omit<
  AddDimmingDialogMutation['variables']['input'],
  'mouldId' | 'createdAt'
>;
export const AddDimmingDialog: FC<{
  onDismiss: () => void;
  open: boolean;
  mouldId: string;
}> = ({ onDismiss, open, mouldId }) => {
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();

  const [addVariables, setAddVariables] =
    useState<DimmingMutationSharedVariables>();

  const [commitMutation, loading] =
    useMutation<AddDimmingDialogMutation>(graphql`
      mutation AddDimmingDialogMutation($input: MutationMouldAddDimmingInput!) {
        mouldAddDimming(input: $input) {
          __typename
          ... on InvalidCavityNumberError {
            cavityNumber
          }
          ... on DimmingNeedsRemovalDateError {
            message
          }
          ... on DimmingExistsForCavityError {
            cavityNumber
          }
          ... on MutationMouldAddDimmingSuccess {
            data {
              __typename
              ... on Mould {
                dimmings {
                  count
                  details {
                    ...TDDimmingDetails_dimmingDetails
                  }
                }
              }
              id
            }
          }
        }
      }
    `);

  const variablesValid = isDimmingAddOrUpdateValid(addVariables);
  const onSavePressed = useCallback(() => {
    if (variablesValid && addVariables) {
      commitMutation({
        variables: {
          input: {
            ...addVariables,
            mouldId,
            createdAt: format(new Date(), 'yyyy-MM-dd'),
          },
        },
        onCompleted: (result) => {
          if (
            result.mouldAddDimming?.__typename === 'InvalidCavityNumberError'
          ) {
            showSnack({
              message: translate(
                'TICKET_DETAILS.CARDS.DIMMINGS.ADD.INVALID_CAVITY_SNACK',
                'Can not add a dimming to cavity number {{ cavityNumber }} for this mould',
                { cavityNumber: result.mouldAddDimming.cavityNumber }
              ),
              variant: 'warning',
            });
            return;
          }
          if (
            result.mouldAddDimming?.__typename ===
            'DimmingNeedsRemovalDateError'
          ) {
            showSnack({
              message: translate(
                'TICKET_DETAILS.CARDS.DIMMINGS.NON_PERM_NEEDS_REMOVAL_DATE',
                'A non permanent dimming needs to provide an expected removal date'
              ),
              variant: 'warning',
            });
            return;
          }
          if (
            result.mouldAddDimming?.__typename === 'DimmingExistsForCavityError'
          ) {
            showSnack({
              message: translate(
                'TICKET_DETAILS.CARDS.DIMMINGS.ADD.DIMMING_EXISTS_FOR_CAVITY',
                'Dimming for cavity number {{ cavityNumber }} already exists on this mould',
                { cavityNumber: result.mouldAddDimming.cavityNumber }
              ),
              variant: 'warning',
            });
            return;
          }
          setAddVariables(undefined);
          onDismiss();
        },
      });
    }
  }, [
    addVariables,
    commitMutation,
    mouldId,
    onDismiss,
    showSnack,
    translate,
    variablesValid,
  ]);

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={open}
      title={translate(
        'TICKET_DETAILS.CARDS.DIMMINGS.ADD.TITLE',
        'Add dimming'
      )}
      primaryAction={{
        primaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.DIMMINGS.ADD.SAVE',
          'Save'
        ),
        primaryActionPressed: onSavePressed,
        primaryActionLoading: loading,
        primaryActionDisabled: !hasValue(addVariables) || !variablesValid,
      }}
      secondaryAction={{
        secondaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.DIMMINGS.ADD.CANCEL',
          'Cancel'
        ),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      content={{
        type: 'node',
        node: (
          <AddOrEditDimmingDialogContent
            onVariablesChange={setAddVariables}
            currentVariables={addVariables}
            variant="add"
          />
        ),
      }}
      maxWidth={'md'}
    />
  );
};

type TypeOption = 'TEMPORARY' | 'PERMANENT';

const LOCAL_DATE_FORMAT = 'yyyy-MM-dd';
const LABEL_WIDTH = 3;
export const AddOrEditDimmingDialogContent: FC<{
  currentVariables: DimmingMutationSharedVariables | undefined;
  onVariablesChange: (
    value: DimmingMutationSharedVariables | undefined
  ) => void;
  variant: 'add' | 'edit';
}> = ({ currentVariables, onVariablesChange, variant }) => {
  const { translate } = useTranslation();
  const [cavityNumber, setCavityNumber] = useState<number | undefined>(
    currentVariables?.cavityNumber && currentVariables.cavityNumber > 0
      ? currentVariables.cavityNumber
      : undefined
  );

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [expectedRemovalStringIsDate, setExpectedRemovalStringIsDate] =
    useState(
      hasValue(currentVariables?.expectedRemovalDescription)
        ? isValid(new Date(currentVariables?.expectedRemovalDescription ?? ''))
        : true
    );

  const [expectedRemovalFreeText, setExpectedRemovalFreeText] = useState(
    hasValue(currentVariables?.expectedRemovalDescription) &&
      !isValid(new Date(currentVariables?.expectedRemovalDescription ?? ''))
      ? currentVariables?.expectedRemovalDescription
      : ''
  );
  const [reason, setReason] = useState<string>(currentVariables?.reason ?? '');

  const [expectedRemovalDate, setExpectedRemovalDate] = useState<string | null>(
    () => {
      const res =
        hasValue(currentVariables) &&
        hasValue(currentVariables.expectedRemovalDescription) &&
        isValid(new Date(currentVariables?.expectedRemovalDescription ?? ''))
          ? currentVariables.expectedRemovalDescription
          : null;
      return res;
    }
  );

  const [type, setType] = useState<TypeOption>(
    currentVariables?.permanent ? 'PERMANENT' : 'TEMPORARY'
  );

  useEffect(() => {
    const dateInput = () => {
      if (type === 'PERMANENT') {
        return null;
      }

      if (expectedRemovalStringIsDate && expectedRemovalDate) {
        return format(new Date(expectedRemovalDate), LOCAL_DATE_FORMAT);
      }

      if (!expectedRemovalStringIsDate && expectedRemovalFreeText) {
        return expectedRemovalFreeText;
      }
      return null;
    };

    onVariablesChange({
      cavityNumber: cavityNumber ?? -1,
      permanent: type === 'PERMANENT' ? true : false,
      reason,
      expectedRemovalDescription: dateInput(),
    });
  }, [
    cavityNumber,
    expectedRemovalDate,
    reason,
    onVariablesChange,
    type,
    expectedRemovalStringIsDate,
    expectedRemovalFreeText,
  ]);

  const onCavityChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setCavityNumber(undefined);
      }
      if (stringIsPositiveInteger(e.target.value)) {
        setCavityNumber(Number.parseInt(e.target.value));
      }
    },
    []
  );

  const onReasonChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setReason(e.target.value);
    },
    []
  );

  const onDateChanged = useCallback((newValue: string | null) => {
    if (isValid(newValue)) {
      setExpectedRemovalDate(newValue);
    } else {
      setExpectedRemovalStringIsDate(false);
    }
  }, []);

  const onDateTextFieldChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setExpectedRemovalFreeText(e.target.value);
      setExpectedRemovalStringIsDate(false);
    },
    []
  );

  const onTypeChanged = useCallback((event: SelectChangeEvent) => {
    setType(event.target.value as TypeOption);
  }, []);

  const onPlainTextCalendarAdornmentClicked = useCallback(() => {
    setExpectedRemovalStringIsDate(true);
    setDatePickerOpen(true);
  }, []);

  const onDatePickerAccept = useCallback(() => {
    setExpectedRemovalStringIsDate(true);
    setDatePickerOpen(false);
  }, []);

  const onDatePickerOpenClicked = useCallback(() => {
    setDatePickerOpen(true);
  }, []);

  return (
    <Grid container spacing={3}>
      {/* Cavity number */}
      <Grid item container alignItems="center">
        <Grid item xs={LABEL_WIDTH}>
          <Typography>
            {translate(
              'TICKET_DETAILS.CARDS.DIMMINGS.ADD.CAVITY',
              'Cavity number'
            )}
          </Typography>
        </Grid>
        <Grid item>
          {variant === 'add' ? (
            <TextField
              autoFocus
              variant="outlined"
              onChange={onCavityChanged}
              value={cavityNumber ?? ''}
              inputProps={{
                maxLength: 3,
              }}
              helperText={translate(
                'TICKET_DETAILS.CARDS.DIMMINGS.ADD.CAVITY_HELPER_TEXT',
                'Write the actual cavity number, e.g. 3 or 12'
              )}
            />
          ) : (
            <Typography>{currentVariables?.cavityNumber ?? ''}</Typography>
          )}
        </Grid>
      </Grid>
      {/* Reason */}
      <Grid item container alignItems="flex-start">
        <Grid item xs={LABEL_WIDTH} mt={1}>
          <Typography>
            {translate('TICKET_DETAILS.CARDS.DIMMINGS.ADD.REASON', 'Reason')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoFocus={variant === 'edit'}
            variant="outlined"
            fullWidth
            onChange={onReasonChanged}
            inputProps={{
              maxLength: TEXT_MAX_CHARS,
            }}
            defaultValue={reason}
            helperText={`${reason.length}/${TEXT_MAX_CHARS}`}
            FormHelperTextProps={{ sx: { alignSelf: 'flex-end' } }}
          />
        </Grid>
      </Grid>
      {/* Type */}
      <Grid item container alignItems="center">
        <Grid item xs={LABEL_WIDTH}>
          <Typography>
            {translate('TICKET_DETAILS.CARDS.DIMMINGS.ADD.TYPE', 'Type')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ minWidth: 220 }}>
            <Select value={type} onChange={onTypeChanged}>
              <MenuItem value={'TEMPORARY'}>
                {translate(
                  'TICKET_DETAILS.CARDS.DIMMINGS.TEMPORARY',
                  'TEMPORARY'
                )}
              </MenuItem>
              <MenuItem value={'PERMANENT'}>
                {translate(
                  'TICKET_DETAILS.CARDS.DIMMINGS.PERMANENT',
                  'PERMANENT'
                )}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Removal */}
      <Grid item container alignItems="center">
        <Grid item xs={LABEL_WIDTH}>
          <Typography>
            {translate(
              'TICKET_DETAILS.CARDS.DIMMINGS.ADD.EXPECTED_REMOVAL',
              'Expected removal'
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {expectedRemovalStringIsDate ? (
            <DatePicker
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: '100%' }}
                  onChange={onDateTextFieldChanged}
                />
              )}
              clearable
              ignoreInvalidInputs={true}
              onChange={onDateChanged}
              value={expectedRemovalDate}
              disabled={type === 'PERMANENT'}
              onAccept={onDatePickerAccept}
              open={datePickerOpen}
              disablePast
              onOpen={onDatePickerOpenClicked}
            />
          ) : (
            <TextField
              autoFocus
              value={type === 'PERMANENT' ? '' : expectedRemovalFreeText}
              onChange={onDateTextFieldChanged}
              fullWidth
              disabled={type === 'PERMANENT'}
              helperText={`${
                expectedRemovalFreeText?.length ?? 0
              }/${TEXT_MAX_CHARS}`}
              FormHelperTextProps={{ sx: { alignSelf: 'flex-end' } }}
              inputProps={{
                maxLength: TEXT_MAX_CHARS,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={onPlainTextCalendarAdornmentClicked}
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const isDimmingAddOrUpdateValid = (
  variables: DimmingMutationSharedVariables | undefined
): boolean => {
  if (!variables) {
    return false;
  }
  const { cavityNumber, reason, expectedRemovalDescription, permanent } =
    variables;
  const cavityValid = hasValue(cavityNumber) && cavityNumber > 0;
  const reasonValid =
    hasValue(reason) && reason.length > 0 && reason.length <= 30;
  const removalValid = permanent ? true : hasValue(expectedRemovalDescription);

  return cavityValid && reasonValid && removalValid;
};
