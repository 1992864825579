import { gql } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import {
  CARD_DATE_FORMAT,
  useDateFromMiddlewareWithLocale,
} from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { PERMISSIONS_CAN_ADD_ERROR_DESCRIPTION } from '../../utility/permissions/ticket';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { CardWithTitle } from '../shared/CardWithTitle';
import { ImageRow, IMAGE_ROW_FRAGMENT } from '../shared/ImageRow';
import { TextWithLabel } from '../shared/TextWithLabel';
import {
  TicketCardCoding,
  TICKET_CARD_CODING_FRAGMENT,
} from '../ticket-card/components/TicketCardCoding';
import { AddErrorDescriptionDialog } from './TDAddErrorDescriptionDialog';
import { TDWidgetDialogButton } from './TDWidgetAddButton';
import { ErrorDescriptionFragment } from './__apollo__/ErrorDescriptionFragment';
import { ErrorDescriptionLocationFragment } from './__apollo__/ErrorDescriptionLocationFragment';
import {
  TicketDetailsErrorDescription,
  TicketDetailsErrorDescription_errorDescription,
} from './__apollo__/TicketDetailsErrorDescription';

export const ERROR_DESCRIPTION_FRAGMENT = gql`
  fragment ErrorDescriptionFragment on Ticket {
    id
    errorDescription {
      description
      createdDate
    }
  }
`;

export const ERROR_DESCRIPTION_LOCATION_FRAGMENT = gql`
  fragment ErrorDescriptionLocationFragment on Ticket {
    id
    originalLocation {
      ... on Location {
        id
        description
      }
    }
    originalSubLocation {
      ... on SubLocation {
        id
        description
      }
      ... on SubLocationText {
        id
        value
      }
    }
  }
`;

export const TD_ERROR_DESCRIPTION_FRAGMENT = gql`
  fragment TicketDetailsErrorDescription on Ticket {
    id
    ...CanAddErrorDescription
    ...ErrorDescriptionFragment
    ...ErrorDescriptionLocationFragment
    images {
      id
      ...ImageRowFragment
    }
    ...CodingFragment
  }
  ${TICKET_CARD_CODING_FRAGMENT}
  ${PERMISSIONS_CAN_ADD_ERROR_DESCRIPTION}
  ${IMAGE_ROW_FRAGMENT}
  ${ERROR_DESCRIPTION_FRAGMENT}
  ${ERROR_DESCRIPTION_LOCATION_FRAGMENT}
`;

export const TDErrorDescriptionApollo: FC<TicketDetailsErrorDescription> = (
  props
) => {
  const { translate } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const {
    ticket: { canAddErrorDescription },
  } = usePermissions();

  const rightItem = canAddErrorDescription(props) ? (
    <TDWidgetDialogButton
      icon={<Icons.Plus />}
      title={translate(
        'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_BUTTON',
        'Description'
      )}
      onClick={openDialog}
    />
  ) : undefined;

  return (
    <div data-cy="TDErrorDescription">
      <CardWithTitle
        title={translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_TITLE',
          'Error description'
        )}
        rightItem={rightItem}
      >
        <TDErrorDescriptionContent {...props} />
        <AddErrorDescriptionDialog
          data={props}
          open={dialogOpen}
          {...props}
          closeDialog={closeDialog}
        />
      </CardWithTitle>
    </div>
  );
};

export const TDErrorDescriptionContent: FC<TicketDetailsErrorDescription> = (
  props
) => {
  const { coding, errorDescription, images } = props;

  return (
    <Grid container direction="column" spacing={2} alignItems={'start'}>
      {coding?.__typename === 'Coding' && (
        <Grid item style={{ marginBottom: 8 }}>
          <TicketCardCoding {...props} />
        </Grid>
      )}
      <Grid item>
        <ErrorDescriptionArray errorDescription={errorDescription} />
      </Grid>
      <Grid item>
        <ImageRow images={images} />
      </Grid>
      <Grid item>
        <Location {...props} />
      </Grid>
    </Grid>
  );
};

export const ErrorDescriptionArray: FC<{
  errorDescription: ErrorDescriptionFragment['errorDescription'];
}> = ({ errorDescription }) => {
  const { translate } = useTranslation();

  return (
    <Grid container spacing={2} direction="column">
      {errorDescription.length === 0 && (
        <Grid item>
          <Typography textAlign="left">
            {translate(
              'ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.ERROR_DESCRIPTION.NO_ERROR_DESCRIPTION',
              'No error description on this ticket yet'
            )}
          </Typography>
        </Grid>
      )}
      {errorDescription.map((entry, index) => {
        return (
          <Grid item key={`${entry.__typename}:${entry.createdDate}:${index}`}>
            <ErrorDescriptionEntry entry={entry} />
          </Grid>
        );
      })}
    </Grid>
  );
};

const Location: FC<ErrorDescriptionLocationFragment> = (ticket) => {
  const { translate } = useTranslation();

  return (
    <Grid container style={{ flexDirection: 'row' }}>
      <Grid
        item
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mr: 0.5,
        }}
      >
        <Icons.Location color="disabled" />
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {ticket.originalLocation &&
          ticket.originalLocation.__typename === 'Location'
            ? ticket.originalLocation.description
            : translate('TICKET_DETAILS.CARDS.LOCATION_UNKNOWN', 'Unknown')}
        </Typography>
        {ticket.originalSubLocation !== null &&
          ticket.originalSubLocation?.__typename !== 'NotFound' && (
            <>
              {ticket.originalSubLocation?.__typename === 'SubLocation' && (
                <Typography variant="body2" color="textSecondary">
                  {ticket.originalSubLocation.description}
                </Typography>
              )}
              {ticket.originalSubLocation?.__typename === 'SubLocationText' && (
                <Typography variant="body2" color="textSecondary">
                  {ticket.originalSubLocation.value}
                </Typography>
              )}
            </>
          )}
      </Grid>
    </Grid>
  );
};

const ErrorDescriptionEntry: FC<{
  entry: TicketDetailsErrorDescription_errorDescription;
}> = ({ entry }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();

  const dateWithPrefix = `${translate(
    'MISC.CREATED_PREFIX',
    'Created'
  )} ${format(entry.createdDate, CARD_DATE_FORMAT)}`;

  return <TextWithLabel label={dateWithPrefix} text={entry.description} />;
};
