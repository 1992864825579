import { Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { isAfter } from 'date-fns';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import {
  CardWithTitle,
  CardWithTitleSkeleton,
} from '../../../components/shared/CardWithTitle';
import {
  TextWithLabel,
  TextWithLabelSkeleton,
} from '../../../components/shared/TextWithLabel';
import {
  useDateFromMiddlewareWithLocale,
  useFormatRelayLocalTimestampWithLocale,
} from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { skeletonify } from '../../skeleton';
import { FullWidthGrid } from '../../ticket-details/TicketDetailsPane';
import { WarrantyWidget_equipment$key } from './__generated__/WarrantyWidget_equipment.graphql';

const ActualComponent = ({
  equipment: equipmentRef,
}: {
  equipment: WarrantyWidget_equipment$key | null;
}) => {
  const { translate } = useTranslation();
  const { parseTimestamp, getDateFromRelayLocalTime } =
    useFormatRelayLocalTimestampWithLocale();
  const { formatDistanceToNow } = useDateFromMiddlewareWithLocale();

  const data = useFragment(
    graphql`
      fragment WarrantyWidget_equipment on Equipment {
        warranty {
          start {
            date
            time
          }
          end {
            date
            time
          }
        }
      }
    `,
    equipmentRef
  );

  if (!data?.warranty) {
    return null;
  }

  const { start, end } = data.warranty;

  const warrantyIsActive = isAfter(
    getDateFromRelayLocalTime({ ...end }),
    Date.now()
  );

  const localizedTimeLeft = formatDistanceToNow(
    getDateFromRelayLocalTime({ ...end }).toISOString(),
    { addSuffix: false }
  );

  const dateFormat = 'dd MMMM y';
  return (
    <FullWidthGrid>
      <CardWithTitle
        title={translate('EQUIPMENT_WIDGETS.WARRANTY.TITLE', 'Warranty')}
      >
        <Grid container direction="row" justifyContent="start">
          <Grid item xs>
            <TextWithLabel
              label={translate('EQUIPMENT_WIDGETS.WARRANTY.START', 'Start')}
              text={parseTimestamp({ ...start, dateFormat: dateFormat })}
            />
          </Grid>
          <Grid item xs>
            <TextWithLabel
              label={translate('EQUIPMENT_WIDGETS.WARRANTY.END', 'End')}
              text={parseTimestamp({
                date: end.date,
                time: end.time,
                dateFormat: dateFormat,
              })}
            />
          </Grid>
          {warrantyIsActive && (
            <Grid item xs>
              <Grid container direction="column" alignItems={'center'}>
                <Grid item>
                  <Icons.WarningTriangleOutlined
                    color="error"
                    height={24}
                    width={24}
                  />
                </Grid>
                <Grid item>
                  <Typography color="error">
                    {translate(
                      'EQUIPMENT_WIDGETS.WARRANTY.TIME_LEFT',
                      '{{ localizedTimeLeft }} left',
                      { localizedTimeLeft }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardWithTitle>
    </FullWidthGrid>
  );
};

const SkeletonComponent = () => {
  return (
    <FullWidthGrid>
      <WarrantyWidgetSkeleton />
    </FullWidthGrid>
  );
};

export const WarrantyWidget = skeletonify(
  'WarrantyWidget',
  ActualComponent,
  SkeletonComponent
);

const WarrantyWidgetSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton>
      <Grid container direction="row" justifyContent="start">
        <Grid item xs>
          <TextWithLabelSkeleton />
        </Grid>
        <Grid item xs>
          <TextWithLabelSkeleton />
        </Grid>
      </Grid>
    </CardWithTitleSkeleton>
  );
};
