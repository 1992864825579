import { hasValue } from '@lego/mst-error-utilities';
import { FC } from 'react';
import { useAddRepairDescriptionContext } from '../../contexts/add-repair-description/add-repair-description-context';
import { useTranslation } from '../../utility/i18n/translation';
import { FlowHeaderWithDiscardDialog } from '../shared/FlowHeaderWithDiscardDialog';

export const ARDHeader: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { chosenDamage },
  } = useAddRepairDescriptionContext();

  const dirty = hasValue(chosenDamage);

  return (
    <FlowHeaderWithDiscardDialog
      dirty={dirty}
      title={translate(
        'ADD_REPAIR_DOCUMENTATION.HEADER.TITLE',
        'Add new repair documentation'
      )}
    />
  );
};
