import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, InputAdornment, useTheme } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../../utility/icons';

export type SearchFieldAdornmentVariant =
  | 'search'
  | 'clear'
  | 'search-and-clear';

export const SearchFieldAdornments: FC<{
  variant?: SearchFieldAdornmentVariant;
  onSearchIconClicked?: () => void;
  onClearIconClicked?: () => void;
}> = ({ variant = 'search', onSearchIconClicked, onClearIconClicked }) => {
  const { palette } = useTheme();

  const getEndAdornment = () => {
    switch (variant) {
      case 'search':
        return (
          <Button onClick={onSearchIconClicked}>
            <InputAdornment position="start">
              <Icons.Search fill={palette.text.disabled} />
            </InputAdornment>
          </Button>
        );
      case 'clear':
        return (
          <Button
            disableRipple
            onClick={onClearIconClicked}
            sx={{ p: 0, minWidth: 0 }}
          >
            <InputAdornment position="end">
              <CloseIcon fill={palette.text.disabled} />
            </InputAdornment>
          </Button>
        );
      case 'search-and-clear':
        return (
          <Grid container direction="row" flex={1}>
            <Grid item xs={5}>
              <Button disableRipple onClick={onClearIconClicked} sx={{ p: 0 }}>
                <InputAdornment position="start">
                  <CloseIcon fill={palette.text.disabled} />
                </InputAdornment>
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button disableRipple onClick={onSearchIconClicked} sx={{ p: 0 }}>
                <InputAdornment position="start">
                  <Icons.Search
                    fill={palette.text.disabled}
                    sx={{ fontSize: '18px' }}
                  />
                </InputAdornment>
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  return getEndAdornment();
};
