/**
 * @generated SignedSource<<536569e6c20faadea1204936cacdf13f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketList_ticket$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"TicketCard_ticket">;
  readonly " $fragmentType": "TicketList_ticket";
}>;
export type TicketList_ticket$key = ReadonlyArray<{
  readonly " $data"?: TicketList_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketList_ticket">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TicketList_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TicketCard_ticket"
        }
      ]
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "40916489792c6aad0949a29118a704df";

export default node;
