import { hasValue } from '@lego/mst-error-utilities';
import { Box } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { usePaginationFragment } from 'react-relay';
import { FillWidthLoading } from '../../components/shared/FillWidthLoading';
import { ExtractNodeFromConnection } from '../../utility-types';
import { useTranslation } from '../../utility/i18n/translation';
import { InfiniteList } from '../components/InfiniteList';
import { ErrorPage } from '../PageErrorBoundary';
import { skeletonify } from '../skeleton';
import { MtsLogCard } from './MtsLogCard';
import {
  MtsLogTabList_mould$data,
  MtsLogTabList_mould$key,
} from './__generated__/MtsLogTabList_mould.graphql';

type Item = ExtractNodeFromConnection<MtsLogTabList_mould$data['mtsLog']>;

const ActualComponent: FC<{
  mould?: MtsLogTabList_mould$key | null;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const { data, ...pagination } = usePaginationFragment(
    graphql`
      fragment MtsLogTabList_mould on Mould
      @refetchable(queryName: "MtsLogTabListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        after: { type: "ID" }
      ) {
        ...MtsLogCard_mould
        mtsLog(first: $first, after: $after)
          @connection(key: "MtsLogTabList_mould_mtsLog") {
          edges {
            node {
              ...MtsLogCard_mtsLog
              id
            }
          }
        }
      }
    `,
    mouldRef ?? null
  );

  const emptyLabel = translate(
    'TICKET_DETAILS.MTS_LOG_TAB.EMPTY',
    'No MTS logs found'
  );

  const errorLabel = translate(
    'TICKET_DETAILS.MTS_LOG_TAB.ERROR',
    'Could not fetch MTS logs'
  );

  const itemKeyExtractor = useCallback((item: Item) => item.id, []);

  const itemRender = useCallback(
    (item: Item) => <MtsLogCard mtsLog={item} />,
    []
  );

  if (!data) {
    return <ErrorPage />;
  }

  const filtered = data.mtsLog?.edges.filter(hasValue).map(({ node }) => node);

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <MtsLogCard mould={data} isHighlighted />
      <Box flex={1} overflow="scroll" pt={2}>
        <InfiniteList
          {...pagination}
          emptyLabel={emptyLabel}
          errorLabel={errorLabel}
          items={filtered}
          itemKeyExtractor={itemKeyExtractor}
          itemRender={itemRender}
          itemSpacing={2}
        />
      </Box>
    </Box>
  );
};

export const MtsLogTabList = skeletonify(
  'MtsLogTabList',
  ActualComponent,
  FillWidthLoading
);
