import { hasValue } from '@lego/mst-error-utilities';
import { Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { ChipWithDefaultBackground } from '../../../components/ticket-card/components/TicketCardMouldChips';
import { skeletonify } from '../../skeleton';
import { useMouldStatusCode } from './statusCodeHook';
import { MouldStatusChip_mould$key } from './__generated__/MouldStatusChip_mould.graphql';

const ActualComponent: FC<{
  mould: MouldStatusChip_mould$key;
}> = ({ mould: mouldRef }) => {
  const data = useFragment(
    graphql`
      fragment MouldStatusChip_mould on Mould {
        statusCode
      }
    `,
    mouldRef
  );
  const statusDescription = useMouldStatusCode(data.statusCode);
  const statusLabel = hasValue(data.statusCode)
    ? `${data.statusCode}: ${statusDescription}`
    : '---';

  return <ChipWithDefaultBackground label={statusLabel} />;
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldStatusChip = skeletonify(
  'MouldStatusChip',
  ActualComponent,
  SkeletonComponent
);
