import { ApolloProvider } from '@apollo/client';
import { Grid } from '@mui/material';
import { ReactElement } from 'react';
import { useApolloClient } from '../apollo/apollo-client';
import { ActivityIndicator } from '../components/shared/ActivityIndicator';
import { withRelayEnvironment } from '../relay/RelayEnvironment';

export const GQLProvider = withRelayEnvironment(
  'select_account',
  ({ children }: { children: ReactElement }) => {
    const client = useApolloClient();

    if (!client) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <ActivityIndicator />
          </Grid>
        </Grid>
      );
    }

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }
);
