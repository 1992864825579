/**
 * @generated SignedSource<<86a9c5b41d263ed8532dbdeb32ebacec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProcessType = "MachineMaintenance" | "MouldMaintenance" | "Packing" | "Processing" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TicketSearchFilters_process$data = {
  readonly type: ProcessType;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchSublocationFilter_process">;
  readonly " $fragmentType": "TicketSearchFilters_process";
};
export type TicketSearchFilters_process$key = {
  readonly " $data"?: TicketSearchFilters_process$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchFilters_process">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchFilters_process",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketSearchSublocationFilter_process"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Process",
  "abstractKey": null
};

(node as any).hash = "df8b1e0c5545df4e2ff723226cb1d7bd";

export default node;
