import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../utility/icons';

export const TicketListEmptyView: FC<{
  textBelowIcon?: string;
  size?: number;
}> = ({ textBelowIcon, size = 120 }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Icons.NoTickets fillOpacity={0.6} style={{ fontSize: size }} />
      {textBelowIcon && <Typography>{textBelowIcon}</Typography>}
    </Grid>
  );
};
