import { gql } from '@apollo/client';
import { FC } from 'react';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { TDRepairDescriptionApollo } from '../../../components/ticket-details/TDRepairDescription';
import { TD_REPAIR_DOCUMENTATION } from '../../../components/ticket-details/TDRepairFragments';
import { TDWidgetWithCollapsibleRowsSkeleton } from './TDWidgetWithCollapsibleRowsSkeleton';
import {
  TDRepairDescriptionQuery,
  TDRepairDescriptionQueryVariables,
} from './__apollo__/TDRepairDescriptionQuery';

const QUERY = gql`
  query TDRepairDescriptionQuery($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        isCmsTicket
        ...TicketDetailsRepairDescriptions
      }
    }
  }
  ${TD_REPAIR_DOCUMENTATION}
`;

export const TDRepairDescription: FC<{ ticketNumber: string }> = ({
  ticketNumber,
}) => {
  const { data, loading } = useGMQuery<
    TDRepairDescriptionQuery,
    TDRepairDescriptionQueryVariables
  >(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDWidgetWithCollapsibleRowsSkeleton showTopRightButton={true} />;
  }

  if (!data || data.ticket.__typename !== 'Ticket' || data.ticket.isCmsTicket) {
    return null;
  }

  return <TDRepairDescriptionApollo {...data.ticket} />;
};
