import { gql } from '@apollo/client';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useAreaAndProcessContext } from '../../contexts/area';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION } from '../../utility/permissions/ticket';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { CardWithTitle } from '../shared/CardWithTitle';
import { EQUIPMENT_LOCATION_FRAGMENT } from '../shared/EquipmentLocationSection';
import { TextWithLabel } from '../shared/TextWithLabel';
import { TDUpdateLocationDialog } from './TDUpdateLocationDialog';
import { TDWidgetDialogButton } from './TDWidgetAddButton';
import { TicketDetailsLocation } from './__apollo__/TicketDetailsLocation';

export const TD_CURRENT_LOCATION = gql`
  fragment TicketDetailsLocation on IEquipment {
    id
    plantId
    ...EquipmentLocationFragment
    ...CanEditEquipmentLocation
    location {
      ... on Location {
        id
        description
      }
    }
    subLocation {
      ... on SubLocationText {
        id
        value
      }
      ... on SubLocation {
        id
        description
      }
    }
    ... on Mould {
      id
      rack
      mouldMachine
    }
  }
  ${PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION}
  ${EQUIPMENT_LOCATION_FRAGMENT}
`;

export const TDCurrentLocationOld: FC<TicketDetailsLocation> = (equipment) => {
  const { translate } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const closeDialog = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  const openDialog = useCallback(() => {
    setEditDialogOpen(true);
  }, []);

  const {
    ticket: { canEditEquipmentLocation },
  } = usePermissions();

  const subLocation = useMemo(() => {
    const { subLocation } = equipment;

    switch (subLocation?.__typename) {
      case 'SubLocation':
        return subLocation.description;
      case 'SubLocationText':
        return subLocation.value;
      case 'NotFound':
      default:
        return '';
    }
  }, [equipment]);

  if (!equipment || equipment.location?.__typename !== 'Location') {
    return null;
  }

  const rightItem = canEditEquipmentLocation(equipment) ? (
    <TDWidgetDialogButton
      icon={<Icons.Edit />}
      title={translate('TICKET_DETAILS.CARDS.LOCATION_ADD_BUTTON', 'Location')}
      onClick={openDialog}
    />
  ) : undefined;

  return (
    <CardWithTitle
      title={translate(
        'TICKET_DETAILS.CARDS.LOCATION_TITLE',
        'Current location'
      )}
      rightItem={rightItem}
    >
      <Grid container direction="row" justifyContent={'space-between'}>
        <Grid item>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <TextWithLabel
                label={translate(
                  'TICKET_DETAILS.CARDS.LOCATION_LABEL',
                  'Hall/Section'
                )}
                text={equipment.location.description}
              />
            </Grid>
            <Grid item>
              <TextWithLabel
                label={translate(
                  'TICKET_DETAILS.CARDS.SUBLOCATION_LABEL',
                  'Room (Row/Place)'
                )}
                text={subLocation}
              />
            </Grid>
            {equipment.__typename === 'Mould' && equipment.rack && (
              <Grid item>
                <TextWithLabel
                  label={translate('TICKET_DETAILS.CARDS.RACK_LABEL', 'Rack')}
                  text={equipment.rack}
                />
              </Grid>
            )}
            {equipment.__typename === 'Mould' && equipment.mouldMachine && (
              <Grid item>
                <TextWithLabel
                  label={translate(
                    'TICKET_DETAILS.CARDS.MACHINE_LABEL',
                    'Machine'
                  )}
                  text={equipment.mouldMachine.toString()}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <EquipmentOnOtherPlantWarning equipmentPlantId={equipment.plantId} />
        </Grid>
      </Grid>

      <TDUpdateLocationDialog
        data={equipment}
        onDismiss={closeDialog}
        open={editDialogOpen}
      />
    </CardWithTitle>
  );
};

const EquipmentOnOtherPlantWarning: FC<{ equipmentPlantId: number }> = ({
  equipmentPlantId,
}) => {
  const {
    selectedArea: { plantId: userPlantId },
  } = useAreaAndProcessContext();
  const { translate } = useTranslation();

  if (userPlantId === equipmentPlantId.toString()) {
    return null;
  }

  return (
    <Tooltip
      title={translate(
        'EQUIPMENT_CARD.ON_OTHER_PLANT_WARNING_TOOLTIP',
        'Equipment located on plant {{ plantId }}',
        { plantId: equipmentPlantId.toString() }
      )}
    >
      <IconButton>
        <Icons.WarningTriangle color="error" />
      </IconButton>
    </Tooltip>
  );
};
