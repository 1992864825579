import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import {
  SparePartSearchActions,
  sparePartSearchReducer,
} from './spare-part-search-reducer';

export interface SparePartSearchState {
  filterOnStock: boolean;
  searchTerm: string | null;
}

export const initialSparePartSearchState: SparePartSearchState = {
  filterOnStock: false,
  searchTerm: null,
};

export interface SparePartSearchContext {
  state: SparePartSearchState;
  dispatch: Dispatch<SparePartSearchActions>;
}

const Context = createContext<SparePartSearchContext | undefined>(undefined);

export const useSparePartSearchContext = (): SparePartSearchContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing SparePartSearchProvider');
  }

  return context;
};

export const SparePartSearchProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    sparePartSearchReducer,
    initialSparePartSearchState
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
