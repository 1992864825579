import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { ReactElement } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { ImageRow } from '../components/ImageRow';
import { CodingChip } from '../components/ticket/CodingChip';
import { ErrorDescription } from '../components/ticket/ErrorDescription';
import { TicketLocation } from '../components/ticket/TicketLocation';
import { skeletonify } from '../skeleton';
import { EquipmentHistoryCardDescription_ticket$key } from './__generated__/EquipmentHistoryCardDescription_ticket.graphql';

type StructureComponentProps = {
  title: ReactElement;
  coding?: ReactElement;
  errorDescription: ReactElement;
  images?: ReactElement;
  location: ReactElement;
};

const ActualComponent = (props: {
  ticket: EquipmentHistoryCardDescription_ticket$key;
}): StructureComponentProps => {
  const { ticket: ticketRef } = props;
  const { translate } = useTranslation();

  const ticket = useFragment(
    graphql`
      fragment EquipmentHistoryCardDescription_ticket on Ticket {
        __typename
        images {
          ...ImageRowImages_imageInformation
        }
        ...CodingChip_ticket
        ...ErrorDescription_ticket @arguments(includeTimestamp: true)
        ...TicketLocation_ticket
      }
    `,
    ticketRef
  );

  return {
    title: (
      <Typography variant="subtitle2" textAlign="start">
        {translate(
          'TICKET_HISTORY_CARD.ERROR_DESCRIPTION',
          'Error description'
        )}
      </Typography>
    ),
    coding:
      ticket.__typename === 'MouldTicket' ? (
        <CodingChip ticket={ticket} />
      ) : undefined,
    errorDescription: <ErrorDescription ticket={ticket} />,
    images: hasValue(ticket.images) ? (
      <ImageRow images={ticket.images} />
    ) : undefined,
    location: <TicketLocation ticket={ticket} />,
  };
};

const SkeletonComponent = () => ({
  title: <Skeleton width={150} height={32} />,
  errorDescription: <ErrorDescription.Skeleton />,
  location: <TicketLocation.Skeleton />,
});

const StructureComponent = (props: StructureComponentProps) => {
  const { title, coding, errorDescription, images, location } = props;
  return (
    <Grid container spacing={1} direction="column" alignItems="flex-start">
      <Grid item>{title}</Grid>
      {hasValue(coding) && <Grid item>{coding}</Grid>}
      <Grid item>{errorDescription}</Grid>
      {hasValue(images) && <Grid item>{images}</Grid>}
      <Grid item>{location}</Grid>
    </Grid>
  );
};

export const EquipmentHistoryCardDescription = skeletonify(
  'EquipmentHistoryCardDescription',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);
