import { TicketPriorityEnum } from '../../../__apollo__/types';

export enum TicketTypeEnum {
  CMS,
  Mould,
  Equipment,
}

export interface ChangePriorityDialogField {
  name: string;
  value: TicketPriorityEnum;
  translation: string;
}

export const ALLOWED_TICKET_PRIORITIES: Record<TicketTypeEnum, TicketPriorityEnum[]> =
  {
    [TicketTypeEnum.CMS]: [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority8,
    ],
    [TicketTypeEnum.Mould]: [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority4,
    ],
    [TicketTypeEnum.Equipment]: [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority3,
      TicketPriorityEnum.Priority5,
    ],
  };
