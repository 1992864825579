import { Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { TextWithLabel } from '../../../components/shared/TextWithLabel';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { MouldWeight_mould$key } from './__generated__/MouldWeight_mould.graphql';

const ActualComponent: FC<{
  mould: MouldWeight_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment MouldWeight_mould on Mould {
        weight {
          unitOfMeasurement
          value
        }
      }
    `,
    mouldRef
  );

  return (
    <TextWithLabel
      text={`${data.weight?.value} ${data.weight?.unitOfMeasurement}`}
      label={translate('TICKET_DETAILS.SISTER_MOULDS_TAB.WEIGHT', 'Weight')}
    />
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const MouldWeight = skeletonify(
  'MouldWeight',
  ActualComponent,
  SkeletonComponent
);
