import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, SVGProps } from 'react';
import { ReactComponent as Add } from './../assets/icons/icnAdd.svg';
import { ReactComponent as AddErrorDescription } from './../assets/icons/icnAddError.svg';
import { ReactComponent as AddRepairDocs } from './../assets/icons/icnAddRepairDoc.svg';
import { ReactComponent as Assign } from './../assets/icons/icnAssign.svg';
import { ReactComponent as AssignOutlined } from './../assets/icons/icnAssignOutlined.svg';
import { ReactComponent as Barcode } from './../assets/icons/icnBarcode.svg';
import { ReactComponent as NoTickets } from './../assets/icons/icnBigNoTickets.svg';
import { ReactComponent as Burger } from './../assets/icons/icnBurger.svg';
import { ReactComponent as Cavities } from './../assets/icons/icnCavities.svg';
import { ReactComponent as CheckmarkBold } from './../assets/icons/icnCheckBold.svg';
import { ReactComponent as IconClose } from './../assets/icons/icnClose.svg';
import { ReactComponent as CloseTicket } from './../assets/icons/icnCloseTicket.svg';
import { ReactComponent as CountDownSvg } from './../assets/icons/icnCountdown.svg';
import { ReactComponent as CreateTicket } from './../assets/icons/icnCreateTicket.svg';
import { ReactComponent as Edit } from './../assets/icons/icnEdit.svg';
import { ReactComponent as ImageNotFound } from './../assets/icons/icnFileImage.svg';
import { ReactComponent as Hardhat } from './../assets/icons/icnHardhat.svg';
import { ReactComponent as Hashtag } from './../assets/icons/icnHashtag.svg';
import { ReactComponent as Home } from './../assets/icons/icnHome.svg';
import { ReactComponent as InfoInCircle } from './../assets/icons/icnInfoInCircle.svg';
import { ReactComponent as BulletList } from './../assets/icons/icnListBullets.svg';
import { ReactComponent as LocationSvg } from './../assets/icons/icnLocation.svg';
import { ReactComponent as LocationFilled } from './../assets/icons/icnLocationFilled.svg';
import { ReactComponent as EquipmentId } from './../assets/icons/icnMachine.svg';
import { ReactComponent as MoneyBag } from './../assets/icons/icnMoneyBag.svg';
import { ReactComponent as MouldType } from './../assets/icons/icnMouldType.svg';
import { ReactComponent as RightArrow } from './../assets/icons/icnMoveBack.svg';
import { ReactComponent as Plus } from './../assets/icons/icnPlus.svg';
import { ReactComponent as PopupPreview } from './../assets/icons/icnPopupPreview.svg';
import { ReactComponent as Print } from './../assets/icons/icnPrint.svg';
import { ReactComponent as RobotArm } from './../assets/icons/icnRobotArm.svg';
import { ReactComponent as RotateLeft } from './../assets/icons/icnRotateLeft.svg';
import { ReactComponent as Search } from './../assets/icons/icnSearch.svg';
import { ReactComponent as SparePartCogs } from './../assets/icons/icnSparepart.svg';
import { ReactComponent as SparepartAssign } from './../assets/icons/icnSparepartAssign.svg';
import { ReactComponent as Calendar } from './../assets/icons/icnStartDate.svg';
import { ReactComponent as SubEquipment } from './../assets/icons/icnSubcomponent.svg';
import { ReactComponent as Subtract } from './../assets/icons/icnSubtract.svg';
import { ReactComponent as TestStatus } from './../assets/icons/icnTestStatus.svg';
import { ReactComponent as TicketId } from './../assets/icons/icnTicket.svg';
import { ReactComponent as Clock } from './../assets/icons/icnTime.svg';
import { ReactComponent as Unassign } from './../assets/icons/icnUnassign.svg';
import { ReactComponent as WarningTriangle } from './../assets/icons/icnWarning.svg';
import { ReactComponent as WarningTriangleOutlined } from './../assets/icons/icnWarningTriangleOutlined.svg';
import { ReactComponent as WeightInKg } from './../assets/icons/icnWeightInKg.svg';
import { ReactComponent as WeightScales } from './../assets/icons/icnWeightScales.svg';

type SvgComponent = FC<SVGProps<SVGSVGElement>>;

const getComponentFromSvg = (Icon: SvgComponent): FC<SvgIconProps> => {
  const theFunc = (props: SvgIconProps) => {
    return <SvgIcon {...props} component={Icon} inheritViewBox />;
  };

  return theFunc;
};

export const Icons = {
  Countdown: getComponentFromSvg(CountDownSvg),
  Location: getComponentFromSvg(LocationSvg),
  EquipmentId: getComponentFromSvg(EquipmentId),
  Calendar: getComponentFromSvg(Calendar),
  TicketId: getComponentFromSvg(TicketId),
  NoTickets: getComponentFromSvg(NoTickets),
  InfoInCircle: getComponentFromSvg(InfoInCircle),
  MouldType: getComponentFromSvg(MouldType),
  WeightInKg: getComponentFromSvg(WeightInKg),
  WeightScales: getComponentFromSvg(WeightScales),
  Hardhat: getComponentFromSvg(Hardhat),
  AddRepairDocs: getComponentFromSvg(AddRepairDocs),
  CheckmarkBold: getComponentFromSvg(CheckmarkBold),
  SparePartCogs: getComponentFromSvg(SparePartCogs),
  ImageNotFound: getComponentFromSvg(ImageNotFound),
  Clock: getComponentFromSvg(Clock),
  RotateLeft: getComponentFromSvg(RotateLeft),
  RightArrow: getComponentFromSvg(RightArrow),
  CloseTicket: getComponentFromSvg(CloseTicket),
  WarningTriangle: getComponentFromSvg(WarningTriangle),
  Edit: getComponentFromSvg(Edit),
  Hashtag: getComponentFromSvg(Hashtag),
  BulletList: getComponentFromSvg(BulletList),
  IconClose: getComponentFromSvg(IconClose),
  Plus: getComponentFromSvg(Plus),
  PopupPreview: getComponentFromSvg(PopupPreview),
  Assign: getComponentFromSvg(Assign),
  Unassign: getComponentFromSvg(Unassign),
  AssignOutlined: getComponentFromSvg(AssignOutlined),
  AddErrorDescription: getComponentFromSvg(AddErrorDescription),
  SubEquipment: getComponentFromSvg(SubEquipment),
  Search: getComponentFromSvg(Search),
  MoneyBag: getComponentFromSvg(MoneyBag),
  CreateTicket: getComponentFromSvg(CreateTicket),
  WarningTriangleOutlined: getComponentFromSvg(WarningTriangleOutlined),
  Burger: getComponentFromSvg(Burger),
  Barcode: getComponentFromSvg(Barcode),
  LocationFilled: getComponentFromSvg(LocationFilled),
  Add: getComponentFromSvg(Add),
  Subtract: getComponentFromSvg(Subtract),
  SparepartAssign: getComponentFromSvg(SparepartAssign),
  RobotArm: getComponentFromSvg(RobotArm),
  Print: getComponentFromSvg(Print),
  Home: getComponentFromSvg(Home),
  Cavities: getComponentFromSvg(Cavities),
  TestStatus: getComponentFromSvg(TestStatus),
};
