import { gql } from '@apollo/client';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { TD_MOULD_DIMMING_DETAILS } from '../../ticket-details/TDDimmingDetails';
import { TD_ERROR_DESCRIPTION_FRAGMENT } from '../../ticket-details/TDErrorDescription';
import { TD_REPAIR_DOCUMENTATION } from '../../ticket-details/TDRepairFragments';
import { ARDDimmings } from './ARDDimmings';
import { ARDErrorDescription } from './ARDErrorDescription';
import { ARDRepairDocumentation } from './ARDRepairDocumentation';
import { ARDSpareParts } from './ARDSpareParts';
import { AddRepairDocumentationSidebarFragment } from './__apollo__/AddRepairDocumentationSidebarFragment';

export const ARD_SIDEBAR_FRAGMENT = gql`
  fragment AddRepairDocumentationSidebarFragment on Ticket {
    id
    ...TicketDetailsErrorDescription
    ...TicketDetailsRepairDescriptions
    ...TicketDetailsMouldDimmingDetails
  }
  ${TD_ERROR_DESCRIPTION_FRAGMENT}
  ${TD_REPAIR_DOCUMENTATION}
  ${TD_MOULD_DIMMING_DETAILS}
`;

export const ARDSidebar: FC<AddRepairDocumentationSidebarFragment> = (
  props
) => {
  return (
    <Grid container direction="column" spacing={2} marginBottom={50}>
      <Grid item>
        <ARDErrorDescription {...props} />
      </Grid>
      <Grid item>
        <ARDRepairDocumentation {...props} />
      </Grid>
      <Grid item>
        <ARDSpareParts />
      </Grid>
      <Grid item>
        <ARDDimmings {...props} />
      </Grid>
    </Grid>
  );
};
