import { Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { skeletonify } from '../../skeleton';
import { EquipmentDescription_equipment$key } from './__generated__/EquipmentDescription_equipment.graphql';

const ActualComponent: FC<{
  equipment: EquipmentDescription_equipment$key | null;
}> = (props) => {
  const { equipment: equipmentRef } = props;
  const equipment = useFragment(
    graphql`
      fragment EquipmentDescription_equipment on Equipment {
        description
      }
    `,
    equipmentRef ?? null
  );

  if (!equipment?.description) {
    return null;
  }

  return <Typography variant="subtitle2">{equipment.description}</Typography>;
};

const SkeletonComponent: FC = () => <Skeleton width={256} height={38} />;

export const EquipmentDescription = skeletonify(
  'EquipmentDescription',
  ActualComponent,
  SkeletonComponent
);
