import { hasValue } from '@lego/mst-error-utilities';

export const cleaningsSaved = ({
  actualShots,
  budgetedShots,
  currentCleaningInterval,
  newCleaningInterval,
}: {
  budgetedShots?: number | null;
  actualShots: number;
  currentCleaningInterval: number;
  newCleaningInterval: number;
}): number => {
  if (!hasValue(budgetedShots)) {
    return 0;
  }
  const cleaningsWithCurrentInterval =
    (budgetedShots - actualShots) / currentCleaningInterval;
  const cleaningsWithNewInterval =
    (budgetedShots - actualShots) / newCleaningInterval;

  const saved = cleaningsWithCurrentInterval - cleaningsWithNewInterval;

  if (newCleaningInterval >= budgetedShots) {
    return cleaningsWithCurrentInterval > 0
      ? Math.floor(cleaningsWithCurrentInterval)
      : 0;
  }

  if (actualShots >= budgetedShots) {
    return 0;
  }

  if (saved > 0) {
    return Math.floor(saved);
  }

  return 0;
};
