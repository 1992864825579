import { gql } from '@apollo/client';
import { FC } from 'react';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from '../../../components/add-time/overview/time-registration-gql-fragments';
import { TDMachineHoursApollo } from '../../../components/ticket-details/TDMachineHours';
import { PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION } from '../../../utility/permissions/ticket';
import { TDWidgetWithCollapsibleRowsSkeleton } from './TDWidgetWithCollapsibleRowsSkeleton';
import {
  TDMachineHoursQuery,
  TDMachineHoursQueryVariables,
} from './__apollo__/TDMachineHoursQuery';

const QUERY = gql`
  query TDMachineHoursQuery($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        isCmsTicket
        ...CanAddMachineTimeRegistration
        ...TimeRegistrationOverview
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
  ${PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION}
`;

export const TDMachineHours: FC<{ ticketNumber: string }> = ({
  ticketNumber,
}) => {
  const { data, loading } = useGMQuery<
    TDMachineHoursQuery,
    TDMachineHoursQueryVariables
  >(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDWidgetWithCollapsibleRowsSkeleton showTopRightButton={true} />;
  }

  if (
    !data ||
    data.ticket.__typename !== 'Ticket' ||
    !data.ticket.isCmsTicket
  ) {
    return null;
  }

  return <TDMachineHoursApollo {...data.ticket} />;
};
