import { hasValue, stringIsPositiveInteger } from '@lego/mst-error-utilities';
import { Box, Container, Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useEffect, useMemo, useTransition } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { ScrollToTopFab } from '../../containers';
import { useAreaAndProcessContext } from '../../contexts/area';
import {
  EquipmentSearchProvider,
  useEquipmentSearchContext,
} from '../../contexts/equipment-search/equipment-search-context';
import { CountAndSearch } from '../components/CountAndSearch';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { EquipmentList } from './EquipmentList';
import EquipmentSearchContainerQuery, {
  EquipmentSearchContainerQuery as EquipmentSearchContainerQueryType,
} from './__generated__/EquipmentSearchContainerQuery.graphql';

export const EquipmentSearchContainer: FC = () => {
  return (
    <Container maxWidth="xl">
      <PageErrorBoundary>
        <EquipmentSearchProvider>
          <EquipmentSearchLoader />
        </EquipmentSearchProvider>
      </PageErrorBoundary>
    </Container>
  );
};

const EquipmentSearchLoader: FC = () => {
  const { selectedArea } = useAreaAndProcessContext();
  const {
    dispatch,
    state: { count, searchTerm },
  } = useEquipmentSearchContext();
  const equipmentNumber = Number.parseInt(searchTerm ?? '1');
  const equipmentNumberChecked = Number.isNaN(equipmentNumber)
    ? 1
    : equipmentNumber;

  const searchTermIsCompleteEquipmentId = useMemo(() => {
    if (!searchTerm || !stringIsPositiveInteger(searchTerm)) {
      return false;
    }
    const trimmedId = trimForZeroes(searchTerm);

    const searchTermCompleteId = trimmedId.length >= 6 && trimmedId.length <= 8;

    return searchTermCompleteId;
  }, [searchTerm]);
  const skipSingularQuery = !searchTermIsCompleteEquipmentId;

  const skipListQuery = useMemo(() => {
    const minimumInputForSearch = 4;
    const skipList =
      !hasValue(searchTerm) || searchTerm.length < minimumInputForSearch;

    return skipList;
  }, [searchTerm]);

  const [queryRef, loadQuery] =
    useQueryLoader<EquipmentSearchContainerQueryType>(
      EquipmentSearchContainerQuery
    );
  const [, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      loadQuery(
        {
          input: {
            plantNumber: Number.parseInt(selectedArea.plantId),
          },
          equipmentSearchInput: {
            searchTerm,
          },
          singularSearchInput: {
            equipmentNumber: equipmentNumberChecked,
          },
          skipList: skipListQuery,
          skipSingular: skipSingularQuery,
        },
        { fetchPolicy: 'store-and-network' }
      );
    });
  }, [
    equipmentNumber,
    equipmentNumberChecked,
    loadQuery,
    searchTerm,
    selectedArea.plantId,
    skipListQuery,
    skipSingularQuery,
  ]);

  const onSearchChanged = useCallback(
    (value: string) => {
      dispatch({ type: 'setSearchTerm', searchTerm: value });
    },
    [dispatch]
  );

  return (
    <Box sx={{ mt: 2 }}>
      {queryRef ? (
        <ActualComponent
          query={queryRef}
          count={count}
          onSearchChanged={onSearchChanged}
          showCount={!skipListQuery}
        />
      ) : (
        <SkeletonComponent />
      )}
    </Box>
  );
};

const trimForZeroes = (id: string): string => {
  return Number.parseInt(id).toString();
};

const ActualComponent: FC<{
  query: PreloadedQuery<EquipmentSearchContainerQueryType>;
  onSearchChanged: (value: string) => void;
  showCount: boolean;
  count?: number;
}> = ({ query: queryRef, count, onSearchChanged, showCount }) => {
  const query = usePreloadedQuery<EquipmentSearchContainerQueryType>(
    graphql`
      query EquipmentSearchContainerQuery(
        $input: QueryPlantInput!
        $equipmentSearchInput: PlantEquipmentSearchInput!
        $singularSearchInput: QueryEquipmentInput!
        $skipList: Boolean!
        $skipSingular: Boolean!
      ) {
        equipment(input: $singularSearchInput) @skip(if: $skipSingular) {
          ... on QueryEquipmentSuccess {
            data {
              ...EquipmentList_equipment
            }
          }
        }
        plant(input: $input) @skip(if: $skipList) {
          ... on QueryPlantSuccess {
            data {
              ...EquipmentList_plant
                @arguments(equipmentListInput: $equipmentSearchInput)
            }
          }
        }
      }
    `,
    queryRef
  );

  return (
    <Container maxWidth="xl" sx={{ mb: 10 }}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <CountAndSearch
            count={count}
            onChange={onSearchChanged}
            showCount={showCount}
          />
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs>
            <EquipmentList.Suspense
              plant={query.plant?.data ?? null}
              equipment={query.equipment?.data ?? null}
            />
          </Grid>
        </Grid>
      </Grid>
      <ScrollToTopFab />
    </Container>
  );
};

const SkeletonComponent: FC = () => {
  const {
    state: { searchTerm },
  } = useEquipmentSearchContext();
  return (
    <Grid container direction="column" spacing={3} alignItems="center">
      <Grid item alignSelf="flex-end">
        <CountAndSearch
          count={0}
          onChange={() => {
            return;
          }}
          showCount={false}
          controlledValue={searchTerm ?? undefined}
        />
      </Grid>
      <Grid item>
        <ActivityIndicator />
      </Grid>
    </Grid>
  );
};
