import {
  Grid,
  GridSize,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import debounce from 'lodash/debounce';
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from '../../../utility/i18n/translation';
import { CreateTicketEquipmentLabel } from '../../create-ticket/CreateTicketEquipmentLabel';
import { SearchEquipmentDialog } from '../../create-ticket/search-equipment-dialog/SearchEquipmentDialog';
import { isValidEquipmentId } from '../../utils';
import {
  SearchFieldAdornments,
  SearchFieldAdornmentVariant,
} from './SearchFieldAdornments';

type EquipmentSearchTextFieldProps = {
  onEquipmentSelected: (equipmentNumber: number | null) => void;
  initialEquipmentNumber?: string;
  showPrefixLabel?: boolean;
  showInput?: boolean;
  showPlaceholder?: boolean;
  variant?: SearchFieldAdornmentVariant;
  prefixLabelStyle?: SxProps<Theme>;
  inputFieldSize?: GridSize;
};

type InnerComponentLoaderProps = EquipmentSearchTextFieldProps & {
  onSearchIconClicked: () => void;
  onClearIconClicked?: () => void;
  equipmentSearchString?: string;
  setEquipmentSearchString: (newValue: string) => void;
};

export const EquipmentSearchTextField: FC<EquipmentSearchTextFieldProps> = (
  props
) => {
  const { translate } = useTranslation();
  const [equipmentSearchString, setEquipmentSearchString] = useState(
    props.initialEquipmentNumber
  );

  const { onEquipmentSelected } = props;
  const [showEquipmentSearchDialog, setShowEquipmentSearchDialog] =
    useState(false);

  const onSearchIconClicked = useCallback(() => {
    setShowEquipmentSearchDialog(true);
  }, []);

  const onClearIconClicked = useCallback(() => {
    setEquipmentSearchString('');
    onEquipmentSelected(null);
  }, [onEquipmentSelected]);

  const onDismissSearchEquipmentDialog = useCallback(() => {
    setShowEquipmentSearchDialog(false);
  }, []);

  const onDialogOptionPressed = useCallback(
    (equipmentNumber: number) => {
      onDismissSearchEquipmentDialog();
      setEquipmentSearchString(equipmentNumber.toString());
      onEquipmentSelected(equipmentNumber);
    },
    [onDismissSearchEquipmentDialog, onEquipmentSelected]
  );

  return (
    <Fragment>
      <InnerComponent
        {...props}
        onSearchIconClicked={onSearchIconClicked}
        onClearIconClicked={onClearIconClicked}
        equipmentSearchString={equipmentSearchString}
        setEquipmentSearchString={setEquipmentSearchString}
      />
      <SearchEquipmentDialog
        onEquipmentPressed={onDialogOptionPressed}
        onDismiss={onDismissSearchEquipmentDialog}
        open={showEquipmentSearchDialog}
        title={translate(
          'CREATE_TICKET.ERROR.SEARCH_DIALOG.TITLE',
          'Equipment search'
        )}
      />
    </Fragment>
  );
};

const InnerComponent: FC<InnerComponentLoaderProps> = ({
  onEquipmentSelected,
  setEquipmentSearchString,
  onSearchIconClicked,
  onClearIconClicked,
  showPrefixLabel = false,
  showInput = true,
  showPlaceholder = true,
  equipmentSearchString,
  prefixLabelStyle,
  inputFieldSize = 5,
  variant,
}) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  const onChangeDebounced = useMemo(() => {
    const searchDispatch: TextFieldProps['onChange'] = (event) => {
      const value = event?.target.value;
      if (!isValidEquipmentId(value)) {
        return;
      }

      onEquipmentSelected(Number.parseInt(value));
    };
    const debounced = debounce(searchDispatch, 500);

    return (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value ?? '';
      if (value === '') {
        onClearIconClicked && onClearIconClicked();
      }
      if (isNaN(Number(value))) {
        return;
      }
      setEquipmentSearchString(value);
      debounced(event);
    };
  }, [setEquipmentSearchString, onEquipmentSelected, onClearIconClicked]);

  return (
    <Grid container spacing={1} direction="column">
      {showPrefixLabel && (
        <Grid item>
          <Typography sx={prefixLabelStyle}>
            {translate(
              'CREATE_TICKET.EQUIPMENT_STEP.TEXT',
              'Please enter equipment ID'
            )}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="row" alignItems="center">
          {showInput ? (
            <Grid item xs={inputFieldSize}>
              <TextField
                placeholder={
                  showPlaceholder
                    ? translate(
                        'CREATE_TICKET.EQUIPMENT_STEP.PLACEHOLDER',
                        'Enter equipment id'
                      )
                    : undefined
                }
                style={{ backgroundColor: palette.background.paper }}
                onChange={onChangeDebounced}
                value={equipmentSearchString || ''}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <SearchFieldAdornments
                      variant={variant}
                      onSearchIconClicked={onSearchIconClicked}
                      onClearIconClicked={onClearIconClicked}
                    />
                  ),
                }}
              />
            </Grid>
          ) : (
            <Grid item>
              <Typography>{equipmentSearchString}</Typography>
            </Grid>
          )}
          {equipmentSearchString && (
            <Grid item xs>
              <CreateTicketEquipmentLabel.Suspense
                equipmentSearchString={equipmentSearchString}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
