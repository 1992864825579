/**
 * @generated SignedSource<<513f9d551de27e3341cc37454a83c237>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryCardDescription_ticket$data = {
  readonly __typename: string;
  readonly images: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ImageRowImages_imageInformation">;
  }> | null;
  readonly " $fragmentSpreads": FragmentRefs<"CodingChip_ticket" | "ErrorDescription_ticket" | "TicketLocation_ticket">;
  readonly " $fragmentType": "EquipmentHistoryCardDescription_ticket";
};
export type EquipmentHistoryCardDescription_ticket$key = {
  readonly " $data"?: EquipmentHistoryCardDescription_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCardDescription_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryCardDescription_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageInformation",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ImageRowImages_imageInformation"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodingChip_ticket"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "includeTimestamp",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "ErrorDescription_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketLocation_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "b31dca19aea662b9897853b5e4323dc7";

export default node;
