import { gql } from '@apollo/client';
import { FC } from 'react';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from '../../../components/add-time/overview/time-registration-gql-fragments';
import { TDManhoursApollo } from '../../../components/ticket-details/TDManhours';
import { TDWidgetWithCollapsibleRowsSkeleton } from './TDWidgetWithCollapsibleRowsSkeleton';
import {
  TDManhoursQuery,
  TDManhoursQueryVariables,
} from './__apollo__/TDManhoursQuery';

const QUERY = gql`
  query TDManhoursQuery($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...TimeRegistrationOverview
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
`;

export const TDManhours: FC<{ ticketNumber: string }> = ({ ticketNumber }) => {
  const { data, loading } = useGMQuery<
    TDManhoursQuery,
    TDManhoursQueryVariables
  >(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDWidgetWithCollapsibleRowsSkeleton showTopRightButton={true} />;
  }

  if (!data || data.ticket.__typename !== 'Ticket') {
    return null;
  }

  return <TDManhoursApollo {...data.ticket} />;
};
