import { gql } from '@apollo/client';
import { Grid, Skeleton } from '@mui/material';
import { FC } from 'react';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { CardWithTitleSkeleton } from '../../../components/shared/CardWithTitle';
import {
  TDErrorDescriptionApollo,
  TD_ERROR_DESCRIPTION_FRAGMENT,
} from '../../../components/ticket-details/TDErrorDescription';
import {
  TDErrorDescriptionQuery,
  TDErrorDescriptionQueryVariables,
} from './__apollo__/TDErrorDescriptionQuery';

const QUERY = gql`
  query TDErrorDescriptionQuery($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...TicketDetailsErrorDescription
      }
    }
  }
  ${TD_ERROR_DESCRIPTION_FRAGMENT}
`;

export const TDErrorDescription: FC<{ ticketNumber: string }> = ({
  ticketNumber,
}) => {
  const { data, loading } = useGMQuery<
    TDErrorDescriptionQuery,
    TDErrorDescriptionQueryVariables
  >(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDErrorDescriptionSkeleton />;
  }

  if (!data || data.ticket.__typename !== 'Ticket') {
    return null;
  }

  return <TDErrorDescriptionApollo {...data.ticket} />;
};

export const TDErrorDescriptionSkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton showTopRightButton={true}>
      <Grid container direction="column">
        <Grid item>
          <Skeleton
            variant="rectangular"
            width={100}
            height={25}
            sx={{ borderRadius: 4 }}
          />
        </Grid>
        {[1, 2].map((val) => {
          const width = val % 2 !== 0 ? 400 : 100;
          const height = val % 2 !== 0 ? 150 : 25;
          return (
            <Grid item key={val}>
              <Skeleton
                variant="text"
                width={width}
                height={height}
                sx={{ borderRadius: 1 }}
              />
            </Grid>
          );
        })}
      </Grid>
    </CardWithTitleSkeleton>
  );
};
