import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { skeletonify } from '../skeleton';
import { TicketAssignStatus_ticket$key } from './__generated__/TicketAssignStatus_ticket.graphql';

type Props = {
  ticket: TicketAssignStatus_ticket$key | null;
};

const ActualComponent: FC<Props> = ({ ticket: ticketRef }) => {
  const { translate } = useTranslation();
  const ticket = useFragment(
    graphql`
      fragment TicketAssignStatus_ticket on Ticket {
        status
      }
    `,
    ticketRef
  );

  if (!ticket) {
    return null;
  }

  return (
    <Grid container>
      {ticket.status === 'InProgress' && (
        <Grid item style={{ textAlign: 'center' }} xs={12}>
          <Icons.Assign style={{ fontSize: 42 }} />
          <Typography variant="body2">
            {translate('TICKET_CARD.ASSIGNED', 'Assigned')}
          </Typography>
        </Grid>
      )}
      {ticket.status === 'Released' && (
        <Grid item style={{ textAlign: 'center' }} xs={12}>
          <Icons.Unassign style={{ fontSize: 42 }} />
          <Typography variant="body2">
            {translate('TICKET_CARD.RELEASED', 'Released')}
          </Typography>
        </Grid>
      )}
      {ticket.status === 'Closed' && (
        <Grid item style={{ textAlign: 'center' }} xs={12}>
          <Icons.CloseTicket style={{ fontSize: 42 }} />
          <Typography variant="body2">
            {translate('TICKET_CARD.CLOSED', 'Closed')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const SkeletonComponent = () => <Skeleton height={75} />;

export const TicketAssignStatus = skeletonify(
  'TicketAssignStatus',
  ActualComponent,
  SkeletonComponent
);
