import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { LoadMoreFn, useFragment } from 'react-relay';
import { OperationType } from 'relay-runtime';
import { useTranslation } from '../../utility/i18n/translation';
import { InfiniteList } from '../components/InfiniteList';
import { TicketCard } from './TicketCard';
import {
  TicketList_ticket$data,
  TicketList_ticket$key,
} from './__generated__/TicketList_ticket.graphql';

type Item = TicketList_ticket$data[number];

export const TicketList: FC<{
  tickets: TicketList_ticket$key | undefined | null;
  hasNext: boolean;
  loadNext: LoadMoreFn<OperationType>;
  isLoadingNext: boolean;
  disableOverscrolling?: boolean;
}> = (props) => {
  const { translate } = useTranslation();
  const { tickets: ticketsRef, ...rest } = props;
  const tickets = useFragment(
    graphql`
      fragment TicketList_ticket on Ticket @relay(plural: true) {
        id
        ...TicketCard_ticket @defer(label: "ticket")
      }
    `,
    ticketsRef ?? null
  );

  const emptyLabel = translate('TICKET_LIST.EMPTY', 'No tickets found');
  const errorLabel = translate(
    'TICKET_LIST.ERROR',
    'Error fetching tickets, please refresh to try again.'
  );

  const itemRender = useCallback(
    (item: Item) => <TicketCard.Suspense ticket={item} />,
    []
  );
  const itemKeyExtractor = useCallback((item: Item) => item.id, []);
  return (
    <InfiniteList
      {...rest}
      emptyLabel={emptyLabel}
      errorLabel={errorLabel}
      items={tickets}
      itemRender={itemRender}
      itemKeyExtractor={itemKeyExtractor}
    />
  );
};
