import { hasValue } from '@lego/mst-error-utilities';
import {
  Grid,
  GridSize,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import debounce from 'lodash/debounce';
import {
  ChangeEvent,
  FC,
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useTranslation } from '../../../utility/i18n/translation';
import { isValidSparePartId } from '../../utils';
import {
  SearchFieldAdornments,
  SearchFieldAdornmentVariant,
} from '../equipment/SearchFieldAdornments';
import { LoadingTextField } from '../LoadingTextField';
import { SparePartSearchLabel } from './SparePartSearchLabel';
import SparePartSearchTextFieldQuery, {
  SparePartSearchTextFieldQuery as SparePartSearchTextFieldQueryType,
} from './__generated__/SparePartSearchTextFieldQuery.graphql';

type SparePartSearchTextFieldProps = {
  onSparePartSelected: (sparePartNumber: string) => void;
  initialSparePartNumber?: string;
  showPrefixLabel?: boolean;
  showInput?: boolean;
  showPlaceholder?: boolean;
  variant?: SearchFieldAdornmentVariant;
  prefixLabelStyle?: SxProps<Theme>;
  inputFieldSize?: GridSize;
};

type InnerComponentLoaderProps = Omit<
  SparePartSearchTextFieldProps,
  'initialSparePartNumber'
> & {
  onSearchIconClicked?: () => void;
  onClearIconClicked?: () => void;
  sparePartSearchString: string;
  setSparePartSearchString: (newValue: string) => void;
};

export const SparePartSearchTextField: FC<SparePartSearchTextFieldProps> = (
  props
) => {
  const [sparePartSearchString, setSparePartSearchString] = useState(
    props.initialSparePartNumber ?? ''
  );

  const { onSparePartSelected } = props;
  const variant = props.variant ?? 'search';

  const onClearIconClicked = useCallback(() => {
    setSparePartSearchString('');
    onSparePartSelected('');
  }, [onSparePartSelected]);

  return (
    <Fragment>
      <InnerComponentLoader
        {...props}
        onClearIconClicked={
          ['search-and-clear', 'clear'].includes(variant)
            ? onClearIconClicked
            : undefined
        }
        sparePartSearchString={sparePartSearchString}
        setSparePartSearchString={setSparePartSearchString}
      />
    </Fragment>
  );
};

const SkeletonLoadingView: FC<{
  searchString: string;
  inputFieldSize?: GridSize;
}> = ({ searchString, inputFieldSize = 5 }) => {
  const { translate } = useTranslation();
  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography>
          {translate(
            'CREATE_CMS_TICKET.SPAREPART_ID.TEXT',
            'Please enter spare part ID'
          )}
        </Typography>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Grid item lg={inputFieldSize}>
          <LoadingTextField loading value={searchString} />
        </Grid>
        <Grid item lg>
          <SparePartSearchLabel.Skeleton />
        </Grid>
      </Grid>
    </Grid>
  );
};

const InnerComponent: FC<
  InnerComponentLoaderProps & {
    query: PreloadedQuery<SparePartSearchTextFieldQueryType>;
  }
> = ({
  onSparePartSelected,
  query: queryRef,
  setSparePartSearchString,
  onSearchIconClicked,
  onClearIconClicked,
  showPrefixLabel = false,
  showInput = true,
  showPlaceholder = true,
  sparePartSearchString,
  prefixLabelStyle,
  inputFieldSize = 5,
  variant,
}) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  const { sparePart } = usePreloadedQuery<SparePartSearchTextFieldQueryType>(
    graphql`
      query SparePartSearchTextFieldQuery(
        $input: QuerySparePartInput!
        $skip: Boolean!
      ) {
        sparePart(input: $input) @skip(if: $skip) {
          ...SparePartSearchLabel_querySparePartResult
          ... on QuerySparePartSuccess {
            data {
              sparePartNumber
            }
          }
        }
      }
    `,
    queryRef
  );

  const onChangeDebounced = useMemo(() => {
    const searchDispatch: TextFieldProps['onChange'] = (event) => {
      const value = event?.target.value;
      if (!isValidSparePartId(value)) {
        return;
      }

      setSparePartSearchString(value);
      onSparePartSelected(value);
    };
    const debounced = debounce(searchDispatch, 500);

    return (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value ?? '';
      if (value === '') {
        onClearIconClicked && onClearIconClicked();
      }
      if (isNaN(Number(value))) {
        return;
      }
      setSparePartSearchString(value);
      debounced(event);
    };
  }, [setSparePartSearchString, onSparePartSelected, onClearIconClicked]);

  return (
    <Grid container spacing={1} direction="column">
      {showPrefixLabel && (
        <Grid item>
          <Typography sx={prefixLabelStyle}>
            {translate(
              'CREATE_CMS_TICKET.SPAREPART_ID.TEXT',
              'Please enter spare part ID'
            )}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="row" alignItems="center">
          {showInput ? (
            <Grid item xs={inputFieldSize}>
              <TextField
                placeholder={
                  showPlaceholder
                    ? translate(
                        'CREATE_CMS_TICKET.SPAREPART_ID.PLACEHOLDER',
                        'Enter spare part ID'
                      )
                    : undefined
                }
                style={{ backgroundColor: palette.background.paper }}
                onChange={onChangeDebounced}
                value={sparePartSearchString}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <SearchFieldAdornments
                      variant={variant}
                      onSearchIconClicked={onSearchIconClicked}
                      onClearIconClicked={onClearIconClicked}
                    />
                  ),
                }}
              />
            </Grid>
          ) : (
            <Grid item>
              <Typography>{sparePart?.data?.sparePartNumber}</Typography>
            </Grid>
          )}
          {sparePart && (
            <Grid item xs>
              <SparePartSearchLabel.Suspense sparePart={sparePart} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const InnerComponentLoader: FC<InnerComponentLoaderProps> = ({
  setSparePartSearchString,
  onSearchIconClicked,
  sparePartSearchString,
  ...rest
}) => {
  const [queryRef, loadQuery] =
    useQueryLoader<SparePartSearchTextFieldQueryType>(
      SparePartSearchTextFieldQuery
    );
  const [, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      loadQuery(
        {
          input: {
            sparePartNumber: sparePartSearchString,
          },
          skip:
            !hasValue(sparePartSearchString) ||
            !isValidSparePartId(sparePartSearchString),
        },
        { fetchPolicy: 'store-and-network' }
      );
    });
  }, [sparePartSearchString, loadQuery]);

  return (
    <Suspense
      fallback={<SkeletonLoadingView searchString={sparePartSearchString} />}
    >
      {queryRef ? (
        <InnerComponent
          {...rest}
          query={queryRef}
          onSearchIconClicked={onSearchIconClicked}
          sparePartSearchString={sparePartSearchString}
          setSparePartSearchString={setSparePartSearchString}
        />
      ) : (
        <SkeletonLoadingView searchString={sparePartSearchString} />
      )}
    </Suspense>
  );
};
