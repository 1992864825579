import { Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { AddMachineHoursProvider } from '../../contexts/add-machine-hours/add-machine-hours-context';
import { TDMachineHoursQuery_ticket_Ticket } from '../../migration/ticket-details/widgets/__apollo__/TDMachineHoursQuery';
import { AppRoutes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { AddMachineHoursOverview } from '../add-time/overview/machine-hours-overview/MachineHoursOverview';
import { CardWithTitle } from '../shared/CardWithTitle';
import { TDWidgetAddButton } from './TDWidgetAddButton';

export const TDMachineHoursApollo: FC<TDMachineHoursQuery_ticket_Ticket> = (
  data
) => {
  const { translate } = useTranslation();
  const title = translate(
    'TICKET_DETAILS.CARDS.MACHINE_HOURS.TITLE',
    'Machine hours'
  );

  const {
    ticket: { canAddMachineTimeRegistration },
  } = usePermissions();

  const canAddTime = canAddMachineTimeRegistration(data);

  const rightItem = canAddTime ? <AddButton /> : undefined;

  if (data.totalMachineHoursInMinutes === 0) {
    return (
      <CardWithTitle title={title} rightItem={rightItem}>
        <Typography>
          {translate(
            'TICKET_DETAILS.CARDS.MACHINE_HOURS.NO_REGISTRATIONS',
            'No machine hours registered yet'
          )}
        </Typography>
      </CardWithTitle>
    );
  }

  return (
    <CardWithTitle title={title} rightItem={rightItem}>
      <AddMachineHoursProvider>
        <AddMachineHoursOverview {...data} interactive={false} />
      </AddMachineHoursProvider>
    </CardWithTitle>
  );
};

const AddButton: FC = () => {
  const { translate } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <TDWidgetAddButton
      icon={<Icons.Edit />}
      routeTo={AppRoutes.tickets.machineHours(id)}
      title={translate(
        'TICKET_DETAILS.CARDS.MACHINE_HOURS.BUTTON',
        'Machine hours'
      )}
    />
  );
};
