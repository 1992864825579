import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { differenceInSeconds } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { useFragment, useRefetchableFragment } from 'react-relay';
import { useParams } from 'react-router-dom';
import { ActivityIndicator } from '../../../components/shared/ActivityIndicator';
import { FillWidthLoading } from '../../../components/shared/FillWidthLoading';
import { useFormatRelativeWithLocale } from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { HallMonitorTicketsStatuses } from '../hall-monitor-setup/HallMonitorCreateDialog';
import { HallMonitorItem_hallMonitorScreen$key } from './__generated__/HallMonitorItem_hallMonitorScreen.graphql';
import {
  HallMonitorItem_priority$key,
  TicketPriority,
} from './__generated__/HallMonitorItem_priority.graphql';
import { useInterval } from '../../../utility/interval';
import { HallMonitorItem_section$key } from './__generated__/HallMonitorItem_section.graphql';

interface StaleData {
  title: string;
  lastRefreshedAt: string;
  percentageBeforeRefresh: number;
  loading: boolean;
  sections: readonly HallMonitorItem_section$key[];
}

const staleData: StaleData = {
  title: '',
  lastRefreshedAt: 'N/A',
  percentageBeforeRefresh: 0,
  loading: true,
  sections: [],
}

const SECONDS_BETWEEN_QUERY_UPDATES = 60;

/**
 * Hard refresh the page for potential app updates every hour
 *
 * To try to safe-guard agains reloading without internet available,
 * we try to do a fetch and only if the response is a 20X response,
 * do we try the reload. Still leaves a window for failure, but it should
 * be so rare that we hopefully can live with bringing out the ladder. :-)
 */
const useRefreshTimer = () => {
  useInterval(async () => {

    try {
      const response = await fetch(location.href);
      if (response.status.toString().startsWith("20")) {
        location.reload();
      }
    } catch { //eslint-disable: no-empty - we don't want to do anything in case of error

    }

  }, 1000 * 60 * 60);
}

/**
 * Refresh the query data on a fixed timer
 * @param refetch
 */
const useRefetchTimer = (refetch: () => void) => {
  useInterval(() => refetch(), SECONDS_BETWEEN_QUERY_UPDATES * 1000);
};

const usePercentageBeforeRefresh = (lastRefreshedAt: number) => {
  const [percentageBeforeRefresh, setPercentageBeforeRefresh] = useState(0);

  useInterval(() => {
    const diff = differenceInSeconds(Date.now(), lastRefreshedAt);
    const percentage = Math.floor(
      (diff / SECONDS_BETWEEN_QUERY_UPDATES) * 100
    );
    setPercentageBeforeRefresh(percentage);
  }, 1000);

  return percentageBeforeRefresh;
}

const ActualComponent: FC<{ hallMonitorScreen: HallMonitorItem_hallMonitorScreen$key; useStaleData: boolean }> = (props) => {
  const { hallMonitorScreen: hallMonitorVariantRef, useStaleData } = props;
  const id = useParams() as { id: string }
  const [lastRefreshedAt, setLastRefreshedAt] = useState(Date.now());
  const lastRefreshedAtString = useFormatRelativeWithLocale(lastRefreshedAt);
  const [loading, setLoading] = useState(false);

  const percentageBeforeRefresh = usePercentageBeforeRefresh(lastRefreshedAt);
  const [{ title, sections }, refetchQuery] = useRefetchableFragment(
    graphql`
    fragment HallMonitorItem_hallMonitorScreen on HallMonitorScreen
      @refetchable(queryName: "HallMonitorItemRefetchQuery") {
      title
      sections {
        ...HallMonitorItem_section
      }
    }`,
    hallMonitorVariantRef
  );

  const refetch = useCallback(() => {
    setLoading(true);
    refetchQuery(
      { nodeId: id },
      {
        fetchPolicy: 'store-and-network',
        onComplete: (error) => {
          if (!error) {
            setLastRefreshedAt(Date.now());
          }

          // The timeout is to avoid jitter in the progress circle animation
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        },
      }
    );
  }, [id, refetchQuery]);

  useRefetchTimer(refetch);
  useRefreshTimer();

  if (useStaleData) {
    return (
      <HallMonitorItemContent useStaleData={true} />
    )
  } else {
    return (
      <HallMonitorItemContent
        title={title}
        lastRefreshedAt={lastRefreshedAtString}
        percentageBeforeRefresh={percentageBeforeRefresh}
        loading={loading}
        sections={sections}
      />
    );
  }
}

interface HallMonitorItemContentWithDataProps {
  title: string;
  lastRefreshedAt: string;
  percentageBeforeRefresh: number;
  loading: boolean;
  sections: readonly HallMonitorItem_section$key[];
  useStaleData?: false;
}

interface HallMonitorItemContentStaleProps {
  useStaleData: true;
}

type HallMonitorItemContentProps = HallMonitorItemContentStaleProps | HallMonitorItemContentWithDataProps;

export const HallMonitorItemContent: FC<HallMonitorItemContentProps> = (props) => {
  const { translate } = useTranslation();

  if (!props.useStaleData) {
    staleData.title = props.title;
    staleData.loading = props.loading;
    staleData.lastRefreshedAt = props.lastRefreshedAt;
    staleData.sections = props.sections;
  }

  const data = (props.useStaleData ? staleData : props) as HallMonitorItemContentWithDataProps;
  const { title, lastRefreshedAt, loading, percentageBeforeRefresh, sections } = data;

  return (
    <Container maxWidth={false}>
      <Typography variant="h1" align="center" mb={2}>
        {title}
      </Typography>
      <Stack direction={'row'} spacing={2} justifyContent="center">
        <Typography variant="h4" align="center" mb={2}>
          {translate(
            'HALL_MONITOR.LAST_UPDATED',
            'Last updated {{ lastUpdatedAt }}',
            {
              lastUpdatedAt: lastRefreshedAt,
            }
          )}
        </Typography>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <CircularProgress
            variant="determinate"
            value={percentageBeforeRefresh}
          />
        )}
      </Stack>
      <Grid container spacing={2}>
        {sections.map((section, index) => (
          <Grid item xs key={index}>
            <CounterWidget section={section} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

type CounterWidgetProps = {
  section: HallMonitorItem_section$key;
};

const CounterWidget: FC<CounterWidgetProps> = ({ section: sectionRef }) => {
  const { title, priorities } = useFragment(
    graphql`
      fragment HallMonitorItem_section on HallMonitorScreenSection {
        title
        priorities @required(action: THROW) {
          ...HallMonitorItem_priority
          value
          count
        }
      }
    `,
    sectionRef
  );
  const sorted = [...priorities].sort((a, b) => a.value.localeCompare(b.value));
  const total = sorted.reduce((prev, curr) => (prev += curr.count), 0);
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Grid container alignItems="center" direction="row" height="100%">
        {/* Left half with total and title */}
        <Grid item xs={8}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography
                variant="h1"
                fontSize="12rem"
                fontWeight="700"
                lineHeight="12rem"
              >
                {total}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="3rem" fontWeight="500" lineHeight="3rem">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Priorities */}
        <Grid item xs={4}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            {sorted.map((item) => (
              <Grid item key={item.value.toString()}>
                <PriorityCount priority={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const PriorityCount: FC<{
  priority: HallMonitorItem_priority$key;
}> = ({ priority: priorityRef }) => {
  const { count, value } = useFragment(
    graphql`
      fragment HallMonitorItem_priority on PriorityFacet {
        count
        value
      }
    `,
    priorityRef
  );
  const { getColorForPriority } = useColorForPriority();
  const priorityColor = getColorForPriority(value);
  const label = getLabelForPriority(value);

  return (
    <Box
      sx={{
        borderRadius: 12,
        border: 8,
        borderColor: priorityColor,
        px: 4,
        py: 2.5,
      }}
    >
      <Typography
        variant="h6"
        fontSize="2rem"
        fontWeight="500"
        lineHeight="2rem"
      >
        P{label}
      </Typography>
      <Typography
        align="center"
        variant="h1"
        fontSize="5rem"
        fontWeight="700"
        lineHeight="5rem"
      >
        {count}
      </Typography>
    </Box>
  );
};

const SkeletonComponent: FC = () => {
  return <FillWidthLoading />;
};

export const HallMonitorItem = skeletonify(
  'HallMonitorItem',
  ActualComponent,
  SkeletonComponent
);

export const useColorForPriority = () => {
  const { palette } = useTheme();

  const getColorForPriority = (priority: TicketPriority): string => {
    switch (priority) {
      case 'Priority1':
        return palette.error.main;
      case 'Priority2':
        return palette.warning.dark;
      case 'Priority3':
        return palette.warning.main;
      case 'Priority4':
        return palette.text.disabled;
      case 'Priority5':
        return palette.text.disabled;
      case 'Priority6':
        return palette.text.disabled;
      case 'Priority7':
        return palette.text.disabled;
      case 'Priority8':
        return palette.text.disabled;
      case 'Priority9':
        return palette.text.disabled;
      case 'PriorityA':
        return palette.text.disabled;

      default:
        return palette.primary.main;
    }
  };

  return { getColorForPriority };
};

export const getLabelForPriority = (priority: TicketPriority) =>
  priority.startsWith('Priority') ? priority.substring('Priority'.length) : '?';

export const useLabelForTicketStatus = () => {
  const { translate } = useTranslation();
  const getLabelForTicketStatus = (
    status: HallMonitorTicketsStatuses
  ): string => {
    switch (status) {
      case 'NotStarted':
        return translate(
          'HALL_MONITOR.CREATE_DIALOG.TICKET_STATUSES.NOT_STARTED',
          'Not started'
        );
      case 'InProgress':
        return translate(
          'HALL_MONITOR.CREATE_DIALOG.TICKET_STATUSES.IN_PROGRESS',
          'In progress'
        );
      case 'Released':
        return translate(
          'HALL_MONITOR.CREATE_DIALOG.TICKET_STATUSES.RELEASED',
          'Released'
        );
      case 'Closed':
        return translate(
          'HALL_MONITOR.CREATE_DIALOG.TICKET_STATUSES.Closed',
          'Closed'
        );
    }
  };

  return { getLabelForTicketStatus };
};
