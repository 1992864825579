/**
 * @generated SignedSource<<5bf282368fa9c6e9b05cfccd4c303f76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ErrorDescription_ticket$data = {
  readonly errorDescription: ReadonlyArray<{
    readonly description: string;
    readonly timestamp?: {
      readonly date: string;
      readonly time: string;
    };
  }> | null;
  readonly " $fragmentType": "ErrorDescription_ticket";
};
export type ErrorDescription_ticket$key = {
  readonly " $data"?: ErrorDescription_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorDescription_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeTimestamp"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorDescription_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "errorDescription",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "condition": "includeTimestamp",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LocalTimestamp",
              "kind": "LinkedField",
              "name": "timestamp",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "88b2a9e08e72373f1681c6e6bda39f55";

export default node;
