import { gql } from '@apollo/client/core';
import {
  TicketFlowType,
  TicketPriorityEnum,
  TicketTypeEnum,
} from '../../__apollo__/types';
import {
  canCloseGreenCleaningMould,
  CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT,
} from '../canCloseGreenCleaningMould';
import {
  isTicketEditable,
  PERMISSIONS_PRIORITY_ALLOWED,
} from './commonFragments';
import { PermissionResult } from './usePermissions';
import { CanAddErrorDescription } from './__apollo__/CanAddErrorDescription';
import { CanAddMachineTimeRegistration } from './__apollo__/CanAddMachineTimeRegistration';
import { CanAddRepairDescription } from './__apollo__/CanAddRepairDescription';
import { CanAddTimeRegistration } from './__apollo__/CanAddTimeRegistration';
import { CanAssignTicket } from './__apollo__/CanAssignTicket';
import { CanCloseMouldGreenTicket } from './__apollo__/CanCloseMouldGreenTicket';
import { CanCreateTicketOnEquipment } from './__apollo__/CanCreateTicketOnEquipment';
import { CanEditEquipmentLocation } from './__apollo__/CanEditEquipmentLocation';
import { CanEditTicketEquipment } from './__apollo__/CanEditTicketEquipment';
import { CanEditTicketPriority } from './__apollo__/CanEditTicketPriority';
import { CanUnassign } from './__apollo__/CanUnassign';
import { IsAllowedToManageTicket } from './__apollo__/IsAllowedToManageTicket';

export const PERMISSIONS_ALLOWED_TO_MANAGE_TICKET = gql`
  fragment IsAllowedToManageTicket on Ticket {
    id
    isAssigned
    ...IsTicketEditable
  }
  ${PERMISSIONS_PRIORITY_ALLOWED}
`;

export const isAllowedToManage = (data: IsAllowedToManageTicket): boolean => {
  return data.isAssigned && isTicketEditable(data);
};

export const PERMISSIONS_CAN_ASSIGN_FRAGMENT = gql`
  fragment CanAssignTicket on Ticket {
    id
    isAssigned
    priority
    flowType
    isCmsTicket
    ...CanCloseGreenCleaningMould
    ...IsTicketEditable
  }
  ${PERMISSIONS_PRIORITY_ALLOWED}
  ${CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT}
`;

export const canAssignTicket =
  (isAllowedToAssign: boolean) =>
  (data: CanAssignTicket): boolean => {
    const isPriorityAllowedToBeAssigned = [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority3,
      TicketPriorityEnum.Priority4,
      TicketPriorityEnum.Priority5,
      TicketPriorityEnum.Priority9,
    ];

    const isCmsPriorityAllowedToBeAssigned = [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority8,
    ];

    const priorityAllowed = (): boolean => {
      if (data.isCmsTicket) {
        return isCmsPriorityAllowedToBeAssigned.includes(data.priority);
      }
      return isPriorityAllowedToBeAssigned.includes(data.priority);
    };

    const isPreventiveCleaning = canCloseGreenCleaningMould(data);

    const result =
      (priorityAllowed() || isPreventiveCleaning) &&
      !data.isAssigned &&
      (isTicketEditable(data) || data.isCmsTicket) &&
      !isYellowFlowType(data.flowType) &&
      isAllowedToAssign;

    return result;
  };

export const PERMISSIONS_CAN_CLOSE_FRAGMENT = gql`
  fragment CanCloseMouldGreenTicket on Ticket {
    id
    priority
    isAssigned
    isOpen
    flowType
    isCmsTicket
    ...CanCloseGreenCleaningMould
    equipment {
      __typename
      ... on EquipmentValue {
        value {
          id
          __typename
        }
      }
    }
  }
  ${CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT}
`;

export const canCloseTicket =
  (isAllowedToClose: boolean) =>
  (data: CanCloseMouldGreenTicket): boolean => {
    const isMould =
      data.equipment?.__typename === 'EquipmentValue' &&
      data.equipment.value.__typename === 'Mould';

    const { priority, isAssigned, isOpen } = data;

    const allowedPrioritiesForEquipment: TicketPriorityEnum[] = [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority3,
      TicketPriorityEnum.Priority4,
      TicketPriorityEnum.Priority5,
      TicketPriorityEnum.Priority9,
    ];

    const allowedPrioritiesForMoulds: TicketPriorityEnum[] = [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority4,
      TicketPriorityEnum.Priority5,
    ];

    const allowedPrioritiesForCms: TicketPriorityEnum[] = [
      TicketPriorityEnum.Priority1,
      TicketPriorityEnum.Priority2,
      TicketPriorityEnum.Priority8,
    ];

    const priorityAllowed = () => {
      if (data.isCmsTicket) {
        return allowedPrioritiesForCms.includes(priority);
      }
      return isMould
        ? allowedPrioritiesForMoulds.includes(priority)
        : allowedPrioritiesForEquipment.includes(priority);
    };

    const isPreventiveCleaning = canCloseGreenCleaningMould(data);

    // We only support close flow for mould tickets of priority 1,2,4,5 and 7 so far.
    const showCloseTicket =
      isAllowedToClose &&
      isAssigned &&
      isOpen &&
      !isYellowFlowType(data.flowType) &&
      (priorityAllowed() || isPreventiveCleaning);

    return showCloseTicket;
  };

export const PERMISSIONS_CAN_UNSASSIGN = gql`
  fragment CanUnassign on Ticket {
    id
    isOpen
    isAssigned
    flowType
    isCmsTicket
    ...IsAllowedToManageTicket
  }
  ${PERMISSIONS_ALLOWED_TO_MANAGE_TICKET}
`;

export const canUnassignTicket =
  (isAllowedToUnassign: boolean) =>
  (data: CanUnassign): boolean => {
    return (
      isAllowedToUnassign &&
      !isYellowFlowType(data.flowType) &&
      (isAllowedToManage(data) || data.isCmsTicket) &&
      data.isOpen &&
      data.isAssigned
    );
  };

export const PERMISSIONS_CAN_ADD_ERROR_DESCRIPTION = gql`
  fragment CanAddErrorDescription on Ticket {
    id
    isOpen
    type
    flowType
    ...IsTicketEditable
  }
  ${PERMISSIONS_PRIORITY_ALLOWED}
`;

export const canAddErrorDescription =
  (isAllowedAddErrorDescription: boolean) =>
  (data: CanAddErrorDescription): boolean => {
    return (
      isAllowedAddErrorDescription &&
      data.isOpen &&
      isTicketEditable(data) &&
      !isYellowFlowType(data.flowType) &&
      !(data.type === TicketTypeEnum.Preventive)
    );
  };

export const PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION = gql`
  fragment CanAddRepairDescription on Ticket {
    id
    isOpen
    type
    isAssigned
    flowType
    ...IsTicketEditable
  }
  ${PERMISSIONS_PRIORITY_ALLOWED}
`;

export const canAddRepairDescription =
  (isAllowedToAddRepairDocumentation: boolean) =>
  (data: CanAddRepairDescription): boolean => {
    return (
      isAllowedToAddRepairDocumentation &&
      data.isAssigned &&
      data.isOpen &&
      !isYellowFlowType(data.flowType) &&
      isTicketEditable(data)
    );
  };

export const PERMISSIONS_CAN_ADD_TIME_REGISTRATION = gql`
  fragment CanAddTimeRegistration on Ticket {
    id
    isAssigned
    isOpen
    flowType
    ...IsTicketEditable
  }
  ${PERMISSIONS_PRIORITY_ALLOWED}
`;

export const canAddTimeRegistration =
  (isAllowedToAddTimeRegistration: boolean) =>
  (data: CanAddTimeRegistration): boolean => {
    return (
      isAllowedToAddTimeRegistration &&
      data.isAssigned &&
      data.isOpen &&
      !isYellowFlowType(data.flowType)
    );
  };

export const PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION = gql`
  fragment CanAddMachineTimeRegistration on Ticket {
    id
    isAssigned
    isOpen
    isCmsTicket
  }
`;

export const canAddMachineTimeRegistration =
  (isAllowedToAddTimeRegistration: boolean) =>
  (data: CanAddMachineTimeRegistration): boolean => {
    return (
      isAllowedToAddTimeRegistration &&
      data.isAssigned &&
      data.isOpen &&
      data.isCmsTicket
    );
  };

export const PERMISSIONS_CAN_EDIT_TICKET_EQUIPMENT = gql`
  fragment CanEditTicketEquipment on Ticket {
    id
    hasActiveCost
    isOpen
    isAssigned
    equipment {
      ... on EquipmentValue {
        value {
          id
          __typename
        }
      }
    }
  }
`;

export enum CanEditTicketRejectionReasons {
  TicketClosed,
  TicketHasActiveCost,
  UserNotAppropriateRole,
  EquipmentIsMould,
}

export const canEditTicketEquipment =
  ({
    isAllowedToEditAssignedTicketsEquipment,
    isAllowedToEditUnassignedTicketsEquipment,
  }: {
    isAllowedToEditAssignedTicketsEquipment: boolean;
    isAllowedToEditUnassignedTicketsEquipment: boolean;
  }) =>
  (
    data: CanEditTicketEquipment
  ): PermissionResult<CanEditTicketRejectionReasons> => {
    const reasons: CanEditTicketRejectionReasons[] = [];

    if (
      (data.isAssigned && !isAllowedToEditAssignedTicketsEquipment) ||
      (!data.isAssigned && !isAllowedToEditUnassignedTicketsEquipment)
    ) {
      reasons.push(CanEditTicketRejectionReasons.UserNotAppropriateRole);
    }

    if (!data.isOpen) {
      reasons.push(CanEditTicketRejectionReasons.TicketClosed);
    }

    if (data.hasActiveCost) {
      reasons.push(CanEditTicketRejectionReasons.TicketHasActiveCost);
    }

    if (
      data.equipment?.__typename === 'EquipmentValue' &&
      data.equipment.value.__typename === 'Mould'
    ) {
      reasons.push(CanEditTicketRejectionReasons.EquipmentIsMould);
    }

    return {
      isAllowed: reasons.length === 0,
      rejectionReasons: reasons,
    };
  };

export const PERMISSIONS_CAN_EDIT_TICKET_PRIORITY = gql`
  fragment CanEditTicketPriority on Ticket {
    id
    isOpen
    isAssigned
    equipment {
      ... on EquipmentValue {
        value {
          id
          plantId
        }
      }
    }
  }
`;

export enum CanEditTicketPriorityRejectionReasons {
  EquipmentIsMould,
  TicketClosed,
  UserNotAppropriateRole,
}

export const canEditTicketPriority =
  ({
    isAllowedToEditAssignedTicketsPriority,
    isAllowedToEditUnassignedTicketsPriority,
  }: {
    isAllowedToEditAssignedTicketsPriority: boolean;
    isAllowedToEditUnassignedTicketsPriority: boolean;
  }) =>
  (
    data: CanEditTicketPriority
  ): PermissionResult<CanEditTicketPriorityRejectionReasons> => {
    const isAllowedToEditTicketPriorityRejectionReasons: CanEditTicketPriorityRejectionReasons[] =
      [];

    if (
      (data.isAssigned && !isAllowedToEditAssignedTicketsPriority) ||
      (!data.isAssigned && !isAllowedToEditUnassignedTicketsPriority)
    ) {
      isAllowedToEditTicketPriorityRejectionReasons.push(
        CanEditTicketPriorityRejectionReasons.UserNotAppropriateRole
      );
    }

    if (!data.isOpen) {
      isAllowedToEditTicketPriorityRejectionReasons.push(
        CanEditTicketPriorityRejectionReasons.TicketClosed
      );
    }

    return {
      isAllowed: isAllowedToEditTicketPriorityRejectionReasons.length === 0,
      rejectionReasons: isAllowedToEditTicketPriorityRejectionReasons,
    };
  };

export const PERMISSIONS_CAN_CREATE_TICKET_ON_EQUIPMENT = gql`
  fragment CanCreateTicketOnEquipment on IEquipment {
    id
    plantId
    isActive
  }
`;

export enum CreateTicketOnEquipmentPermissionRejectionReasons {
  Inactive,
  OnOtherPlant,
}

export const canCreateTicket =
  (currentPlantId: string) =>
  (
    data: CanCreateTicketOnEquipment
  ): PermissionResult<CreateTicketOnEquipmentPermissionRejectionReasons> => {
    const reasons: CreateTicketOnEquipmentPermissionRejectionReasons[] = [];
    if (!data.isActive) {
      reasons.push(CreateTicketOnEquipmentPermissionRejectionReasons.Inactive);
    }

    if (data.plantId.toString() !== currentPlantId) {
      reasons.push(
        CreateTicketOnEquipmentPermissionRejectionReasons.OnOtherPlant
      );
    }

    return {
      isAllowed: reasons.length === 0,
      rejectionReasons: reasons,
    };
  };

export const PERMISSIONS_CAN_EDIT_EQUIPMENT_LOCATION = gql`
  fragment CanEditEquipmentLocation on IEquipment {
    id
    isLocked
    isActive
    __typename
  }
`;

export const canEditEquipmentLocation = (
  data: CanEditEquipmentLocation
): boolean => {
  return !data.isLocked && data.isActive;
};

const isYellowFlowType = (flowType: TicketFlowType | null): boolean => {
  if (!flowType) {
    return false;
  }
  return [
    TicketFlowType.YellowFunctionalTest,
    TicketFlowType.YellowRepair,
    TicketFlowType.YellowToMPEE,
    TicketFlowType.YellowToProduction,
    TicketFlowType.YellowToProductionRejected,
    TicketFlowType.YellowToQA,
    TicketFlowType.YellowToQAApproved,
  ].includes(flowType);
};
