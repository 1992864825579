import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Container, Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactElement, ReactNode } from 'react';
import { useFragment } from 'react-relay';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from '../../components/add-time/overview/time-registration-gql-fragments';
import { TD_ASSIGNED_TO_FRAGMENT } from '../../components/ticket-details/TDAssignedTo';
import { TD_CURRENT_LOCATION } from '../../components/ticket-details/TDCurrentLocation';
import { TD_ERROR_DESCRIPTION_FRAGMENT } from '../../components/ticket-details/TDErrorDescription';
import { TD_REPAIR_DOCUMENTATION } from '../../components/ticket-details/TDRepairFragments';
import {
  TypeChecked,
  useTicketQueryTypeCheck,
} from '../../containers/TicketByIdTypecheckHook';
import { ManufacturerWidget } from '../components/equipment/ManufacturerWidget';
import { WarrantyWidget } from '../components/equipment/WarrantyWidget';
import { skeletonify } from '../skeleton';
import {
  GetTicketDetails,
  GetTicketDetailsVariables,
  GetTicketDetails_ticket_Ticket,
} from './__apollo__/GetTicketDetails';
import { TicketDetailsPane_ticket$key } from './__generated__/TicketDetailsPane_ticket.graphql';
import { TDAssignedTo } from './widgets/TDAssignedTo';
import { TDCreatedBy } from './widgets/TDCreatedBy';
import {
  TDCurrentLocation,
  TDCurrentLocationSkeleton,
} from './widgets/TDCurrentLocation';
import { TDDimmingDetails } from './widgets/TDDimmingDetails';
import {
  TDErrorDescription,
  TDErrorDescriptionSkeleton,
} from './widgets/TDErrorDescription';
import { TDMachineHours } from './widgets/TDMachineHours';
import { TDManhours } from './widgets/TDManhours';
import { TDMouldDetails } from './widgets/TDMouldDetails';
import { TDMouldStatus } from './widgets/TDMouldStatus';
import { TDRepairDescription } from './widgets/TDRepairDescription';
import { TDSpringChange } from './widgets/TDSpringChange';
import { TDSysCleaning } from './widgets/TDSysCleaning';
import { TDWidgetWithCollapsibleRowsSkeleton } from './widgets/TDWidgetWithCollapsibleRowsSkeleton';

export const TICKET_DETAILS_FRAGMENT = gql`
  fragment TicketDetailsFragment on Ticket {
    id
    isCmsTicket
    ...AssignedTo
    ...TicketDetailsErrorDescription
    ...TicketDetailsRepairDescriptions
    ...TimeRegistrationOverview
    equipment {
      ... on EquipmentValue {
        value {
          id
          ...TicketDetailsLocation
        }
      }
    }
  }
  ${TD_ASSIGNED_TO_FRAGMENT}
  ${TD_CURRENT_LOCATION}
  ${TD_ERROR_DESCRIPTION_FRAGMENT}
  ${TD_REPAIR_DOCUMENTATION}
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
`;

export const TICKET_DETAILS_QUERY = gql`
  query GetTicketDetails($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...TicketDetailsFragment
      }
    }
  }
  ${TICKET_DETAILS_FRAGMENT}
`;

const ActualComponent = ({
  ticket: ticketRef,
}: {
  ticket?: TicketDetailsPane_ticket$key;
}) => {
  const ticket = useFragment(
    graphql`
      fragment TicketDetailsPane_ticket on Ticket {
        __typename
        ticketNumber
        equipment {
          equipmentNumber
        }
        ...TDCreatedBy_ticket @defer
        equipment {
          __typename
          ...TDMouldDetails_equipment @defer
          ...TDMouldStatus_equipment @defer
          ...TDDimmingDetails_mould @defer
          ...ManufacturerWidget_equipment @defer
          ...WarrantyWidget_equipment @defer
          ...TDSysCleaning_mould @defer
          ...TDSpringChange_mould @defer
          ... on Mould {
            springChangeInterval
            cleaningInterval
          }
        }
      }
    `,
    ticketRef ?? null
  );

  const ticketNumber = ticket?.ticketNumber?.toString();
  const equipmentNumber = ticket?.equipment?.equipmentNumber?.toString();

  return {
    createdBy: <TDCreatedBy employee={ticket} />,
    assignedTo: hasValue(ticketNumber) ? (
      <TDAssignedTo ticketNumber={ticketNumber} />
    ) : undefined,
    mouldDetails:
      ticket?.equipment?.__typename === 'Mould' ? (
        <TDMouldDetails equipment={ticket?.equipment} />
      ) : undefined,
    mouldStatus:
      ticket?.equipment?.__typename === 'Mould' ? (
        <TDMouldStatus equipment={ticket?.equipment} />
      ) : undefined,

    dimmingDetails:
      ticket?.equipment?.__typename === 'Mould' ? (
        <TDDimmingDetails
          mould={ticket.equipment}
          canEditDimmings={ticket.__typename !== 'CMSTicket'}
        />
      ) : undefined,
    currentLocation: hasValue(equipmentNumber) ? (
      <TDCurrentLocation equipmentNumber={equipmentNumber} />
    ) : undefined,
    errorDescription: hasValue(ticketNumber) ? (
      <TDErrorDescription ticketNumber={ticketNumber} />
    ) : undefined,
    repairDescription:
      hasValue(ticketNumber) && ticket?.__typename !== 'CMSTicket' ? (
        <TDRepairDescription ticketNumber={ticketNumber} />
      ) : undefined,
    manhours: hasValue(ticketNumber) ? (
      <TDManhours ticketNumber={ticketNumber} />
    ) : undefined,
    machineHours: hasValue(ticketNumber) ? (
      <TDMachineHours ticketNumber={ticketNumber} />
    ) : undefined,
    warranty: <WarrantyWidget equipment={ticket?.equipment ?? null} />,
    manufacturer: <ManufacturerWidget equipment={ticket?.equipment ?? null} />,
    sysCleaning:
      ticket?.equipment?.__typename === 'Mould' &&
      ticket?.equipment?.cleaningInterval !== 0 ? (
        <TDSysCleaning equipment={ticket?.equipment} />
      ) : undefined,
    springChange:
      ticket?.equipment?.__typename === 'Mould' &&
      ticket?.equipment?.springChangeInterval !== 0 ? (
        <TDSpringChange equipment={ticket?.equipment} />
      ) : undefined,
  };
};
const SkeletonComponent = () => ({
  createdBy: <TDCreatedBy.Skeleton />,
  assignedTo: <TDCreatedBy.Skeleton />,
  mouldDetails: <TDMouldDetails.Skeleton />,
  mouldStatus: <TDMouldStatus.Skeleton />,
  dimmingDetails: <TDDimmingDetails.Skeleton />,
  currentLocation: <TDCurrentLocationSkeleton />,
  errorDescription: <TDErrorDescriptionSkeleton />,
  repairDescription: <TDWidgetWithCollapsibleRowsSkeleton />,
  manhours: <TDWidgetWithCollapsibleRowsSkeleton />,
  machineHours: <TDWidgetWithCollapsibleRowsSkeleton />,
  warranty: <WarrantyWidget.Skeleton />,
  manufacturer: <ManufacturerWidget.Skeleton />,
});
const StructureComponent = (props: {
  createdBy: ReactElement;
  assignedTo?: ReactElement;
  warranty: ReactElement;
  manufacturer: ReactElement;
  mouldDetails?: ReactElement;
  mouldStatus?: ReactElement;
  springChange?: ReactElement;
  sysCleaning?: ReactElement;
  dimmingDetails?: ReactElement;
  currentLocation?: ReactElement;
  errorDescription?: ReactElement;
  repairDescription?: ReactElement;
  manhours?: ReactElement;
  machineHours?: ReactElement;
}) => {
  const {
    assignedTo,
    createdBy,
    currentLocation,
    dimmingDetails,
    errorDescription,
    manhours,
    mouldDetails,
    mouldStatus,
    sysCleaning,
    springChange,
    repairDescription,
    warranty,
    manufacturer,
    machineHours,
  } = props;

  return (
    <DetailsPaneWrapper>
      <Grid
        container
        direction="column"
        spacing={2}
        data-cy="TicketDetailsContainer"
      >
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} lg>
            {createdBy}
          </Grid>
          <Grid item xs={12} lg>
            {assignedTo}
          </Grid>
        </Grid>
        {warranty}
        {manufacturer}
        {mouldDetails && <FullWidthGrid>{mouldDetails}</FullWidthGrid>}
        {mouldStatus && <FullWidthGrid>{mouldStatus}</FullWidthGrid>}
        {sysCleaning && <FullWidthGrid>{sysCleaning}</FullWidthGrid>}
        {springChange && <FullWidthGrid>{springChange}</FullWidthGrid>}
        {currentLocation && <FullWidthGrid>{currentLocation}</FullWidthGrid>}
        {errorDescription && <FullWidthGrid>{errorDescription}</FullWidthGrid>}

        {dimmingDetails && <FullWidthGrid>{dimmingDetails}</FullWidthGrid>}
        {repairDescription && (
          <FullWidthGrid>{repairDescription}</FullWidthGrid>
        )}
        {manhours && <FullWidthGrid>{manhours}</FullWidthGrid>}
        {machineHours && <FullWidthGrid>{machineHours}</FullWidthGrid>}
      </Grid>
    </DetailsPaneWrapper>
  );
};

export const TicketDetailsPane = skeletonify(
  'TicketDetailsPane',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

export const DetailsPaneWrapper: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  return (
    <Container maxWidth="xl" sx={{ mb: 20 }}>
      {children}
    </Container>
  );
};

// width: 100% is due to magical CSS reasons
export const FullWidthGrid: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Grid item xs style={{ width: '100%' }}>
      {children}
    </Grid>
  );
};

export const useApolloTicketDetailsQuery = (
  id?: string
): TypeChecked<GetTicketDetails_ticket_Ticket> | undefined => {
  const { data, loading } = useGMQuery<
    GetTicketDetails,
    GetTicketDetailsVariables
  >(TICKET_DETAILS_QUERY, {
    skip: !hasValue(id),
    variables: { input: { id: id ?? '' } },
    fetchPolicy: 'cache-and-network',
  });

  const checked = useTicketQueryTypeCheck<GetTicketDetails_ticket_Ticket>({
    data,
    loading,
  });

  const checkedIsData = checked.type === 'data';

  return checkedIsData ? checked : undefined;
};
