import { hasValue } from '@lego/mst-error-utilities';
import {
  format,
  formatDistanceToNow,
  formatRelative,
  parse,
  parseISO,
} from 'date-fns';
import { LocalTimestampInput } from '../migration/create-ticket/__generated__/CreateTicketDescriptionStepMutation.graphql';
import { localeToDateFnsLocale } from './i18n/i18n';
import { useTranslation } from './i18n/translation';

const DEFAULT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const CARD_DATE_FORMAT = 'do MMM u p';

export const getCurrentTimestampForMiddleware = (): string => {
  return format(Date.now(), "yyyy-MM-dd'T'HH:mm:ss");
};

export const getTimestampForRelay = (
  date?: number | null
): LocalTimestampInput => {
  const now = date ? date : Date.now();
  return {
    time: format(now, 'HH:mm:ss'),
    date: format(now, 'yyyy-MM-dd'),
  };
};

export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

export function useFormatDateWithLocale(
  dateFromMiddleware: string,
  dateFormat?: string
): string;

export function useFormatDateWithLocale(
  dateFromMiddleware: string | undefined | null,
  dateFormat?: string
): string | undefined;

export function useFormatDateWithLocale(
  dateFromMiddleware: string | undefined | null,
  dateFormat = DEFAULT_FORMAT
): string | undefined {
  const { locale: currentLocale } = useTranslation();
  if (!hasValue(dateFromMiddleware)) {
    return undefined;
  }

  return format(parseISO(dateFromMiddleware), dateFormat, {
    locale: localeToDateFnsLocale(currentLocale),
  });
}

export function useFormatRelativeWithLocale(date: number): string {
  const { locale: currentLocale } = useTranslation();

  return formatRelative(date, Date.now(), {
    locale: localeToDateFnsLocale(currentLocale),
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormatRelayLocalTimestampWithLocale = () => {
  const { locale: currentLocale } = useTranslation();

  const parseTimestamp = ({
    date,
    time,
    dateFormat = DEFAULT_FORMAT,
  }: {
    date: string;
    time: string;
    dateFormat?: string;
  }) => {
    const asDate = getDateFromRelayLocalTime({ date, time });
    const parsed = asDate.toISOString();

    return format(parseISO(parsed), dateFormat, {
      locale: localeToDateFnsLocale(currentLocale),
    });
  };

  const getDateFromRelayLocalTime = ({
    date,
    time,
  }: {
    date: string;
    time: string;
    dateFormat?: string;
  }) => {
    const timeWithoutMilliseconds =
      time.length === 12 ? time.substring(0, 8) : time;

    return parse(
      `${date} ${timeWithoutMilliseconds}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
  };

  return {
    parseTimestamp,
    getDateFromRelayLocalTime,
  };
};

export type UseDateFromMiddlewareWithLocaleFormat = {
  format: (dateFromMiddleware: string, dateFormat?: string) => string;
  formatDistanceToNow: (
    dateFromMiddleware: string,
    options: Omit<Parameters<typeof formatDistanceToNow>[1], 'options'>
  ) => string;
};

export const useDateFromMiddlewareWithLocale =
  (): UseDateFromMiddlewareWithLocaleFormat => {
    const { locale: currentLocale } = useTranslation();
    return {
      format: (dateFromMiddleware, dateFormat = DEFAULT_FORMAT) => {
        const locale = localeToDateFnsLocale(currentLocale);
        return format(parseISO(dateFromMiddleware), dateFormat, {
          locale,
        });
      },
      formatDistanceToNow: (dateFromMiddleware, options) => {
        const locale = localeToDateFnsLocale(currentLocale);
        return formatDistanceToNow(parseISO(dateFromMiddleware), {
          locale,
          ...options,
        });
      },
    };
  };

export const useFormatTime = (): {
  formatDuration: (minutesInDuration: number) => string;
} => {
  const { translate } = useTranslation();
  const formatDuration = (minutesInDuration: number) => {
    const hours = Math.floor(minutesInDuration / 60);
    const minutes = minutesInDuration % 60;

    return translate(
      'MISC.DURATION.DURATION_LABEL',
      '{{hours}}h {{minutes}}m',
      {
        hours: hours,
        minutes: minutes,
      }
    );
  };
  return { formatDuration };
};

export const formatDateForMiddleware = (date: Date | number): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const formatIntervalForMiddleware = (interval: Interval): string => {
  return `${formatDateForMiddleware(interval.start)}@${formatDateForMiddleware(
    interval.end
  )}`;
};
