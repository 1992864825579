/**
 * @generated SignedSource<<bf1e27b71072d3617133e07e042fb2eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpenAndRecentlyClosedTickets_equipment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OpenTicketsList_baseEquipment" | "RecentlyClosedTicketsList_equipment">;
  readonly " $fragmentType": "OpenAndRecentlyClosedTickets_equipment";
};
export type OpenAndRecentlyClosedTickets_equipment$key = {
  readonly " $data"?: OpenAndRecentlyClosedTickets_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenAndRecentlyClosedTickets_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "completedDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenAndRecentlyClosedTickets_equipment",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "completedDate",
              "variableName": "completedDate"
            }
          ],
          "kind": "FragmentSpread",
          "name": "RecentlyClosedTicketsList_equipment"
        }
      ]
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OpenTicketsList_baseEquipment"
        }
      ]
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "61ea27060bea678bfffe949206862747";

export default node;
