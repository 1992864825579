import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from '../../utility/i18n/translation';
import { SimpleDialog } from '../shared/SimpleDialog';

export const FlowHeaderWithDiscardDialog: FC<{
  title: string;
  dirty: boolean;
}> = ({ title, dirty }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const onConfirmExitPressed = () => {
    setOpen(false);
    navigate(-1);
  };

  const onCancelExitPressed = () => {
    setOpen(false);
  };

  const onCloseButtonClicked = () => {
    if (dirty) {
      setOpen(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <Grid container item justifyContent="space-between">
      <Grid item>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          onClick={onCloseButtonClicked}
          data-cy="FlowHeaderWithDiscardDialog-exit-button"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <SimpleDialog
        onDismiss={onCancelExitPressed}
        open={open}
        title={translate(
          'FLOW_HEADER.DIALOG.TITLE',
          'You have unsaved changes'
        )}
        content={{
          type: 'text',
          body: translate(
            'FLOW_HEADER.DIALOG.BODY',
            'By exiting this page you will lose your unsaved changes'
          ),
        }}
        primaryAction={{
          primaryActionLabel: translate(
            'FLOW_HEADER.DIALOG.PRIMARY_ACTION',
            'Go back to page'
          ),
          primaryActionPressed: onCancelExitPressed,
        }}
        secondaryAction={{
          secondaryActionLabel: translate(
            'FLOW_HEADER.DIALOG.SECONDARY_ACTION',
            'Exit without saving'
          ),
          secondaryActionPressed: onConfirmExitPressed,
        }}
      />
    </Grid>
  );
};
