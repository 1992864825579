import {
  CardActionArea,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactNode, useCallback, useState } from 'react';
import { useFragment } from 'react-relay';
import { GMImageWithFallback } from '../../../components/shared/GMImageComponents';
import { SparePartEANWithIcon } from '../../../components/ticket-card/components/SparePartEANWithIcon';
import { SparePartIdWithIcon } from '../../../components/ticket-card/components/SparePartIdWithIcon';
import { SparePartInStock } from '../../../components/ticket-card/components/SparePartInStock';
import { SparepartDetailsDialog } from '../../components/sparepart-details-dialog/SparepartDetailsDialog';
import { skeletonify } from '../../skeleton';
import { BomSparePartRow_sparePart$key } from './__generated__/BomSparePartRow_sparePart.graphql';

const ActualComponent: FC<{
  sparePart: BomSparePartRow_sparePart$key;
  hideRightItem?: boolean;
  disabled?: boolean;
}> = (props) => {
  const {
    sparePart: sparePartRef,
    hideRightItem = false,
    disabled = false,
  } = props;

  const data = useFragment(
    graphql`
      fragment BomSparePartRow_sparePart on SparePart
      @argumentDefinitions(processId: { type: "ID!" }) {
        id
        description
        model
        image {
          uri
        }
        sparePartNumber
        procurementInfo(input: { processId: $processId }) {
          inStock
        }
        ean
      }
    `,
    sparePartRef
  );

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const handleClick = useCallback(() => {
    setDetailsDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setDetailsDialogOpen(false);
  }, []);

  return (
    <>
      <SparepartDetailsDialog
        onCloseClicked={handleClose}
        open={detailsDialogOpen}
        sparePartId={data.id}
      />
      <CardComponent onClick={handleClick} disabled={disabled}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Grid item sx={{ mr: 1, mb: 1, mt: 1 }}>
                <GMImageWithFallback
                  url={data.image?.uri}
                  hoverEnabled={true}
                  style={{
                    objectFit: 'contain',
                    height: 110,
                    width: 110,
                  }}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  sx={{ height: '100%' }}
                  justifyContent="space-evenly"
                >
                  <Grid item>
                    <Grid container direction="row" spacing={3}>
                      <Grid item>
                        <SparePartIdWithIcon
                          sparePartId={data.sparePartNumber.toString() ?? '-'}
                          opaque={true}
                        />
                      </Grid>
                      <Grid item>
                        <SparePartEANWithIcon
                          EAN={data.ean?.toString() ?? '---'}
                          opaque={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>{data.description}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: 'text.secondary' }}>
                      {data.model}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {hideRightItem && <Grid item sx={{ mr: 3, mt: 1 }} />}
          {!hideRightItem && data?.procurementInfo?.inStock && (
            <Grid item sx={{ mr: 3, mt: 1 }}>
              <SparePartInStock />
            </Grid>
          )}
        </Grid>
      </CardComponent>
    </>
  );
};

const CardComponent: FC<{
  children: ReactNode;
  disabled: boolean;
  onClick?: () => void;
}> = ({ children, disabled, onClick }) => {
  if (disabled) {
    return <CardContent sx={{ pt: 2 }}>{children}</CardContent>;
  }
  return (
    <CardActionArea sx={{ pt: 2 }} onClick={onClick}>
      {children}
    </CardActionArea>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={100} />;
};

export const BomSparePartRow = skeletonify(
  'BomSparePart',
  ActualComponent,
  SkeletonComponent
);
