import { Grid } from '@mui/material';
import { FC, Suspense, useState } from 'react';
import { FillWidthLoading } from '../components/shared/FillWidthLoading';
import { useAreaAndProcessContext } from '../contexts/area';
import { CountAndSearch } from '../migration/components/CountAndSearch';
import { ProcessSelect } from '../migration/sublocation-list/ProcessSelect';
import { SublocationList } from '../migration/sublocation-list/SublocationList';

export const AdminContainer: FC = () => {
  const [searchString, setSearchString] = useState('');
  const [relayProcessId, setRelayProcessId] = useState(
    useAreaAndProcessContext().relayProcessId || ''
  );

  return (
    <Grid container direction="column" height="100%">
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        sx={{ mt: 2 }}
      >
        <Grid
          container
          item
          xs={7}
          md={6}
          direction="row"
          justifyContent="space-around"
        >
          <Grid item xs={4}>
            <ProcessSelect.Suspense
              value={relayProcessId}
              onProcessSelected={setRelayProcessId}
            />
          </Grid>
          <Grid item xs={8}>
            <CountAndSearch onChange={setSearchString} />
          </Grid>
        </Grid>
      </Grid>
      <Suspense fallback={<FillWidthLoading />}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          className="hide-scrollbar"
          sx={{ my: 2, overflow: 'auto', flex: 1 }}
        >
          <Grid item xs={7} md={6}>
            <SublocationList
              searchString={searchString}
              relayProcessId={relayProcessId}
            />
          </Grid>
        </Grid>
      </Suspense>
    </Grid>
  );
};
