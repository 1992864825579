import { hasValue } from '@lego/mst-error-utilities';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { usePaginationFragment } from 'react-relay';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { useTranslation } from '../../utility/i18n/translation';
import { skeletonify } from '../skeleton';
import { TicketList } from '../ticket-list/TicketList';
import { AssignedTickets_viewer$key } from './__generated__/AssignedTickets_viewer.graphql';

const Header: FC<{ totalCount?: number; refetch?: () => void }> = (props) => {
  const { refetch, totalCount } = props;
  const { translate } = useTranslation();
  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle1">
            {translate('ASSIGNED_TO_ME.HEADER', 'All tickets assigned to me') +
              (totalCount !== undefined ? ` (${totalCount})` : '')}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={!refetch}
            onClick={refetch}
            startIcon={<RefreshIcon />}
            variant="text"
          >
            {translate('ASSIGNED_TO_ME.HEADER_REFRESH', 'Refresh')}
          </Button>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

const ActualComponent: FC<{ viewer: AssignedTickets_viewer$key }> = (props) => {
  const { viewer: viewerRef } = props;
  const { data, refetch, hasNext, loadNext, isLoadingNext } =
    usePaginationFragment(
      graphql`
        fragment AssignedTickets_viewer on Viewer
        @refetchable(queryName: "AssignedTicketsRefetchQuery")
        @argumentDefinitions(
          first: { type: "Int", defaultValue: 10 }
          after: { type: "ID" }
        ) {
          assignedTickets(
            first: $first
            after: $after
            input: { status: InProgress }
          ) @connection(key: "AssignedTickets_viewer_assignedTickets") {
            count
            edges {
              node {
                ...TicketList_ticket
              }
            }
          }
        }
      `,
      viewerRef
    );

  const { assignedTickets } = data;

  const tickets = assignedTickets?.edges
    .filter(hasValue)
    .map(({ node }) => node);

  const handleRefetch = useCallback(
    () => refetch({}, { fetchPolicy: 'network-only' }),
    [refetch]
  );
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs>
        <Header totalCount={assignedTickets?.count} refetch={handleRefetch} />
      </Grid>
      <Grid item sx={{ mt: 1 }}>
        <TicketList
          tickets={tickets}
          hasNext={hasNext}
          isLoadingNext={isLoadingNext}
          loadNext={loadNext}
        />
      </Grid>
    </Grid>
  );
};

const SkeletonComponent: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid item xs alignSelf="center">
      <ActivityIndicator />
    </Grid>
  </Grid>
);

export const AssignedTickets = skeletonify(
  'AssignedTickets',
  ActualComponent,
  SkeletonComponent
);
