import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import {
  EquipmentSearchActions,
  equipmentSearchReducer,
} from './equipment-search-reducer';

export interface EquipmentSearchState {
  count: number;
  searchTerm: string | null;
}

export const initialEquipmentSearchState: EquipmentSearchState = {
  count: 0,
  searchTerm: null,
};

export interface EquipmentSearchContext {
  state: EquipmentSearchState;
  dispatch: Dispatch<EquipmentSearchActions>;
}

const Context = createContext<EquipmentSearchContext | undefined>(undefined);

export const useEquipmentSearchContext = (): EquipmentSearchContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing EquipmentSearchProvider');
  }

  return context;
};

export const EquipmentSearchProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    equipmentSearchReducer,
    initialEquipmentSearchState
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
