import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { PageErrorBoundary } from '../../PageErrorBoundary';
import { skeletonify } from '../../skeleton';
import { SparePartDetailsDialogPartInfo } from './SparePartDetailsDialogPartInfo';
import { SparePartDetailsDialogStockLevels } from './SparePartDetailsDialogStockLevels';
import SparePartDetailsDialogQueryContainerQuery, {
  SparePartDetailsDialogQueryContainerQuery as SparePartDetailsDialogQueryContainerQueryType,
} from './__generated__/SparePartDetailsDialogQueryContainerQuery.graphql';

const ActualComponent: FC<{
  query: PreloadedQuery<SparePartDetailsDialogQueryContainerQueryType>;
  sparePartId: string;
}> = (props) => {
  const { query: queryRef, sparePartId } = props;
  const query = usePreloadedQuery(
    graphql`
      query SparePartDetailsDialogQueryContainerQuery(
        $sparePartId: ID!
        $processId: ID!
      ) {
        sparePart: node(id: $sparePartId) {
          ... on SparePart {
            ...SparePartDetailsDialogPartInfo_sparePart
              @arguments(processId: $processId)
          }
        }
        ...SparePartDetailsDialogStockLevels_query
          @arguments(sparePartId: $sparePartId)
      }
    `,
    queryRef
  );

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item>
        <SparePartDetailsDialogPartInfo.Suspense sparePart={query.sparePart} />
      </Grid>
      <Grid item>
        <SparePartDetailsDialogStockLevels.Suspense
          query={query}
          sparePartId={sparePartId}
        />
      </Grid>
    </Grid>
  );
};

const SkeletonComponent: FC = () => <SparePartDetailsDialogPartInfo.Skeleton />;

const SparePartDetailsDialogQueryContainerPage = skeletonify(
  'SparePartDetailsDialogQueryContainerPage',
  ActualComponent,
  SkeletonComponent
);

export const SparePartDetailsDialogQueryContainer: FC<{
  sparePartId: string;
  processId: string;
}> = ({ sparePartId, processId }) => {
  const [queryRef, loadQuery] =
    useQueryLoader<SparePartDetailsDialogQueryContainerQueryType>(
      SparePartDetailsDialogQueryContainerQuery
    );

  const load = useCallback(() => {
    loadQuery({
      sparePartId,
      processId,
    });
  }, [loadQuery, processId, sparePartId]);

  useEffect(() => load(), [load]);

  return (
    <PageErrorBoundary onReset={() => load()}>
      {queryRef ? (
        <SparePartDetailsDialogQueryContainerPage.Suspense
          query={queryRef}
          sparePartId={sparePartId}
        />
      ) : (
        <SparePartDetailsDialogQueryContainerPage.Skeleton />
      )}
    </PageErrorBoundary>
  );
};
