import { Alert, Box, Grid, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { usePaginationFragment } from 'react-relay';
import { Waypoint } from 'react-waypoint';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { Icons } from '../../utility/icons';
import { BackToTop } from './BackToTop';

const EmptyView: FC<{
  label: string;
}> = ({ label }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Icons.NoTickets fillOpacity={0.6} style={{ fontSize: 120 }} />
      </Grid>
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
    </Grid>
  );
};

const ErrorView: FC<{
  label: string;
}> = ({ label }) => {
  return <Alert severity="error">{label}</Alert>;
};

type RelayPaginationProps = Pick<
  ReturnType<typeof usePaginationFragment>,
  'hasNext' | 'isLoadingNext' | 'loadNext'
>;
export type InfiniteListProps<T> = {
  items?: ReadonlyArray<T> | null;
  itemRender: (item: T, index: number) => JSX.Element;
  DividerComponent?: JSX.Element;
  itemKeyExtractor(item: T): string;
  errorLabel: string;
  emptyLabel: string;
  pageSize?: number;
  itemSpacing?: number;
  disableOverscrolling?: boolean;
} & RelayPaginationProps;

/**
 *
 * @returns A list wrapper that shows empty/error views for a list, as well as handle infinite scroll loading
 */
export const InfiniteList = <T,>(props: InfiniteListProps<T>): JSX.Element => {
  const {
    hasNext,
    isLoadingNext,
    loadNext,
    items,
    emptyLabel,
    errorLabel,
    itemKeyExtractor,
    itemRender,
    DividerComponent,
    pageSize = 10,
    disableOverscrolling = false,
    itemSpacing = 2,
  } = props;
  const loadNextPage = useCallback(() => {
    loadNext(pageSize);
  }, [loadNext, pageSize]);

  if (!items) {
    return <ErrorView label={errorLabel} />;
  }

  if (items.length === 0) {
    return <EmptyView label={emptyLabel} />;
  }

  return (
    <Grid container direction="column" spacing={itemSpacing} flexWrap="nowrap">
      {items.map((item, index) => (
        <Grid item key={itemKeyExtractor(item)}>
          {index > 0 && DividerComponent}
          {itemRender(item, index)}
        </Grid>
      ))}
      {hasNext && !isLoadingNext && <Waypoint onEnter={loadNextPage} />}
      {isLoadingNext && (
        <Grid item xs alignSelf="center">
          <ActivityIndicator />
        </Grid>
      )}
      {disableOverscrolling ? null : (
        <Grid item>
          <Box height={80} />
        </Grid>
      )}
      <BackToTop />
    </Grid>
  );
};
