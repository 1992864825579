import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Grid, MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import {
  useGMLazyQuery,
  useGMMutation,
  useGMQuery,
} from '../../apollo/customApolloHooks';
import { useAreaAndProcessContext } from '../../contexts/area';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { CardWithTitle } from '../shared/CardWithTitle';
import { FillWidthLoading } from '../shared/FillWidthLoading';
import { GMDropdown, GMDropdownProps } from '../shared/GMDropdown';
import { Area, AREA_FRAGMENT } from './Area';
import { Process, PROCESS_FRAGMENT } from './Process';
import { AreaQuery } from './__apollo__/AreaQuery';
import { MyCurrentProcess } from './__apollo__/MyCurrentProcess';
import { ProcessQuery, ProcessQueryVariables } from './__apollo__/ProcessQuery';
import {
  SelectProcess,
  SelectProcessVariables,
} from './__apollo__/SelectProcess';

export const AREA_SELECTOR_QUERY = gql`
  query AreaQuery {
    allAreas {
      id
      ...AreaFragment
    }
  }
  ${AREA_FRAGMENT}
`;

export const PROCESS_SELECTOR_QUERY = gql`
  query ProcessQuery($input: AllProcessesByAreaIdInput!) {
    allProcesses(input: $input) {
      id
      ...ProcessFragment
    }
  }
  ${PROCESS_FRAGMENT}
`;

const CURRENT_PROCESS_FRAGMENT = gql`
  fragment SelectedProcess on Profile {
    id
    selectedProcess(skipPlantCheck: true) {
      process {
        id
        area {
          id
          ...AreaFragment
        }
      }
    }
  }
  ${AREA_FRAGMENT}
`;

const CURRENT_PROCESS_QUERY = gql`
  query MyCurrentProcess {
    me {
      id
      ...SelectedProcess
    }
  }
  ${CURRENT_PROCESS_FRAGMENT}
`;

const SELECT_PROCESS_MUTATION = gql`
  mutation SelectProcess($input: SelectProcessInput!) {
    selectProcess(input: $input) {
      id
    }
  }
`;

export const AreaAndProcess: FC = () => {
  const { translate } = useTranslation();
  const title = translate('PROFILE.AREA_AND_PROCESS.TITLE', 'Area settings');
  const { showSnack } = useGMSnackbar();
  const { setSelectedProcessId, setSelectedArea: setContextArea } =
    useAreaAndProcessContext();
  const [selectedArea, setSelectedArea] = useState<string>('');
  const { isRepairman } = useAuthContext();
  const [selectedProcess, setSelectedProcess] = useState<string>('');

  const [
    getProcesses,
    { data: processes, loading: processesLoading, error: processesError },
  ] = useGMLazyQuery<ProcessQuery, ProcessQueryVariables>(
    PROCESS_SELECTOR_QUERY
  );

  const snackMessage = translate(
    'PROFILE.AREA_AND_PROCESS.SUCCESS',
    'Successfully updated process'
  );

  const {
    data: areas,
    loading: areasLoading,
    error: areaError,
  } = useGMQuery<AreaQuery>(AREA_SELECTOR_QUERY);

  const [selectProcess, { loading: selectingProcess }] = useGMMutation<
    SelectProcess,
    SelectProcessVariables
  >(SELECT_PROCESS_MUTATION, {
    onCompleted: () => {
      showSnack({
        message: snackMessage,
        showDismissButton: true,
        variant: 'success',
      });
      if (selectedProcess.length > 0) {
        setSelectedProcessId(selectedProcess);
      }

      const area = areas?.allAreas.find((x) => x.id === selectedArea);
      if (area) {
        setContextArea({ id: area.id, plantId: area.plant.id });
      }
    },
  });

  const { loading: currentProcessLoading } = useGMQuery<MyCurrentProcess>(
    CURRENT_PROCESS_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const areaId = data?.me.selectedProcess.process?.area.id;
        const processId = data?.me.selectedProcess.process?.id;

        setSelectedArea(areaId ?? '');
        setSelectedProcess(processId ?? '');
        if (areaId) {
          getProcesses({ variables: { input: { areaId } } });
        }
        if (hasValue(processId) && processId.length > 0) {
          setSelectedProcessId(processId);
        }
      },
    }
  );

  const onAreaChosen = (areaId: string) => {
    if (areaId !== selectedArea) {
      setSelectedArea(areaId);
      setSelectedProcess('');
    }
    getProcesses({ variables: { input: { areaId } } });
  };

  const onProcessChosen = (processId: string) => {
    setSelectedProcess(processId);
    selectProcess({ variables: { input: { processId } } });
  };

  if (areasLoading || currentProcessLoading) {
    return (
      <CardWithTitle title={title} halfSize={true}>
        <FillWidthLoading />
      </CardWithTitle>
    );
  }

  if (!areas || hasValue(areaError) || hasValue(processesError)) {
    return (
      <CardWithTitle title={title} halfSize={true}>
        <Typography>
          {translate(
            'PROFILE.AREA_AND_PROCESS.ERROR',
            'Could not fetch areas and processes, please refresh and try again'
          )}
        </Typography>
      </CardWithTitle>
    );
  }

  return (
    <CardWithTitle title={title} halfSize={true}>
      <Grid container direction="column" spacing={3}>
        <Grid xs item>
          <Area
            loading={areasLoading}
            areas={areas.allAreas}
            onAreaChosen={onAreaChosen}
            currentlySelectedAreaId={selectedArea}
          />
        </Grid>
        {processesLoading && <FillWidthLoading />}
        {hasValue(selectedArea) && isRepairman && processes && (
          <Grid xs item>
            <Process
              loading={processesLoading || selectingProcess}
              processes={processes.allProcesses}
              onProcessChosen={onProcessChosen}
              currentlySelectedProcessId={selectedProcess}
            />
          </Grid>
        )}
      </Grid>
    </CardWithTitle>
  );
};

export type AreaAndProcessListItem = { id: string; name: string };

export const AreaOrProcess: FC<{
  items: AreaAndProcessListItem[];
  title: string;
  helperText: string;
  currentlySelectedId: string;
  loading: boolean;
  onChange: Pick<GMDropdownProps, 'onChange'>['onChange'];
  showHelperText: boolean;
  disabled?: boolean;
  showPlaceholder?: boolean;
}> = ({
  title,
  helperText,
  items,
  onChange,
  currentlySelectedId,
  loading,
  showHelperText = true,
  disabled = false,
  showPlaceholder = true,
}) => {
  const { translate } = useTranslation();
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item>
        <GMDropdown
          onChange={onChange}
          value={currentlySelectedId}
          disabled={loading || disabled}
          placeholderText={
            showPlaceholder
              ? translate(
                  'PROFILE.AREA_AND_PROCESS.PLACEHOLDER_TEXT',
                  'Choose an option...'
                )
              : undefined
          }
        >
          {items.map((val) => {
            return (
              <MenuItem key={val.id} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
        </GMDropdown>
      </Grid>
      {showHelperText && (
        <Grid item style={{ marginTop: 24 }}>
          <Typography variant="body2">{helperText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
