/**
 * @generated SignedSource<<af0678164e0f10214ee4251b472124a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AllowedTicketPriority = "GAT" | "LightCleaning" | "OrderEndCleaning" | "OrderEndRepair" | "PlannedCleaning" | "PlannedRepair" | "Priority1" | "Priority2" | "Priority3" | "Priority5" | "Wornout" | "%future added value";
export type MutationCreateTicketInput = {
  assignedTo?: string | null;
  createdDate: LocalTimestampInput;
  description: string;
  equipmentId: string;
  isChangeOverIssue?: boolean | null;
  isRecurring: boolean;
  priority: AllowedTicketPriority;
  requiredEndDate?: LocalTimestampInput | null;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type CreateTicketDescriptionStepMutation$variables = {
  input: MutationCreateTicketInput;
};
export type CreateTicketDescriptionStepMutation$data = {
  readonly createTicket: {
    readonly data?: {
      readonly id: string;
      readonly ticketNumber: number | null;
    };
  };
};
export type CreateTicketDescriptionStepMutation = {
  response: CreateTicketDescriptionStepMutation$data;
  variables: CreateTicketDescriptionStepMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTicketDescriptionStepMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createTicket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCreateTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTicketDescriptionStepMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createTicket",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCreateTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac42869d1c31a4d7ed1a05c14da5592d",
    "id": null,
    "metadata": {},
    "name": "CreateTicketDescriptionStepMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTicketDescriptionStepMutation(\n  $input: MutationCreateTicketInput!\n) {\n  createTicket(input: $input) {\n    __typename\n    ... on MutationCreateTicketSuccess {\n      data {\n        __typename\n        id\n        ticketNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d925906ac23c93f611b802c76589abf2";

export default node;
