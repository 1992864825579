import { hasValue } from '@lego/mst-error-utilities';
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  useTheme,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { GMDropdown } from '../../components/shared/GMDropdown';
import { skeletonify } from '../skeleton';
import { ProcessSelectQuery } from './__generated__/ProcessSelectQuery.graphql';

const ActualComponent: FC<{
  value: string;
  onProcessSelected: (newProcessId: string) => void;
}> = ({ value, onProcessSelected }) => {
  const { palette } = useTheme();
  const AVAILABLE_PROCESS_TYPES = ['Packing', 'Processing'];
  const data = useLazyLoadQuery<ProcessSelectQuery>(
    graphql`
      query ProcessSelectQuery {
        plants {
          processes {
            id
            name
            type
            plant {
              plantNumber
            }
          }
        }
      }
    `,
    {}
  );

  const processes =
    data.plants
      ?.flatMap((plant) => plant.processes)
      .filter(
        (process) =>
          process?.type && AVAILABLE_PROCESS_TYPES.includes(process.type)
      )
      .filter(hasValue) || [];

  const handleChange = (event: SelectChangeEvent<string>) => {
    onProcessSelected(event.target.value);
  };

  return (
    <Box sx={{ backgroundColor: palette.background.paper }}>
      <GMDropdown onChange={handleChange} value={value}>
        {processes?.map((process) => (
          <MenuItem key={process.id} value={process.id}>
            {process.name} ({process.plant?.plantNumber})
          </MenuItem>
        ))}
      </GMDropdown>
    </Box>
  );
};

const SkeletonComponent = () => <Skeleton width="100%" height="100%" />;

export const ProcessSelect = skeletonify(
  'ProcessSelect',
  ActualComponent,
  SkeletonComponent
);
