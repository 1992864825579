import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAreaAndProcessContext } from '.';
import {
  useGMLazyQuery,
  useGMMutation,
  useGMQuery,
} from '../../apollo/customApolloHooks';
import { useAuthContext } from '../AuthContext';
import {
  ChangeProcess,
  ChangeProcessVariables,
} from './__apollo__/ChangeProcess';
import { GetAreas, GetAreas_allAreas } from './__apollo__/GetAreas';
import {
  GetProcesses,
  GetProcessesVariables,
  GetProcesses_allProcesses,
} from './__apollo__/GetProcesses';

const GET_AREAS = gql`
  query GetAreas {
    allAreas {
      id
      name
      plant {
        id
      }
    }
  }
`;

const SELECTED_PROCESS_FRAGMENT = gql`
  fragment SelectedProcessFragment on Profile {
    id
    selectedProcess(skipPlantCheck: true) {
      mustSelectProcess
      process {
        id
        area {
          id
          plant {
            id
          }
        }
      }
    }
  }
`;

const GET_PROCESSES = gql`
  query GetProcesses($input: AllProcessesByAreaIdInput!) {
    me {
      id
      ...SelectedProcessFragment
    }
    allProcesses(input: $input) {
      id
      name
    }
  }
  ${SELECTED_PROCESS_FRAGMENT}
`;

const CHANGE_PROCESS_MUTATION = gql`
  mutation ChangeProcess($input: SelectProcessInput!) {
    selectProcess(input: $input) {
      id
      ...SelectedProcessFragment
    }
  }
  ${SELECTED_PROCESS_FRAGMENT}
`;

export type SelectProcessData = GetProcesses & {
  changeProcess: (process: GetProcesses_allProcesses) => void;
};

export type Request<D> =
  | { state: 'not-started' }
  | { state: 'loading' }
  | { state: 'error'; error: Error | undefined }
  | { state: 'success'; data: D };

export const useAreaAndProcessQueries = (
  areaId: string,
  shouldLoadProcesses: boolean
): [Request<GetAreas_allAreas[]>, Request<SelectProcessData>] => {
  const { authenticated } = useAuthContext();
  const { setSelectedProcessId } = useAreaAndProcessContext();
  const [areaRequest, setAreaRequest] = useState<Request<GetAreas_allAreas[]>>({
    state: 'not-started',
  });
  const [processRequest, setProcessRequest] = useState<
    Request<SelectProcessData>
  >({
    state: 'not-started',
  });

  useGMQuery<GetAreas>(GET_AREAS, {
    onError: (error) => setAreaRequest({ state: 'error', error }),
    onCompleted: (data) =>
      setAreaRequest({ state: 'success', data: data.allAreas }),
  });

  const [changeProcess] = useGMMutation<ChangeProcess, ChangeProcessVariables>(
    CHANGE_PROCESS_MUTATION,
    {
      onCompleted: (data) =>
        setProcessRequest((current) => {
          if (current.state === 'success') {
            return {
              state: 'success',
              data: {
                ...current.data,
                me: data.selectProcess,
              },
            };
          } else {
            return current;
          }
        }),
    }
  );

  const [getProcesses] = useGMLazyQuery<GetProcesses, GetProcessesVariables>(
    GET_PROCESSES,
    {
      onError: (error) => setProcessRequest({ state: 'error', error }),
      onCompleted: (data) =>
        setProcessRequest({
          state: 'success',
          data: {
            ...data,
            changeProcess: (process) => {
              setSelectedProcessId(process.id);
              changeProcess({
                variables: { input: { processId: process.id } },
              });
            },
          },
        }),
    }
  );

  useEffect(() => {
    if (authenticated && shouldLoadProcesses) {
      setProcessRequest({ state: 'loading' });
      getProcesses({ variables: { input: { areaId } } });
    }
  }, [areaId, authenticated, getProcesses, shouldLoadProcesses]);

  return [areaRequest, processRequest];
};
