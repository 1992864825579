import { Container } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { skeletonify } from '../skeleton';
import { AssignedTickets } from './AssignedTickets';
import { DashboardHeader } from './DashboardHeader';
import DashboardQuery, {
  DashboardQuery as DashboardQueryType,
} from './__generated__/DashboardQuery.graphql';

const ActualComponent: FC<{ query: PreloadedQuery<DashboardQueryType> }> = (
  props
) => {
  const { query: queryRef } = props;
  const { viewer } = usePreloadedQuery(
    graphql`
      query DashboardQuery {
        viewer {
          ...AssignedTickets_viewer @defer(label: "tickets")
          ...DashboardHeader_viewer
        }
      }
    `,
    queryRef
  );

  return (
    <>
      <DashboardHeader viewer={viewer} />
      <AssignedTickets.Suspense viewer={viewer} />
    </>
  );
};
const SkeletonComponent: FC = () => (
  <>
    <DashboardHeader.Skeleton />
    <AssignedTickets.Skeleton />
  </>
);

const DashboardPage = skeletonify(
  'DashboardPage',
  ActualComponent,
  SkeletonComponent
);

export const Dashboard: FC = () => {
  const [queryRef, loadQuery] =
    useQueryLoader<DashboardQueryType>(DashboardQuery);

  useEffect(
    () => loadQuery({}, { fetchPolicy: 'store-and-network' }),
    [loadQuery]
  );

  return (
    <Container maxWidth="lg" style={{ marginTop: 24 }}>
      <PageErrorBoundary onReset={() => loadQuery({})}>
        {queryRef ? (
          <DashboardPage.Suspense query={queryRef} />
        ) : (
          <DashboardPage.Skeleton />
        )}
      </PageErrorBoundary>
    </Container>
  );
};
