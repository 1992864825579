import { hasValue } from '@lego/mst-error-utilities';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { useTicketListSearchContext } from '../../contexts/ticket-list-search/ticket-list-search-context';
import { TicketFilterFragment } from '../../contexts/ticket-list-search/__apollo__/TicketFilterFragment';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketPriorityEnum, TicketStatus } from '../../__apollo__/types';

export const getPriorityFromString = (priority: string): TicketPriorityEnum => {
  switch (priority) {
    case '1':
      return TicketPriorityEnum.Priority1;
    case '2':
      return TicketPriorityEnum.Priority2;
    case '3':
      return TicketPriorityEnum.Priority3;
    case '4':
      return TicketPriorityEnum.Priority4;
    case '5':
      return TicketPriorityEnum.Priority5;
    case '6':
      return TicketPriorityEnum.Priority6;
    case '7':
      return TicketPriorityEnum.Priority7;
    case '8':
      return TicketPriorityEnum.Priority8;

    default:
      throw new Error('Unhandled priority');
  }
};

export const mapStringPriorityToTicketPriorityEnum = (
  priorities: string[]
): TicketPriorityEnum[] => {
  const res = priorities.map(getPriorityFromString);
  if (priorities.includes('3')) {
    return [...res, TicketPriorityEnum.MouldCall];
  }
  return res;
};

export const ALL_PRIORITIES = [
  TicketPriorityEnum.MouldCall,
  TicketPriorityEnum.Priority1,
  TicketPriorityEnum.Priority2,
  TicketPriorityEnum.Priority3,
  TicketPriorityEnum.Priority4,
  TicketPriorityEnum.Priority5,
  TicketPriorityEnum.Priority6,
  TicketPriorityEnum.Priority7,
  TicketPriorityEnum.Priority8,
];

export const ALL_TICKET_STATUSES = [
  TicketStatus.InProgress,
  TicketStatus.NotStarted,
  TicketStatus.Released,
];

const ALL_ALLOWED_PRIORITY_FILTERS = ['1', '2', '3', '4', '5', '6', '7', '8'];

type TicketListSearchPrioritiesProps = {
  handlePriorityChanged: (priority: string) => void;
  checkedPriorities: string[];
  priorityCounts?: TicketFilterFragment[];
};

export const TicketListPriorities: FC<TicketListSearchPrioritiesProps> = ({
  handlePriorityChanged,
  checkedPriorities,
  priorityCounts,
}) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('TICKET_LIST.FILTERS.PRIORITY.TITLE', 'Priority')}
        </Typography>
      </Grid>
      <FormGroup sx={{ ml: 3 }}>
        {ALL_ALLOWED_PRIORITY_FILTERS.map((val) => {
          const handleChange = () => {
            handlePriorityChanged(val);
          };

          const checked = checkedPriorities.includes(val);
          const filterCount = priorityCounts?.find((x) => x.type === val);

          return (
            <Grid
              container
              key={val}
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={<Checkbox onChange={handleChange} />}
                label={val}
                checked={checked}
              />
              {hasValue(priorityCounts) && (
                <Typography style={{ color: palette.text.disabled }}>
                  {filterCount?.count ?? 0}
                </Typography>
              )}
            </Grid>
          );
        })}
      </FormGroup>
    </Grid>
  );
};

export const TicketListSearchPriorities: FC = () => {
  const {
    dispatch,
    state: {
      priorities,
      filterCounts: { priority },
    },
  } = useTicketListSearchContext();

  const handleChange = (priority: string) => {
    dispatch({ type: 'togglePriority', priority });
  };

  return (
    <TicketListPriorities
      checkedPriorities={priorities}
      handlePriorityChanged={handleChange}
      priorityCounts={priority}
    />
  );
};
