import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { skeletonify } from '../skeleton';
import {
  EquipmentDocumentsGroupSearch_equipment$data,
  EquipmentDocumentsGroupSearch_equipment$key,
} from './__generated__/EquipmentDocumentsGroupSearch_equipment.graphql';

export const equipmentDocumentsGroupSearchNoGroupId = 'no_group';

export type EquipmentDocumentsGroupSearchSelectType =
  EquipmentDocumentsGroupSearch_equipment$data['documentGroups'][0];

const ActualComponent: FC<{
  equipment?: EquipmentDocumentsGroupSearch_equipment$key | null;
  onGroupSelected: (newGroup: string | null) => void;
}> = (props) => {
  const [value, setValue] = useState<string | null>(null);
  const { translate } = useTranslation();
  const { equipment: equipmentRef, onGroupSelected } = props;
  const data = useFragment(
    graphql`
      fragment EquipmentDocumentsGroupSearch_equipment on Equipment {
        documentGroups {
          id
          description
        }
      }
    `,
    equipmentRef ?? null
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      setValue(event.target.value);
      onGroupSelected(event.target.value);
    },
    [onGroupSelected]
  );

  const groupLabel = translate(
    'EQUIPMENT_DOCUMENT_LIST.DROPDOWN_LABEL',
    'Choose group'
  );
  const noGroupString = translate(
    'EQUIPMENT_DOCUMENT_LIST.SEARCH_NO_GROUP_CHOSEN',
    'No group filter'
  );

  const options = useMemo(() => {
    const noGroupOption = {
      id: equipmentDocumentsGroupSearchNoGroupId,
      value: '',
      description: noGroupString,
    };
    if (data?.documentGroups && (data.documentGroups.length ?? 0) > 0) {
      return [noGroupOption, ...data.documentGroups];
    }
    return [noGroupOption];
  }, [data?.documentGroups, noGroupString]);

  return (
    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel>{groupLabel}</InputLabel>
      <Select value={value ?? ''} label={groupLabel} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="rectangular" width={'100%'} height={52} />;
};

export const EquipmentDocumentsGroupSearch = skeletonify(
  'EquipmentDocumentsGroupSearch',
  ActualComponent,
  SkeletonComponent
);
