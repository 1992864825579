import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { FC, ReactElement, ReactNode, useMemo } from 'react';

const MAX_LENGTH_OF_WORD_IN_TITLE = 16;
export const CardWithTitle: FC<{
  title: string;
  halfSize?: boolean;
  rightItem?: ReactElement;
  children: ReactNode;
}> = ({ title, halfSize = false, children, rightItem }) => {
  const lengthOfLongestWordInTitle = useMemo(() => {
    const wordsInTitle = title.split(' ');

    return wordsInTitle.reduce((prev, curr) => {
      return curr.length > prev ? curr.length : prev;
    }, 0);
  }, [title]);

  return (
    <Card
      data-cy={`CardWithTitle-${title.replace(' ', '-')}`}
      style={{ height: '100%' }}
    >
      <Grid container direction="row" style={{ padding: 20 }}>
        <Grid
          item
          xs={12}
          lg={halfSize ? 4 : 2}
          style={{
            textOverflow: 'ellipsis',
            marginBottom: 8,
            marginTop: 4,
          }}
        >
          <Typography
            variant="subtitle1"
            noWrap={lengthOfLongestWordInTitle > MAX_LENGTH_OF_WORD_IN_TITLE}
          >
            {title}
          </Typography>
        </Grid>
        <Grid xs={12} lg item>
          {children}
        </Grid>
        {rightItem && (
          <Grid xs={12} lg={2} item container justifyContent="flex-end">
            <Grid item>{rightItem}</Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export const CardWithTitleSkeleton: FC<{
  halfSize?: boolean;
  showTopRightButton?: boolean;
  children: ReactNode;
}> = ({ halfSize = false, children, showTopRightButton = false }) => {
  return (
    <Card style={{ height: '100%' }}>
      <Grid container direction="row" style={{ padding: 20 }}>
        <Grid
          item
          xs={12}
          lg={halfSize ? 4 : 2}
          style={{
            textOverflow: 'ellipsis',
            marginBottom: 8,
            marginTop: 4,
          }}
        >
          <Skeleton variant="text" width={150} />
        </Grid>
        <Grid xs={12} lg item>
          {children}
        </Grid>
        {showTopRightButton && (
          <Grid xs={12} lg={2} item container justifyContent="flex-end">
            <Skeleton variant="text" width={150} height={40} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
