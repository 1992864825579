import { hasValue } from '@lego/mst-error-utilities';
import {
  HallMonitorPriorities,
  HallMonitorTicketsStatuses,
} from './HallMonitorCreateDialog';
import { HallMonitorCreateDialogMutation$variables } from './__generated__/HallMonitorCreateDialogMutation.graphql';

export type HallMonitorCreateSection = {
  priority: ReadonlyArray<HallMonitorPriorities>;
  status?: ReadonlyArray<HallMonitorTicketsStatuses>;
  codings?: string[];
  title: string;
};

export interface HallMonitorCreateState {
  title?: string;
  processId?: string;
  sections: HallMonitorCreateSection[];
}

export const initialHallMonitorState: HallMonitorCreateState = {
  sections: [],
};

export type HallMonitorActions =
  | { type: 'setProcessId'; id: string }
  | { type: 'setTitle'; title: string }
  | { type: 'clearState' }
  | { type: 'addSection' }
  | { type: 'removeSection'; index: number }
  | { type: 'editSectionTitle'; index: number; title: string }
  | {
      type: 'editSectionPriorities';
      index: number;
      priorities: HallMonitorPriorities[];
    }
  | {
      type: 'editSectionTicketStatuses';
      index: number;
      statuses: HallMonitorTicketsStatuses[];
    }
  | {
      type: 'editSectionCoding';
      index: number;
      coding: string[];
    }
  | { type: 'setShowErrorOnManHourStep'; show: string };

export const hallMonitorReducer = (
  state: HallMonitorCreateState,
  action: HallMonitorActions
): HallMonitorCreateState => {
  switch (action.type) {
    case 'setProcessId':
      return { ...initialHallMonitorState, processId: action.id };
    case 'clearState':
      return initialHallMonitorState;
    case 'setTitle':
      return { ...state, title: action.title };
    case 'addSection':
      return {
        ...state,
        sections: [...state.sections, { title: '', priority: [] }],
      };
    case 'removeSection':
      // eslint-disable-next-line no-case-declarations
      const copy = [...state.sections];
      copy.splice(action.index, 1);
      return { ...state, sections: copy };
    case 'editSectionTitle':
      return {
        ...state,
        sections: updateKeyForSection({
          key: 'title',
          index: action.index,
          value: action.title,
          sections: state.sections,
        }),
      };
    case 'editSectionPriorities':
      return {
        ...state,
        sections: updateKeyForSection({
          key: 'priority',
          index: action.index,
          value: action.priorities,
          sections: state.sections,
        }),
      };
    case 'editSectionTicketStatuses':
      return {
        ...state,
        sections: updateKeyForSection({
          key: 'status',
          index: action.index,
          value: action.statuses,
          sections: state.sections,
        }),
      };
    case 'editSectionCoding':
      return {
        ...state,
        sections: updateKeyForSection({
          key: 'codings',
          index: action.index,
          value: action.coding,
          sections: state.sections,
        }),
      };
    case 'setShowErrorOnManHourStep':
      return { ...state, title: action.show };
  }
};

const updateKeyForSection = <
  K extends keyof HallMonitorCreateSection,
  V extends HallMonitorCreateSection[K]
>({
  index,
  key,
  sections,
  value,
}: {
  key: K;
  sections: HallMonitorCreateSection[];
  index: number;
  value: V;
}): HallMonitorCreateSection[] => {
  const sectionCopy: HallMonitorCreateSection = {
    ...sections[index],
  };
  sectionCopy[key] = value;
  const arrCopy = [...sections];
  arrCopy[index] = sectionCopy;
  return arrCopy;
};

export const hallMonitorMapStateToMutationInput = ({
  sections,
  processId,
  title,
}: HallMonitorCreateState):
  | HallMonitorCreateDialogMutation$variables
  | undefined => {
  const validInput = hasValue(processId) && hasValue(title) && title.length > 0;

  if (!validInput) {
    return undefined;
  }

  return {
    createHallMonitorVariantInput: {
      processId,
      title,
      sections,
    },
  };
};
