import { gql } from '@apollo/client';
import { Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { CardWithTitle } from '../shared/CardWithTitle';
import { CollapsibleWidget } from '../shared/CollapsibleWidget';
import { TextWithLabel } from '../shared/TextWithLabel';
import { MouldDimmingDetails } from './__apollo__/MouldDimmingDetails';

export const TD_MOULD_DIMMING_DETAILS = gql`
  fragment MouldDimmingDetails on Mould {
    id
    dimmingDetails {
      id
      cavityNumber
      permanent
      addedAt
      addedBy
      reason
      expectedRemovalDescription
    }
  }
  fragment TicketDetailsMouldDimmingDetails on Ticket {
    id
    equipment {
      ... on EquipmentValue {
        value {
          id
          ... on Mould {
            ...MouldDimmingDetails
          }
        }
      }
    }
  }
`;

export const TDDimmingDetails: FC<MouldDimmingDetails> = (props) => {
  const { translate } = useTranslation();
  const cardTitle = translate(
    'TICKET_DETAILS.CARDS.DIMMINGS.TITLE',
    'Dimmings'
  );

  return (
    <CardWithTitle title={cardTitle}>
      <TDDimmingsContent {...props} />
    </CardWithTitle>
  );
};

export const TDDimmingsContent: FC<MouldDimmingDetails> = ({
  dimmingDetails,
}) => {
  const { translate } = useTranslation();

  if (dimmingDetails.length === 0) {
    return (
      <Typography>
        {translate('TICKET_DETAILS.CARDS.DIMMINGS.NO_DIMMINGS', 'No dimmings')}
      </Typography>
    );
  }

  const translations = {
    cavityNr: translate(
      'TICKET_DETAILS.CARDS.DIMMINGS.CAVITY_NO',
      'Cavity no.'
    ),
    reason: translate('TICKET_DETAILS.CARDS.DIMMINGS.REASON', 'Reason'),
    removalDate: translate(
      'TICKET_DETAILS.CARDS.DIMMINGS.REMOVAL_DATE',
      'Expected removal date'
    ),
    addedBy: translate('TICKET_DETAILS.CARDS.DIMMINGS.ADDED_BY', 'Added by'),
    addedAt: translate(
      'TICKET_DETAILS.CARDS.DIMMINGS.ADDED_DATE',
      'Added date'
    ),
    type: translate('TICKET_DETAILS.CARDS.DIMMINGS.TYPE', 'Type'),
    temporaryTranslated: translate(
      'TICKET_DETAILS.CARDS.DIMMINGS.TEMPORARY',
      'Temporary'
    ),
    permanentTranslated: translate(
      'TICKET_DETAILS.CARDS.DIMMINGS.PERMANENT',
      'Permanent'
    ),
  };

  return (
    <Grid>
      {dimmingDetails.map((dimming, index) => {
        const {
          id,
          cavityNumber,
          expectedRemovalDescription,
          permanent,
          addedAt,
          addedBy,
          reason,
        } = dimming;

        return (
          <Grid container key={id} direction="column">
            <CollapsibleWidget
              componentWhenClosed={
                <Grid
                  container
                  direction="row"
                  style={{ flex: 1 }}
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography>#{cavityNumber}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{reason}</Typography>
                  </Grid>
                </Grid>
              }
            >
              <Grid
                container
                spacing={2}
                style={{ paddingRight: 48, marginBottom: 10 }}
              >
                <DimmingRow
                  leftItem={{
                    label: translations.removalDate,
                    text: expectedRemovalDescription,
                  }}
                  rightItem={{ label: translations.addedBy, text: addedBy }}
                />
                <DimmingRow
                  leftItem={{
                    label: translations.type,
                    text: permanent
                      ? translations.permanentTranslated
                      : translations.temporaryTranslated,
                  }}
                  rightItem={{ label: translations.addedAt, text: addedAt }}
                />
              </Grid>
            </CollapsibleWidget>

            {/* Do not show divider after last item in list */}
            {index < dimmingDetails.length - 1 && <Divider />}
          </Grid>
        );
      })}
    </Grid>
  );
};

type RowElement = {
  text?: string | null;
  label: string;
};

const DimmingRow: FC<{ leftItem: RowElement; rightItem: RowElement }> = ({
  leftItem,
  rightItem,
}) => {
  return (
    <Grid container item justifyContent="space-between">
      <Grid item>
        <TextWithLabel label={leftItem.label} text={leftItem.text ?? ''} />
      </Grid>
      <Grid item>
        <TextWithLabel
          label={rightItem.label}
          text={rightItem.text ?? ''}
          alignTextRight={true}
        />
      </Grid>
    </Grid>
  );
};
