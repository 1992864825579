import { hasValue } from '@lego/mst-error-utilities';
import { LoadingButton } from '@mui/lab';
import { TableCell, TableRow, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useTransition } from 'react';
import { useMutation, useRefetchableFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { AppRoutes } from '../../Router';
import { getTimestampForRelay } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { PlannedCleaningJobRowMutation } from './__generated__/PlannedCleaningJobRowMutation.graphql';
import { PlannedCleaningJobRow_mould$key } from './__generated__/PlannedCleaningJobRow_mould.graphql';

export const PlannedCleaningJobRow: FC<{
  mould: PlannedCleaningJobRow_mould$key;
}> = ({ mould: mouldRef }) => {
  const { translate, locale } = useTranslation();
  const [mould, refetch] = useRefetchableFragment(
    graphql`
      fragment PlannedCleaningJobRow_mould on Mould
      @refetchable(queryName: "PlannedCleaningJobRowRefetchQuery") {
        id
        equipmentNumber
        description
        statusCode
        location {
          description
        }
        weight {
          value
          unitOfMeasurement
        }
        type
        boxConcept
        mouldMachine {
          equipmentNumber
        }
        actualShots
        shotsLastUpdatedDate {
          date
        }
        cleaningInterval
        elementsPerShot
        lastCleaningShots
        shotsSinceLastCleaning
        dimmings {
          count
        }
        currentCleaningTicket {
          ticketNumber
        }
      }
    `,
    mouldRef
  );

  const [commit, isInFlight] =
    useMutation<PlannedCleaningJobRowMutation>(graphql`
      mutation PlannedCleaningJobRowMutation(
        $input: MutationCreateSysCleaningTicketInput!
      ) {
        createSysCleaningTicket(input: $input) {
          ... on MutationCreateSysCleaningTicketSuccess {
            data {
              ticketNumber
            }
          }
        }
      }
    `);

  const [isRefetching, startTransition] = useTransition();

  const handleCreate = () => {
    commit({
      variables: {
        input: {
          equipmentId: mould.id,
          createdDate: getTimestampForRelay(),
        },
      },
      onCompleted: () => {
        startTransition(() => {
          refetch({});
        });
      },
    });
  };

  if (!hasValue(mould)) {
    return (
      <TableRow>
        <TableCell colSpan={100} align="left">
          <Typography>
            {translate('PLANNED_JOBS.MOULD_MISSING', 'Missing mould')}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell align="center">{mould.equipmentNumber}</TableCell>
      <TableCell align="center">{mould.description}</TableCell>
      <TableCell align="center">{mould.statusCode}</TableCell>
      <TableCell align="center">{mould.location?.description}</TableCell>
      <TableCell align="center">
        {mould.weight?.value} {mould.weight?.unitOfMeasurement}
      </TableCell>
      <TableCell align="center">{mould.type}</TableCell>
      <TableCell align="center">{mould.boxConcept}</TableCell>
      <TableCell align="center">
        {mould.mouldMachine?.equipmentNumber}
      </TableCell>
      <TableCell align="center">
        {mould.lastCleaningShots?.toLocaleString(locale)}
      </TableCell>
      <TableCell align="center">
        {mould.actualShots?.toLocaleString(locale)}
      </TableCell>
      <TableCell align="center">
        {hasValue(mould.shotsLastUpdatedDate) &&
        hasValue(mould.shotsLastUpdatedDate?.date)
          ? new Date(mould.shotsLastUpdatedDate?.date).toLocaleDateString()
          : undefined}
      </TableCell>
      <TableCell align="center">
        {mould.cleaningInterval?.toLocaleString(locale)}
      </TableCell>
      <TableCell align="center">
        {mould.shotsSinceLastCleaning?.toLocaleString(locale)}
      </TableCell>
      <TableCell align="center">
        {hasValue(mould.cleaningInterval) &&
        hasValue(mould.shotsSinceLastCleaning)
          ? (
              mould.shotsSinceLastCleaning - mould.cleaningInterval
            ).toLocaleString(locale)
          : undefined}
      </TableCell>
      <TableCell align="center">
        {mould.elementsPerShot}/{mould.dimmings?.count}
      </TableCell>
      <TableCell align="center">
        {hasValue(mould.currentCleaningTicket) &&
        hasValue(mould.currentCleaningTicket.ticketNumber) ? (
          <Link
            to={AppRoutes.tickets.byTicketNumber(
              mould.currentCleaningTicket.ticketNumber,
              'details'
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {mould.currentCleaningTicket?.ticketNumber}
          </Link>
        ) : (
          <LoadingButton
            onClick={handleCreate}
            loading={isInFlight || isRefetching}
            sx={{ p: 0 }}
            loadingIndicator={<ActivityIndicator size={40} />}
          >
            {translate('PLANNED_JOBS.CREATE_TICKET', 'Create')}
          </LoadingButton>
        )}
      </TableCell>
    </TableRow>
  );
};
