import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketPriorityEnum } from '../../__apollo__/types';
import { CMSTicketsPriorityInput } from './__generated__/CmsMutationsEquipmentRepairMutation.graphql';

export const CmsTicketPriority: FC<{
  onChange: (value: CMSTicketsPriorityInput) => void;
}> = ({ onChange }) => {
  const { translate } = useTranslation();
  const handleOnChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onChange(value as CMSTicketsPriorityInput);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body2">
          {translate('CREATE_CMS_TICKET.PRIORITY', 'Choose priority')}
        </Typography>
      </Grid>
      <Grid item sx={{ pl: 1, mt: 1 }}>
        <RadioGroup onChange={handleOnChange}>
          <FormControlLabel
            value={TicketPriorityEnum.Priority1}
            control={<Radio size="small" />}
            label={
              <Typography variant="body2">
                {translate('PRIORITIES.Priority1', '1')}
              </Typography>
            }
          />
          <FormControlLabel
            value={TicketPriorityEnum.Priority2}
            control={<Radio size="small" />}
            label={
              <Typography variant="body2">
                {translate('PRIORITIES.Priority2', '2')}
              </Typography>
            }
          />
          <FormControlLabel
            value={TicketPriorityEnum.Priority8}
            control={<Radio size="small" />}
            label={
              <Typography variant="body2">
                {translate('PRIORITIES.Priority8', '8')}
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
