import { hasValue } from '@lego/mst-error-utilities';
import { TextField } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { stringIsPositiveInteger } from '../../utility/numbers';

export const AddTimeHourAndMinutesInput: FC<{
  machineOrEmployeeId?: string;
  onTimeChanged: (minutesSpent: number) => void;
}> = ({ machineOrEmployeeId, onTimeChanged }) => {
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [displayValue, setDisplayValue] = useState('00:00');

  useEffect(() => {
    onTimeChanged(hours * 60 + minutes);
  }, [machineOrEmployeeId, hours, minutes, onTimeChanged]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (!newValue.includes(':')) {
      return;
    }

    const [newHours, newMinutes] = newValue.split(':');

    if (isValidStringInput(newHours)) {
      setHours(newHours === '' ? 0 : Number.parseInt(newHours));
    }

    if (isValidStringInput(newMinutes, true)) {
      setMinutes(newMinutes === '' ? 0 : Number.parseInt(newMinutes));
    }

    setDisplayValue(
      `${isValidStringInput(newHours) ? newHours : hours}:${
        isValidStringInput(newMinutes, true) ? newMinutes : minutes
      }`
    );
  };

  return (
    <TextField
      data-cy={`AddTimeHourAndMinutesInput-${machineOrEmployeeId}`}
      variant="outlined"
      value={displayValue}
      onChange={onChange}
      inputMode="numeric"
      disabled={!hasValue(machineOrEmployeeId)}
    />
  );
};

const isValidStringInput = (value: string, isMinutes = false): boolean => {
  const MAX_HOURS = 99;
  const MINUTES_IN_HOUR = 60;

  const someConst = stringIsPositiveInteger(value) && value.length <= 2;
  const isValid = value === '' || someConst;

  if (isMinutes) {
    return value.length > 0
      ? isValid && Number.parseInt(value) < MINUTES_IN_HOUR
      : isValid;
  } else {
    return value.length > 0
      ? isValid && Number.parseInt(value) <= MAX_HOURS
      : isValid;
  }
};
