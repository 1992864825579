import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { ErrorMessage } from '../../migration/components/ErrorMessage';

export const CTNoManhoursOrRepairDocs: FC<{
  title: string;
  message: string;
  showWarning: boolean;
}> = ({ title, message, showWarning }) => {
  return (
    <Grid container direction="column" spacing={1} style={{ marginLeft: 8 }}>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </Grid>
      {showWarning && <CTWarningMessage message={message} />}
    </Grid>
  );
};

export const CTWarningMessage: FC<{ message: string }> = ({ message }) => {
  return (
    <Grid
      item
      container
      alignItems="center"
      spacing={1}
      data-cy="CTWarningMessage"
    >
      <Grid item>
        <ErrorMessage message={message} />
      </Grid>
    </Grid>
  );
};
