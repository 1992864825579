import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { GMDatePicker } from '../../components/shared/GMDatePicker';
import { useTranslation } from '../../utility/i18n/translation';

export const CmsTicketEndDate: FC<{
  value: Date | null;
  onChange: (value: Date | null) => void;
}> = ({ value, onChange }) => {
  const { translate } = useTranslation();
  const dateValid = (date: Date | null) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      hasValue(date) && date.toString() !== 'Invalid Date' && date >= today
    );
  };
  const handleOnChange = (date: Date | null) => {
    if (dateValid(date)) {
      onChange(date);
    } else {
      onChange(null);
    }
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <GMDatePicker
          title={translate(
            'CREATE_CMS_TICKET.REQUIRED_END_DATE',
            'Required end date'
          )}
          value={value}
          onChange={handleOnChange}
          showOptional={false}
          titleVariant="body2"
          ignoreInvalidInputs={false}
          disablePast
        />
      </Grid>
    </Grid>
  );
};
