/**
 * @generated SignedSource<<5b74dc6bef8feaf71e9dadf73ca9b364>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentCard_equipment$data = {
  readonly equipmentNumber: number;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentCard_Chips_equipment" | "EquipmentCard_EquipmentOnOtherPlantWarning_equipment" | "EquipmentDescription_equipment" | "EquipmentLocation_equipment" | "EquipmentNumber_equipment">;
  readonly " $fragmentType": "EquipmentCard_equipment";
};
export type EquipmentCard_equipment$key = {
  readonly " $data"?: EquipmentCard_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentCard_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentCard_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentNumber",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentNumber_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentDescription_equipment"
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentLocation_equipment"
        }
      ]
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentCard_Chips_equipment"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentCard_EquipmentOnOtherPlantWarning_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "1734fed42ef7ef6a38e9bd7706a5ee79";

export default node;
