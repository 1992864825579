import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from '../i18n/translation';
import { CustomSnackWithErrorDetails } from './CustomSnackWithErrorDetails';

export const useErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useTranslation();

  return useCallback(
    (errors: string[]) => {
      enqueueSnackbar(
        translate('ERRORS.UNKNOWN', 'Unexpected error occurred'),
        {
          content: function RelayErrorSnack(key, message) {
            return (
              <CustomSnackWithErrorDetails
                id={key}
                message={typeof message === 'string' ? message : ''}
                errorLines={errors}
              />
            );
          },
          persist: true,
        }
      );
    },
    [enqueueSnackbar, translate]
  );
};
