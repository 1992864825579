import { ApolloError, gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { useEffect } from 'react';
import { useGMLazyQuery, useGMQuery } from '../../apollo/customApolloHooks';
import { RepairDocumentationFlowVariant } from '../../__apollo__/types';
import { useAddRepairDescriptionContext } from './add-repair-description-context';
import {
  GetRepairFlowVariantForTicket,
  GetRepairFlowVariantForTicketVariables,
} from './__apollo__/GetRepairFlowVariantForTicket';
import {
  RepairComponentOptions,
  RepairComponentOptionsVariables,
} from './__apollo__/RepairComponentOptions';
import {
  RepairDescriptionActivities,
  RepairDescriptionActivitiesVariables,
} from './__apollo__/RepairDescriptionActivities';
import {
  RepairDescriptionCauses,
  RepairDescriptionCausesVariables,
} from './__apollo__/RepairDescriptionCauses';
import {
  RepairDescriptionDamages,
  RepairDescriptionDamagesVariables,
} from './__apollo__/RepairDescriptionDamages';
import {
  SubEquipmentOptions,
  SubEquipmentOptionsVariables,
} from './__apollo__/SubEquipmentOptions';

export const REPAIR_FLOW_VARIANT = gql`
  query GetRepairFlowVariantForTicket($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        repairDocumentation {
          id
          repairFlowVariant
        }
      }
    }
  }
`;

export const REPAIR_DESCRIPTION_SUB_EQUIPMENTS = gql`
  query SubEquipmentOptions(
    $input: RepairDocumentationSubEquipmentOptionsForTicket!
  ) {
    repairDescriptionSubEquipmentOptions(input: $input) {
      id
      ...SubEquipmentOption
    }
  }
  fragment SubEquipmentOption on RepairDocumentationSubEquipmentOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_COMPONENTS = gql`
  query RepairComponentOptions(
    $input: RepairDocumentationComponentOptionsForTicket!
  ) {
    repairDescriptionComponentOptions(input: $input) {
      id
      ...ComponentOption
    }
  }
  fragment ComponentOption on RepairDocumentationComponentOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_DAMAGES = gql`
  query RepairDescriptionDamages(
    $input: RepairDescriptionDamageOptionsForTicket!
  ) {
    repairDescriptionDamageOptions(input: $input) {
      id
      ...DamageOption
    }
  }
  fragment DamageOption on RepairDocumentationDamageOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_CAUSES = gql`
  query RepairDescriptionCauses(
    $input: RepairDescriptionCauseOptionsForTicket!
  ) {
    repairDescriptionCauseOptions(input: $input) {
      id
      ...CauseOption
    }
  }

  fragment CauseOption on RepairDocumentationCauseOption {
    id
    description
    requiresAdditionalInput
  }
`;

export const REPAIR_DESCRIPTION_ACTIVITIES = gql`
  query RepairDescriptionActivities(
    $input: RepairDescriptionActivityOptionsForTicket!
  ) {
    repairDescriptionActivityOptions(input: $input) {
      id
      ...ActivityOption
    }
  }

  fragment ActivityOption on RepairDocumentationActivityOption {
    id
    description
  }
`;

// Pre-fetches repair doc data by loading it into the Apollo cache
export const usePrefetchRepairDescriptionData = (
  ticketId: string
): {
  loading: boolean;
  errors: ApolloError | undefined;
} => {
  const {
    dispatch,
    state: { chosenDamage, chosenSubEquipment, chosenComponent },
  } = useAddRepairDescriptionContext();

  const {
    data: variantData,
    loading: variantLoading,
    error: variantError,
  } = useGMQuery<
    GetRepairFlowVariantForTicket,
    GetRepairFlowVariantForTicketVariables
  >(REPAIR_FLOW_VARIANT, {
    variables: { input: { id: ticketId } },
    onCompleted: (data) => {
      if (
        data.ticket.__typename === 'Ticket' &&
        data.ticket.repairDocumentation.repairFlowVariant ===
          RepairDocumentationFlowVariant.Packing
      ) {
        dispatch({
          type: 'set_repair_flow_variant',
          variant: RepairDocumentationFlowVariant.Packing,
        });
      }
    },
  });

  const { loading: subEquipmentLoading } = useGMQuery<
    SubEquipmentOptions,
    SubEquipmentOptionsVariables
  >(REPAIR_DESCRIPTION_SUB_EQUIPMENTS, {
    skip:
      variantLoading ||
      (variantData?.ticket.__typename === 'Ticket' &&
        variantData.ticket.repairDocumentation.repairFlowVariant ===
          RepairDocumentationFlowVariant.Baseline),
    variables: { input: { ticketId } },
    fetchPolicy: 'network-only',
  });

  const { loading: componentsLoading, error: subEquipmentError } = useGMQuery<
    RepairComponentOptions,
    RepairComponentOptionsVariables
  >(REPAIR_DESCRIPTION_COMPONENTS, {
    skip: !hasValue(chosenSubEquipment),
    variables: {
      input: { ticketId, subEquipmentId: chosenSubEquipment?.id ?? '' },
    },
    fetchPolicy: 'network-only',
  });

  const { loading: damagesLoading, error: damagesError } = useGMQuery<
    RepairDescriptionDamages,
    RepairDescriptionDamagesVariables
  >(REPAIR_DESCRIPTION_DAMAGES, {
    variables: { input: { ticketId, componentId: chosenComponent?.id } },
    fetchPolicy: 'network-only',
  });

  const [getCauses, { loading: causesLoading, error: causesError }] =
    useGMLazyQuery<RepairDescriptionCauses, RepairDescriptionCausesVariables>(
      REPAIR_DESCRIPTION_CAUSES
    );

  const { loading: activitiesLoading, error: activitiesError } = useGMQuery<
    RepairDescriptionActivities,
    RepairDescriptionActivitiesVariables
  >(REPAIR_DESCRIPTION_ACTIVITIES, {
    variables: { input: { ticketId } },
    onCompleted: (data) => {
      dispatch({
        type: 'set_should_show_activities',
        show:
          hasValue(data) && data.repairDescriptionActivityOptions.length > 0,
      });
    },
  });

  useEffect(() => {
    if (hasValue(chosenDamage) && !causesLoading) {
      getCauses({
        variables: { input: { chosenDamage: chosenDamage.id, ticketId } },
      });
    }
  }, [causesLoading, chosenDamage, getCauses, ticketId]);

  return {
    loading:
      damagesLoading ||
      activitiesLoading ||
      subEquipmentLoading ||
      componentsLoading,
    errors:
      damagesError ||
      activitiesError ||
      causesError ||
      variantError ||
      subEquipmentError,
  };
};
