import { Autocomplete, Skeleton, TextField } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { skeletonify } from '../../skeleton';
import {
  SearchEquipmentDialogLocationFilter_plant$data,
  SearchEquipmentDialogLocationFilter_plant$key,
} from './__generated__/SearchEquipmentDialogLocationFilter_plant.graphql';

export type SearchEquipmentDialogLocationFilterPropsLocationType =
  | NonNullable<SearchEquipmentDialogLocationFilter_plant$data['locations']>[0]
  | null;

export type SearchEquipmentDialogLocationFilterProps = {
  plant: SearchEquipmentDialogLocationFilter_plant$key | null;
  onLocationChanged: (
    input: SearchEquipmentDialogLocationFilterPropsLocationType
  ) => void;
  selectedLocation: SearchEquipmentDialogLocationFilterPropsLocationType;
};

const ActualComponent: FC<SearchEquipmentDialogLocationFilterProps> = (
  props
) => {
  const { plant: plantRef, onLocationChanged, selectedLocation } = props;
  const data = useFragment(
    graphql`
      fragment SearchEquipmentDialogLocationFilter_plant on Plant {
        locations {
          id
          description
          locationNumber
        }
      }
    `,
    plantRef
  );

  const options = data?.locations ?? [];

  return (
    <Autocomplete
      onChange={(_, value) => onLocationChanged(value)}
      value={selectedLocation}
      getOptionLabel={({ locationNumber, description }) =>
        `${description} ${locationNumber}`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={options.length === 0}
      options={options}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="rectangular" width={'100%'} height={50} />;
};

export const SearchEquipmentDialogLocationFilter = skeletonify(
  'SearchEquipmentDialogLocationFilter',
  ActualComponent,
  SkeletonComponent
);
