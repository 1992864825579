import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { TextField, Typography } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';
import { useGMMutation } from '../../apollo/customApolloHooks';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { SimpleDialog } from '../shared/SimpleDialog';
import { TDErrorDescriptionContent } from './TDErrorDescription';
import {
  AddErrorDescription,
  AddErrorDescriptionVariables,
} from './__apollo__/AddErrorDescription';
import { TicketDetailsErrorDescription } from './__apollo__/TicketDetailsErrorDescription';

const ADD_ERROR_DESCRIPTION_MUTATION = gql`
  mutation AddErrorDescription($input: TicketAddErrorDescriptionInput!) {
    addErrorDescriptionToTicket(input: $input) {
      ... on Ticket {
        id
        description
        errorDescription {
          description
          createdDate
        }
      }
    }
  }
`;

const MAX_LENGTH_FOR_ERROR_DESCRIPTION = 10000;
export const AddErrorDescriptionDialog: FC<{
  open: boolean;
  closeDialog: () => void;
  data: TicketDetailsErrorDescription;
}> = ({ data, open, closeDialog }) => {
  const { translate } = useTranslation();
  const [newDescription, setNewDescription] = useState<string>();
  const { loading, onSavePressed } = useAddMutation(closeDialog);

  const validInput = hasValue(newDescription) && newDescription.length > 0;

  const onSave = useCallback(() => {
    if (validInput) {
      onSavePressed(newDescription, data.id);
      setNewDescription(undefined);
    }
  }, [newDescription, onSavePressed, data.id, validInput]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewDescription(newValue);
  };

  return (
    <SimpleDialog
      onDismiss={closeDialog}
      secondaryAction={{
        secondaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.CANCEL_BUTTON',
          'Cancel'
        ),
        secondaryActionPressed: closeDialog,
      }}
      open={open}
      title={translate(
        'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.TITLE',
        'Add error description'
      )}
      primaryAction={{
        primaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.SAVE_BUTTON',
          'SAVE'
        ),
        primaryActionPressed: onSave,
        primaryActionLoading: loading,
      }}
      content={{
        type: 'node',
        node: (
          <Content
            data={data}
            onChange={onChange}
            textFieldValue={newDescription}
          />
        ),
      }}
      maxWidth={'lg'}
    />
  );
};

type DialogContentProps = {
  data: TicketDetailsErrorDescription;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  textFieldValue?: string;
};
const Content: FC<DialogContentProps> = ({
  data,
  onChange,
  textFieldValue,
}) => {
  const { translate } = useTranslation();

  return (
    <Fragment>
      <DialogContentText sx={{ mb: 2 }}>
        {translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.SUBTITLE',
          'Current error description'
        )}
      </DialogContentText>
      <TDErrorDescriptionContent {...data} />
      <DialogContentText sx={{ my: 2 }}>
        {translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.ADDITIONAL_SUBTITLE',
          'Additional error description'
        )}
      </DialogContentText>
      <Typography>
        {translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.INPUT_HEADER',
          'Error description'
        )}
      </Typography>
      <TextField
        variant="outlined"
        onChange={onChange}
        fullWidth
        minRows={5}
        multiline
        inputProps={{ maxLength: MAX_LENGTH_FOR_ERROR_DESCRIPTION }}
        value={textFieldValue}
        placeholder={translate(
          'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_ADD_DIALOG.INPUT_PLACEHOLDEr',
          'Enter new error description'
        )}
      />
    </Fragment>
  );
};

const useAddMutation = (closeDialog: () => void) => {
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();
  const failureString = translate(
    'TICKET_DETAILS.CARDS.ERROR_DESCRIPTION_SAVE_FAILED',
    'Could not save, please try again'
  );

  const [save, { loading, error }] = useGMMutation<
    AddErrorDescription,
    AddErrorDescriptionVariables
  >(ADD_ERROR_DESCRIPTION_MUTATION, {
    onCompleted: (data) => {
      if (data.addErrorDescriptionToTicket.__typename === 'Ticket') {
        showSnack({
          message: translate(
            'SCREENS.TICKET_ADD_ERROR_DESCRIPTION.SUCCESS',
            'Successfully added error description'
          ),
          variant: 'success',
        });
        closeDialog();
      } else {
        showSnack({ message: failureString, variant: 'error' });
      }
    },
  });

  const onSavePressed = useCallback(
    (description: string, ticketId: string) => {
      save({ variables: { input: { description, ticketId } } });
    },
    [save]
  );

  return { onSavePressed, loading, error };
};
