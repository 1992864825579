import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEventHandler, Dispatch, useCallback } from 'react';
import {
  MouldStatusInput,
  TicketsPriorityInput,
  TicketsStatusInput,
} from './__generated__/TicketSearchQuery.graphql';

type SupportedInputsEnum =
  | MouldStatusInput
  | TicketsPriorityInput
  | TicketsStatusInput;

type FacetInputType = {
  readonly count: number;
  readonly value: SupportedInputsEnum;
};

type FacetCountProps<T extends FacetInputType> = {
  value: T['value'];
  facets: ReadonlyArray<T>;
};

const Facet = <T extends FacetInputType>({
  value,
  facets,
}: FacetCountProps<T>) => {
  const { palette } = useTheme();
  return (
    <Typography style={{ color: palette.text.disabled }}>
      {facets?.find((x) => x.value === value)?.count ?? 0}
    </Typography>
  );
};

type TicketSearchFilterToggleProp<T extends FacetInputType> = {
  label: string;
  facetValue: FacetCountProps<T>;
  enabled: boolean;
  onChange: Dispatch<boolean>;
};

export const TicketSearchBooleanFilterWithFacet = <T extends FacetInputType>(
  props: TicketSearchFilterToggleProp<T>
) => {
  const {
    label,
    facetValue: { facets, value },
    onChange,
    enabled,
  } = props;
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.currentTarget.checked);
    },
    [onChange]
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={enabled} onChange={handleChange} />}
          label={label}
        />
      </Grid>
      <Grid item>
        <Facet facets={facets} value={value} />
      </Grid>
    </Grid>
  );
};
