import { gql } from '@apollo/client';
import { Button, Card, CardActionArea, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../../Router';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { TicketStatus } from '../../../__apollo__/types';
import {
  ErrorDescriptionArray,
  ERROR_DESCRIPTION_FRAGMENT,
} from '../../ticket-details/TDErrorDescription';
import { CountdownTimer } from '../components/CountdownTimer';
import { EquipmentIdWithIcon } from '../components/EquipmentIdWithIcon';
import {
  EquipmentLocation,
  TICKET_CARD_LOCATION_FRAGMENT,
} from '../components/EquipmentLocation';
import {
  EquipmentManufacturer,
  TICKET_CARD_MANUFACTURER_FRAGMENT,
} from '../components/EquipmentManufacturer';
import { PriorityPill } from '../components/PriorityPill';
import {
  TicketCardMouldChips,
  TICKET_CARD_MOULD_CHIP_FRAGMENTS,
} from '../components/TicketCardMouldChips';
import { TicketIdWithIcon } from '../components/TicketIdWithIcon';
import { TicketDateWithCalendarIcon } from '../components/TicketStartDate';
import { TicketCardFragment } from './__apollo__/TicketCardFragment';

export const TICKET_CARD = gql`
  fragment TicketCardFragment on Ticket {
    id
    isAssigned
    priority
    description
    ...ErrorDescriptionFragment
    requiredEndDate
    status
    ...TicketCardMouldChipsFragment
    equipment {
      __typename
      ... on EquipmentValue {
        value {
          __typename
          id
          description
          ...ManufacturerFragment
          ...LocationFragment
        }
      }
    }
  }
  ${TICKET_CARD_LOCATION_FRAGMENT}
  ${TICKET_CARD_MOULD_CHIP_FRAGMENTS}
  ${ERROR_DESCRIPTION_FRAGMENT}
  ${TICKET_CARD_MANUFACTURER_FRAGMENT}
`;

export const TicketCard: FC<{
  data: TicketCardFragment;
  showAssignedIconIfAssigned: boolean;
}> = ({ data: ticket, showAssignedIconIfAssigned }) => {
  const { translate } = useTranslation();
  const equipment =
    ticket.equipment?.__typename === 'EquipmentValue'
      ? ticket.equipment?.value
      : null;

  return (
    <Button
      component={NavLink}
      to={AppRoutes.tickets.byTicketNumber(ticket.id, 'details')}
      sx={{ p: 0, borderRadius: 0, width: '100%' }}
    >
      <Card sx={{ flex: 1, p: 0 }}>
        <CardActionArea>
          <Grid container direction="row" sx={{ p: 2 }}>
            {/* Prio pill */}
            <Grid item container direction="column" xs={1} sx={{ mr: 1 }}>
              <Grid item xs={6}>
                <PriorityPill priority={ticket.priority} />
              </Grid>
              {ticket.status === TicketStatus.InProgress &&
                showAssignedIconIfAssigned && (
                  <Grid item style={{ textAlign: 'center' }} xs={5}>
                    <Icons.Assign style={{ fontSize: 42 }} />
                    <Typography variant="body2">
                      {translate('TICKET_CARD.ASSIGNED', 'Assigned')}
                    </Typography>
                  </Grid>
                )}
              {ticket.status === TicketStatus.Released && (
                <Grid item style={{ textAlign: 'center' }} xs={5}>
                  <Icons.Unassign style={{ fontSize: 42 }} />
                  <Typography variant="body2">
                    {translate('TICKET_CARD.RELEASED', 'Released')}
                  </Typography>
                </Grid>
              )}
            </Grid>

            {/* Main content (descriptions, ids) */}
            <Grid item xs>
              <Grid container item direction="column" spacing={2}>
                {/* IDs */}
                <Grid container item direction="row" spacing={2}>
                  {equipment && (
                    <Grid item>
                      <EquipmentIdWithIcon equipmentId={equipment.id} />
                    </Grid>
                  )}
                  <Grid item>
                    <TicketIdWithIcon ticketId={ticket.id} />
                  </Grid>
                </Grid>
                {/* Equipment Description */}
                <Grid item>
                  <Typography variant="subtitle2" textAlign="left">
                    {equipment?.description}
                  </Typography>
                </Grid>
                {/* Manufacturer & model number */}
                {(equipment?.manufacturer || equipment?.modelNumber) && (
                  <Grid item style={{ paddingTop: 5 }}>
                    <EquipmentManufacturer {...equipment} />
                  </Grid>
                )}
                {/* Ticket Description */}
                <Grid item>
                  <ErrorDescriptionArray
                    errorDescription={ticket.errorDescription}
                  />
                </Grid>
                {/* Ticket card mould chips */}
                <Grid item>
                  <TicketCardMouldChips {...ticket} />
                </Grid>
              </Grid>
            </Grid>

            {/* Date and place */}
            <Grid item xs={3}>
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  {ticket.requiredEndDate && (
                    <TicketDateWithCalendarIcon date={ticket.requiredEndDate} />
                  )}
                </Grid>
                {ticket.requiredEndDate && (
                  <Grid item>
                    <CountdownTimer endDate={ticket.requiredEndDate} />
                  </Grid>
                )}
                {ticket.equipment?.__typename === 'EquipmentValue' && (
                  <Grid item>
                    <EquipmentLocation {...ticket.equipment.value} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Button>
  );
};
