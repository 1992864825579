import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { Icons } from '../../../utility/icons';
import { skeletonify } from '../../skeleton';
import { TicketNumber_ticket$key } from './__generated__/TicketNumber_ticket.graphql';

const ActualComponent: FC<{ ticket: TicketNumber_ticket$key }> = (props) => {
  const { ticket: ticketRef } = props;

  const ticket = useFragment(
    graphql`
      fragment TicketNumber_ticket on Ticket {
        ticketNumber
      }
    `,
    ticketRef
  );

  return <Typography variant="body1">{ticket.ticketNumber}</Typography>;
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

const StructureComponent: FC<{ children: any }> = ({ children }) => (
  <Grid container direction="row" spacing={1}>
    <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
      <Icons.TicketId />
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);

export const TicketNumber = skeletonify(
  'TicketNumber',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);
