import { useTheme } from '@mui/material';
import Fab from '@mui/material/Fab';
import { FC, useCallback, useState } from 'react';
import { Icons } from '../../utility/icons';
import { PrintEquipmentLabelDialog } from './PrintEquipmentLabelDialog';

const size = 56;
export const EquipmentDetailsFab: FC<{ equipmentId: string }> = ({
  equipmentId,
}) => {
  const {
    palette: {
      primary: { main: backgroundColor },
    },
  } = useTheme();

  const [open, setOpen] = useState(false);

  const onPrintButtonClicked = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onDismiss = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <PrintEquipmentLabelDialog
        open={open}
        onDismiss={onDismiss}
        equipmentId={equipmentId}
      />

      <Fab
        color="primary"
        sx={{
          backgroundColor,
          height: size,
          width: size,
          position: 'absolute',
          right: '10%',
          top: 156,
        }}
        onClick={onPrintButtonClicked}
      >
        <Icons.Print fill="white" />
      </Fab>
    </>
  );
};
