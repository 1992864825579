import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import {
  generateTransactionId,
  hasValue,
  redactVariablesForSentry,
  reportGraphQLError,
  reportNetworkError,
  reportUnknownApolloError,
} from '@lego/mst-error-utilities';
import { useCallback, useMemo } from 'react';
import pkg from '../../package.json';
import { useAreaAndProcessContext } from '../contexts/area';
import { useGetAccessToken } from '../contexts/AuthContext';
import possibleTypes from '../possibleTypes.json';
import { getCrashReporter } from '../utility/crash-reporter';
import { useTranslation } from '../utility/i18n/translation';

// These are needed when working with unions and interfaces in fragments as we do with errors, see
// https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
export const createCache = (): InMemoryCache =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Profile: {
        fields: {
          selectedProcess: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          // The input for relayStylePagination describes the key in the cache, so different
          // input value combinations create different keys
          // https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
          confidentialityQuery: relayStylePagination([
            'input',
            [
              'areaId',
              'processId',
              'userSearchTerm',
              'createdAtFrom',
              'createdAtTo',
            ],
          ]),
          ticketList: relayStylePagination([
            'input',
            [
              'processId',
              'priorities',
              'assignedToEmployee',
              'searchTerm',
              'sorting',
              'status',
              'completedFromDate',
              'completedToDate',
              'equipmentId',
              'locationId',
              'sublocationId',
            ],
          ]),
          equipmentHistory: relayStylePagination([
            'input',
            ['equipmentId', 'completedDateRange', 'priorities'],
          ]),
        },
      },
    },
  });

export const apolloCache = createCache();

const errorLink: ApolloLink = onError(
  ({ networkError, operation, graphQLErrors, response }) => {
    const reporter = getCrashReporter();
    if (hasValue(graphQLErrors) && graphQLErrors.length > 0) {
      graphQLErrors.forEach((err) =>
        reportGraphQLError(operation, err, reporter)
      );

      return;
    }

    if (hasValue(networkError)) {
      reportNetworkError(operation, networkError, reporter);
    } else {
      reportUnknownApolloError({ operation, response, reporter });
    }
  }
);

const breadcrumbLink = new ApolloLink((operation, forward) => {
  const { variables, operationName } = operation;
  const context = operation.getContext();
  const transactionId = hasValue(context.headers)
    ? context.headers['x-transaction-id']
    : undefined;

  getCrashReporter().addBreadcrumb({
    category: 'graphql',
    data: {
      request: operationName,
      variables: redactVariablesForSentry(variables),
      transactionId,
    },
  });

  return forward(operation);
});

const useFetch = () => {
  const { getAccessToken } = useGetAccessToken();

  return useCallback(
    async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
      const token = await getAccessToken();
      const headers = hasValue(token)
        ? {
            ...init?.headers,
            Authorization: `Bearer ${token}`,
          }
        : {
            ...init?.headers,
          };

      return fetch(input, {
        ...init,
        credentials: 'include',
        headers,
      });
    },
    [getAccessToken]
  );
};

const useHeaderLink = (): ApolloLink => {
  const { locale } = useTranslation();
  const { selectedArea } = useAreaAndProcessContext();

  return useMemo(() => {
    const headers: Record<string, string> = {
      'x-user-language': locale,
      'x-plant-id': selectedArea.plantId,
      'x-area-id': selectedArea.id,
    };

    return setContext(async (_, { headers: currentHeaders }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...currentHeaders,
        ...headers,
      },
    }));
  }, [locale, selectedArea.id, selectedArea.plantId]);
};

const transactionIdLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-transaction-id': generateTransactionId(),
    },
  }));

  return forward(operation);
});

export const useApolloClient = ():
  | ApolloClient<NormalizedCacheObject>
  | undefined => {
  const headerLink = useHeaderLink();
  const fetch = useFetch();

  return useMemo(() => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_BASE_URL}/maintenance/graphql`,
      // Use if developing against local instance instead:
      // uri: 'http://localhost:4000',
      fetch,
    });

    return new ApolloClient({
      cache: apolloCache,
      name: 'MST-Ticket-App',
      link: ApolloLink.from([
        transactionIdLink,
        breadcrumbLink,
        headerLink,
        errorLink,
        httpLink,
      ]),
      version: pkg.version,
    });
  }, [fetch, headerLink]);
};
