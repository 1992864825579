import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import {
  CardWithTitle,
  CardWithTitleSkeleton,
} from '../../../components/shared/CardWithTitle';
import {
  CARD_DATE_FORMAT,
  useFormatRelayLocalTimestampWithLocale,
} from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { EmployeeAvatarWithName } from '../../components/employee/EmployeeAvatarWithName';
import { skeletonify } from '../../skeleton';
import { TDCreatedBy_ticket$key } from './__generated__/TDCreatedBy_ticket.graphql';

const ActualComponent: FC<{ employee: TDCreatedBy_ticket$key | null }> = ({
  employee: employeeRef,
}) => {
  const avatarSize = 46;
  const { translate } = useTranslation();

  const { parseTimestamp } = useFormatRelayLocalTimestampWithLocale();
  const data = useFragment(
    graphql`
      fragment TDCreatedBy_ticket on Ticket {
        createdTimestamp {
          date
          time
        }
        createdBy {
          __typename
          ... on Employee {
            ...EmployeeAvatarWithName_employee
          }
        }
      }
    `,
    employeeRef
  );
  if (!data) {
    return null;
  }
  const { createdBy, createdTimestamp } = data;

  const dateLabel = parseTimestamp({
    date: createdTimestamp.date,
    time: createdTimestamp.time,
    dateFormat: CARD_DATE_FORMAT,
  });

  const title = translate(
    'TICKET_DETAILS.CARDS.CREATED_BY.TITLE',
    'Created By'
  );

  if (createdBy?.__typename !== 'Employee') {
    const message = translate(
      'TICKET_DETAILS.CARDS.CREATED_BY.SYSTEM',
      'System'
    );

    return (
      <CardWithTitle title={title} halfSize={true}>
        <Typography style={{ height: 70 }}>{message}</Typography>
      </CardWithTitle>
    );
  }

  return (
    <CardWithTitle title={title} halfSize={true}>
      <EmployeeAvatarWithName
        employee={createdBy}
        size={avatarSize}
        secondaryLabel={dateLabel}
      />
    </CardWithTitle>
  );
};

const SkeletonComponent: FC = () => {
  return <TDAssignedToOrCreatedBySkeleton />;
};

export const TDCreatedBy = skeletonify(
  'TDCreatedBy',
  ActualComponent,
  SkeletonComponent
);

export const TDAssignedToOrCreatedBySkeleton: FC = () => {
  return (
    <CardWithTitleSkeleton halfSize={true}>
      <Grid container direction="row" alignItems="center">
        <Grid item container xs={2}>
          <Grid item>
            <EmployeeAvatarWithName.Skeleton size={46} />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={8}>
          <Grid item>
            <Skeleton variant="text" width={250} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={150} />
          </Grid>
        </Grid>
      </Grid>
    </CardWithTitleSkeleton>
  );
};
