import { Grid, Paper, Tab, Tabs } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, SyntheticEvent, useCallback } from 'react';
import { useFragment } from 'react-relay';
import { useNavigate, useParams } from 'react-router-dom';
import { TDHeaderSkeleton } from '../../components/ticket-details/TDHeader';
import { useAreaAndProcessContext } from '../../contexts/area';
import { AppRoutes, RouteTypes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { EquipmentDescription } from '../components/equipment/EquipmentDescription';
import { EquipmentGroupChip } from '../components/equipment/EquipmentGroupChip';
import { EquipmentNumber } from '../components/equipment/EquipmentNumber';
import { skeletonify } from '../skeleton';
import { EquipmentDetailsFab } from './EquipmentDetailsFab';
import { EquipmentDetailsHeaderFragment$key } from './__generated__/EquipmentDetailsHeaderFragment.graphql';

const GUTTERS = '10%';

const ActualComponent: FC<{
  details: EquipmentDetailsHeaderFragment$key | null;
}> = (props) => {
  const { translate } = useTranslation();
  const { relayProcessId } = useAreaAndProcessContext();

  const { tab, id } = useParams() as RouteTypes['equipment'];
  const navigate = useNavigate();

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: RouteTypes['equipment']['tab']) => {
      navigate(AppRoutes.equipment.byId(id, newValue), { replace: true });
    },
    [id, navigate]
  );

  const { details: detailsRef } = props;
  const details = useFragment(
    graphql`
      fragment EquipmentDetailsHeaderFragment on Equipment {
        __typename
        id
        ...EquipmentNumber_equipment
        ...EquipmentDescription_equipment
        ...EquipmentGroupChip_equipment
      }
    `,
    detailsRef ?? null
  );

  if (!details) {
    return null;
  }

  const isMould = details.__typename === 'Mould';

  return (
    <Paper
      style={{ borderRadius: 0, width: '100%', marginBottom: 1 }}
      data-cy="EDHeader"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ py: 1, px: GUTTERS }}
      >
        <Grid container spacing={2} sx={{ flex: 1 }}>
          <Grid item>
            <EquipmentNumber equipment={details} />
          </Grid>
          <Grid item>
            <EquipmentDescription equipment={details} />
          </Grid>
        </Grid>
        <Grid item>
          <EquipmentGroupChip equipment={details} />
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: GUTTERS,
          height: 0,
        }}
      >
        <EquipmentDetailsFab equipmentId={details.id} />
      </Grid>
      <Grid sx={{ ml: GUTTERS, pl: 1 }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab
            value={'details'}
            label={translate('TICKET_DETAILS.HEADER.TABS.DETAILS', undefined)}
            id={'details'}
            data-cy="EDHeader-tab-details"
          />
          <Tab
            label={translate('TICKET_DETAILS.HEADER.TABS.HISTORY', undefined)}
            id={'history'}
            value={'history'}
            data-cy="EDHeader-tab-history"
          />
          <Tab
            label={translate('TICKET_DETAILS.HEADER.TABS.COSTS', undefined)}
            id={'costs'}
            value={'costs'}
            data-cy="EDHeader-tab-costs"
          />
          <Tab
            label={translate('TICKET_DETAILS.HEADER.TABS.DOCUMENTS', undefined)}
            id={'documents'}
            value={'documents'}
            data-cy="EDHeader-tab-documents"
          />
          {isMould ? (
            <Tab
              label={translate('TICKET_DETAILS.HEADER.TABS.MTS_LOG', 'MTS Log')}
              id={'mts-log'}
              value={'mts-log'}
            />
          ) : null}
          {isMould ? (
            <Tab
              label={translate(
                'TICKET_DETAILS.HEADER.TABS.SISTER_MOULDS',
                'Sister Moulds'
              )}
              id={'sister-moulds'}
              value={'sister-moulds'}
            />
          ) : null}
          {relayProcessId ? (
            <Tab
              label={translate(
                'TICKET_DETAILS.HEADER.TABS.SPAREPARTS',
                'Spare parts'
              )}
              id={'spareparts'}
              value={'spareparts'}
            />
          ) : null}
          <Tab
            label={translate(
              'TICKET_DETAILS.HEADER.TABS.OPEN_TICKETS',
              'Open tickets'
            )}
            id={'open-tickets'}
            value={'open-tickets'}
          />
        </Tabs>
      </Grid>
    </Paper>
  );
};

export const EquipmentDetailsHeader = skeletonify(
  'EquipmentDetailsHeader',
  ActualComponent,
  () => {
    return <TDHeaderSkeleton narrow={true} />;
  }
);
