import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Checkbox, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import {
  getFlagIconForPlantNumber,
  usePlantIdentifierForNumber,
} from '../shared/flagIcons';
import { cleaningsSaved } from './cleaningsSaved';
import { SisterMouldRow as SisterMouldRowType } from './__apollo__/SisterMouldRow';

export const SISTER_MOULD_ROW_FRAGMENT = gql`
  fragment SisterMouldRow on Mould {
    id
    statusCode
    mouldAgePercentage
    cleaningInterval
    plantId
    actualShots
    budgetedShots
    location {
      ... on Location {
        id
      }
    }
  }
`;

export const SisterMouldRow: FC<{
  data: SisterMouldRowType;
  variant: 'own' | 'sister';
  selected: boolean;
  canBeSelected: boolean;
  newCleaningInterval?: number;
}> = ({ data, variant, newCleaningInterval, selected, canBeSelected }) => {
  const { palette } = useTheme();
  const countryIdentifier = usePlantIdentifierForNumber(data.plantId);
  const textColor = selected ? 'white' : undefined;
  const { actualShots, budgetedShots, cleaningInterval } = data;
  const { translate } = useTranslation();

  const mouldOverused =
    hasValue(budgetedShots) && budgetedShots !== 0
      ? actualShots > budgetedShots
      : undefined;

  const cleaningsSavedLabel: string = useMemo(() => {
    if (!hasValue(newCleaningInterval) || mouldOverused) {
      return '---';
    }
    return cleaningsSaved({
      actualShots,
      budgetedShots,
      currentCleaningInterval: cleaningInterval ?? 0,
      newCleaningInterval,
    }).toString();
  }, [
    actualShots,
    budgetedShots,
    cleaningInterval,
    mouldOverused,
    newCleaningInterval,
  ]);

  return (
    <Grid
      container
      flexDirection="row"
      sx={{
        backgroundColor: selected ? palette.primary.main : undefined,
        py: 2,
        width: '100%',
      }}
    >
      {/* Icon / checkmark */}
      <Grid container item flex={1} justifyContent="center" alignItems="center">
        <Grid item>
          {variant === 'own' && (
            <Icons.LocationFilled fill="green" style={{ fontSize: 42 }} />
          )}
          {variant === 'sister' && canBeSelected && (
            <Checkbox
              style={{ color: selected ? 'white' : undefined }}
              checked={selected}
            />
          )}
        </Grid>
      </Grid>
      <Grid item flex={11}>
        {/* Row content */}
        <Grid container flexDirection={'row'} spacing={1} sx={{ pr: 2 }}>
          {/* Header */}
          <Grid
            item
            container
            flexDirection={'row'}
            justifyContent="space-between"
          >
            <Grid item>
              <Grid item container flexDirection={'row'} spacing={1}>
                <Grid item>
                  {data.statusCode && <StatusCodePill code={data.statusCode} />}
                </Grid>
                <Grid item>
                  <Typography sx={{ color: textColor }}>{data.id}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container flexDirection={'row'} spacing={1}>
                <Grid item>
                  <Typography sx={{ color: textColor }}>
                    {countryIdentifier} ({data.plantId})
                  </Typography>
                </Grid>
                <Grid item>
                  {getFlagIconForPlantNumber(data.plantId, {
                    height: 15,
                    width: 25,
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Body */}
          <Grid
            item
            container
            flexDirection={'row'}
            justifyContent="space-between"
          >
            <Column>
              <ValueAndLabel
                selected={selected}
                value={
                  hasValue(data.mouldAgePercentage)
                    ? `${data.mouldAgePercentage}%`
                    : '---'
                }
                label="budgetedShots"
              />
            </Column>
            <Column>
              <ValueAndLabel
                selected={selected}
                value={`${data.cleaningInterval}`}
                label="currentCleaning"
              />
            </Column>
            <Column>
              {selected && (
                <ValueAndLabel
                  selected={selected}
                  value={newCleaningInterval?.toString() ?? '---'}
                  label="newCleaning"
                />
              )}
            </Column>
            <Column>
              {selected && (
                <Tooltip
                  placement="top-end"
                  title={translate(
                    'SISTER_MOULD_LIST.CLEANINGS_SAVED_TOOLTIP',
                    'in the remaining lifetime of the mould'
                  )}
                >
                  {/* <div> due to https://mui.com/material-ui/guides/composition/#caveat-with-refs */}
                  <div>
                    <ValueAndLabel
                      selected={selected}
                      value={cleaningsSavedLabel}
                      label="cleaningsSaved"
                      mouldOverused={mouldOverused}
                    />
                  </div>
                </Tooltip>
              )}
            </Column>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Column: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Grid item lg={3} md={6} xs={12}>
      {children}
    </Grid>
  );
};

const ValueAndLabel: FC<{
  value: string;
  label: 'budgetedShots' | 'currentCleaning' | 'newCleaning' | 'cleaningsSaved';
  selected: boolean;
  mouldOverused?: boolean;
}> = ({ label, value, selected, mouldOverused }) => {
  const { translate } = useTranslation();
  const {
    palette: {
      text: { secondary, primary },
    },
  } = useTheme();

  const labelTextColor = selected ? 'white' : secondary;
  const valueTextColor = selected ? 'white' : primary;

  const translatedLabel: string = useMemo(() => {
    switch (label) {
      case 'budgetedShots':
        return translate(
          'SISTER_MOULD_LIST.BUDGETED_SHOTS',
          'of budgeted shots'
        );
      case 'currentCleaning':
        return translate(
          'SISTER_MOULD_LIST.CURRENT_CLEANING',
          'Current cleaning interval'
        );
      case 'newCleaning':
        return translate(
          'SISTER_MOULD_LIST.NEW_CLEANING',
          'New cleaning interval'
        );
      case 'cleaningsSaved':
        return translate(
          'SISTER_MOULD_LIST.CLEANINGS_SAVED',
          'Cleanings saved'
        );
    }
  }, [label, translate]);

  return (
    <Grid
      container
      flexDirection={{ lg: 'column' }}
      sx={{ gap: { xs: 2, lg: 0 } }}
    >
      <Grid item flexDirection={'row'} spacing={2}>
        <Grid item>
          <Typography color={valueTextColor}>{value}</Typography>
        </Grid>
        {mouldOverused && (
          <Grid item>
            <Tooltip
              placement="bottom-end"
              title={translate(
                'SISTER_MOULD_LIST.SHOTS_OVER_BUDGET_TOOLTIP',
                'Can not calculate cleanings saved, as mould has already been used for more shots it was budgeted for'
              )}
            >
              <HelpOutlineIcon style={{ color: 'white' }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography color={labelTextColor} variant="caption">
          {translatedLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

const StatusCodePill: FC<{ code: number }> = ({ code }) => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        color: 'white',
        backgroundColor: palette.success.main,
        px: 1,
        borderRadius: 4,
      }}
    >
      {code}
    </Typography>
  );
};
