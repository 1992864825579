import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { useGMMutation } from '../../apollo/customApolloHooks';
import { useMachineHoursContext } from '../../contexts/add-machine-hours/add-machine-hours-context';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import {
  BulkMachineTimeAdditionInput,
  ManageMachineHoursInBulkInput,
} from '../../__apollo__/types';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from './overview/time-registration-gql-fragments';
import {
  UpdateMachineHoursForTicket,
  UpdateMachineHoursForTicketVariables,
} from './__apollo__/UpdateMachineHoursForTicket';

const UPDATE_MACHINE_HOURS_FOR_TICKET = gql`
  mutation UpdateMachineHoursForTicket($input: ManageMachineHoursInBulkInput!) {
    manageMachineTimeRegistrationsInBulk(input: $input) {
      ... on Ticket {
        id
        ...TimeRegistrationOverview
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
`;

type SaveTimeResult = 'success' | 'error' | 'no-request-made';

export const useAddMachineHoursMutation = (
  ticketId: string
): { saveChanges: () => Promise<SaveTimeResult>; loading: boolean } => {
  const {
    state: { additions, deletions },
  } = useMachineHoursContext();
  const { translate } = useTranslation();

  const { showSnack } = useGMSnackbar();
  showSnack;

  const [updateMachineHoursForTicket, { loading }] = useGMMutation<
    UpdateMachineHoursForTicket,
    UpdateMachineHoursForTicketVariables
  >(UPDATE_MACHINE_HOURS_FOR_TICKET, {
    onCompleted: () => {
      showSnack({
        message: translate(
          'ADD_MACHINE_HOURS.SUCCESS_SNACK',
          'Machine hours for ticket successfully updated'
        ),
        variant: 'success',
      });
    },
  });

  const saveChanges = async (): Promise<SaveTimeResult> => {
    const input: ManageMachineHoursInBulkInput = {
      ticketId,
      additions: additions
        .filter((val) => val.minutesSpent > 0)
        .map((addition) => {
          return {
            machineId: addition.machine.id,
            minutes: addition.minutesSpent,
            comment: addition.comment,
          } as BulkMachineTimeAdditionInput;
        }),
      timeRegistrationIdsToDelete: deletions.map((deletion) =>
        deletion.timeRegistrationNumber.toString()
      ),
    };

    if (
      input.additions.length === 0 &&
      input.timeRegistrationIdsToDelete.length === 0
    ) {
      return 'no-request-made';
    }

    const res = await updateMachineHoursForTicket({
      variables: {
        input,
      },
    });

    return !hasValue(res) || hasValue(res.errors) ? 'error' : 'success';
  };

  return {
    saveChanges,
    loading,
  };
};
