import { gql } from '@apollo/client';
import { TicketPriorityEnum } from '../__apollo__/types';
import { CanCloseGreenCleaningMould } from './__apollo__/CanCloseGreenCleaningMould';

export const CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT = gql`
  fragment CanCloseGreenCleaningMould on Ticket {
    id
    priority
    coding {
      ... on Coding {
        number
      }
    }
  }
`;

// https://legogroup.atlassian.net/wiki/spaces/MSTGM/pages/26384746422/RP+Close+mold+ticket+green
export const canCloseGreenCleaningMould = ({
  priority,
  coding,
}: CanCloseGreenCleaningMould): boolean => {
  return (
    priority === TicketPriorityEnum.Priority7 &&
    coding?.__typename === 'Coding' &&
    coding.number?.toString().endsWith('41')
  );
};
