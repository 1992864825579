import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Skeleton } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { CardWithTitleSkeleton } from '../../../components/shared/CardWithTitle';

type Props = Pick<
  ComponentProps<typeof CardWithTitleSkeleton>,
  'showTopRightButton'
>;

export const TDWidgetWithCollapsibleRowsSkeleton: FC<Props> = ({
  showTopRightButton,
}) => {
  return (
    <CardWithTitleSkeleton showTopRightButton={showTopRightButton}>
      <Grid container direction="column" spacing={1}>
        {[1, 2, 3].map((val) => {
          return (
            <Grid item key={val} container direction="row">
              <Grid item xs={10}>
                <Skeleton
                  variant="rectangular"
                  width="95%"
                  sx={{ borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={2}>
                <ExpandMoreIcon color="disabled" />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </CardWithTitleSkeleton>
  );
};
