import axios from 'axios';
import { useEffect, useState } from 'react';
import { getAreaFromStorage } from '../../contexts/area/area-and-process-context';
import { getAccessToken } from '../../msal';
import { getNodeEnvironment } from '../../utility/environment';

export const useAuthenticatedPictureSource = (
  uri: string | undefined
): string | undefined => {
  const [src, setSrc] = useState<string | undefined>();

  useEffect(() => {
    if (!uri) {
      return;
    }

    let objectURL: string | undefined;

    const fetchImage = async (url: string) => {
      const token = await getAccessToken(undefined);
      // Avoid CORS on localhost
      const fullUrl =
        getNodeEnvironment() === 'local'
          ? url.replace(/^https?:\/\/[^/]*\//g, '')
          : `${url}`;

      try {
        const response = await axios.get(fullUrl, {
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            'x-plant-id': getAreaFromStorage().plantId,
          },
          responseType: 'blob',
        });

        objectURL = URL.createObjectURL(response.data);
        setSrc(objectURL);
      } catch (error) {
        setSrc(undefined);
      }
    };

    fetchImage(uri);
    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [uri]);

  return src;
};
