/**
 * @generated SignedSource<<79d438178e62c0bb7cbdf05ec1023909>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartDetailsDialogPartInfo_sparePart$data = {
  readonly dimensions: string | null;
  readonly newSparePart: {
    readonly sparePartNumber: number;
  } | null;
  readonly procurementInfo: {
    readonly leadTime: string | null;
    readonly manufacturer: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly manufacturingPart: string | null;
    readonly price: {
      readonly currency: string;
      readonly value: number;
    } | null;
    readonly vendor: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly vendorMaterial: string | null;
  } | null;
  readonly unitOfMeasure: string;
  readonly " $fragmentSpreads": FragmentRefs<"BomSparePartRow_sparePart">;
  readonly " $fragmentType": "SparePartDetailsDialogPartInfo_sparePart";
};
export type SparePartDetailsDialogPartInfo_sparePart$key = {
  readonly " $data"?: SparePartDetailsDialogPartInfo_sparePart$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogPartInfo_sparePart">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "processId",
    "variableName": "processId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "processId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartDetailsDialogPartInfo_sparePart",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SparePart",
      "kind": "LinkedField",
      "name": "newSparePart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sparePartNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dimensions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitOfMeasure",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": (v0/*: any*/),
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "SparePartProcurementInfo",
      "kind": "LinkedField",
      "name": "procurementInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vendorMaterial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Vendor",
          "kind": "LinkedField",
          "name": "vendor",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Manufacturer",
          "kind": "LinkedField",
          "name": "manufacturer",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manufacturingPart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "price",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leadTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BomSparePartRow_sparePart"
    }
  ],
  "type": "SparePart",
  "abstractKey": null
};
})();

(node as any).hash = "b8e00af2826e193464fd05240245b8f7";

export default node;
