import {
  getNodeEnvironment,
  PossibleEnvironments,
} from '../../utility/environment';
import { useScript } from './script';

const Sources: Partial<Record<PossibleEnvironments, string>> = {
  // dev: 'https://assets.adobedtm.com/113bc1fcf650/594050d70a74/launch-6dc166e9e1d1-development.min.js',
  uat: 'https://assets.adobedtm.com/113bc1fcf650/594050d70a74/launch-c4a6334e668b-staging.min.js',
  production:
    'https://assets.adobedtm.com/113bc1fcf650/594050d70a74/launch-3417095f6faf.min.js',
};

export const useAnalytics = () => {
  useScript(Sources[getNodeEnvironment()]);
};
