import { hasValue } from '@lego/mst-error-utilities';
import { Collapse, Grid, TextField, Typography, useTheme } from '@mui/material';
import { ChangeEvent, FC, useCallback } from 'react';
import { useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { ErrorMessage } from '../../migration/components/ErrorMessage';
import { useTranslation } from '../../utility/i18n/translation';
import { ToggleButton, ToggleButtonGroup } from '../shared/ToggleButtons';

export const CTGatApproval: FC = () => {
  const { translate } = useTranslation();
  const {
    state: { gatInfo },
    dispatch,
  } = useCloseTicketContext();
  const {
    typography: { fontWeightBold },
  } = useTheme();

  const onApprovalChanged = useCallback(
    (_: any, value: boolean) => {
      dispatch({ type: 'setGATapproved', approved: value });
    },
    [dispatch]
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    dispatch({ type: 'setGATrejectionReason', reason: newValue });
  };

  const value: boolean | undefined = hasValue(gatInfo)
    ? gatInfo.approved
    : undefined;

  const defaultValue = gatInfo?.rejectionReason ?? undefined;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography fontWeight={fontWeightBold}>
          {translate('CLOSE_TICKET.GAT_STEP.GREEN_FLOW_HEADER', 'Close green')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {translate('CLOSE_TICKET.GAT_STEP.SUMMARY', 'Is the GAT approved?')}
        </Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={onApprovalChanged}
        >
          <ToggleButton value={true}>
            {translate('CLOSE_TICKET.GAT_STEP.APPROVED', 'Yes')}
          </ToggleButton>
          <ToggleButton value={false}>
            {translate('CLOSE_TICKET.GAT_STEP.NOT_APPROVED', 'No')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <Collapse in={value === false}>
          <Typography>
            {translate(
              'CLOSE_TICKET.GAT_STEP.NOT_APPROVED_HEADER',
              'Please write the reason for not approving*'
            )}
          </Typography>
          <TextField
            defaultValue={defaultValue}
            variant="outlined"
            multiline
            minRows={3}
            fullWidth
            onChange={onChange}
            inputProps={{ maxLength: 5000 }} // Sanity limit
          />
          {gatInfo?.showGATrejectionReasonMissingWarning && (
            <ErrorMessage
              message={translate(
                'CLOSE_TICKET.GAT_STEP.REASON_MISSING',
                'Reason for not approving is mandatory for this ticket type'
              )}
            />
          )}
        </Collapse>
      </Grid>
    </Grid>
  );
};
