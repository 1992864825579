/**
 * @generated SignedSource<<97895f3fea1519c74b559d78cfb512e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryList_ticket$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCard_ticket">;
  readonly " $fragmentType": "EquipmentHistoryList_ticket";
}>;
export type EquipmentHistoryList_ticket$key = ReadonlyArray<{
  readonly " $data"?: EquipmentHistoryList_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryList_ticket">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquipmentHistoryList_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryCard_ticket"
        }
      ]
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "9d5b4ac8fe2ffe45f9cba5b0ba656218";

export default node;
