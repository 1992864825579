import { Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { AddTimeProvider } from '../../contexts/add-time/add-time-context';
import { AppRoutes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { AddTimeOverview } from '../add-time/overview/AddTimeOverview';
import { TimeRegistrationOverview } from '../add-time/overview/__apollo__/TimeRegistrationOverview';
import { CardWithTitle } from '../shared/CardWithTitle';
import { TDWidgetAddButton } from './TDWidgetAddButton';

export const TDManhoursApollo: FC<TimeRegistrationOverview> = (data) => {
  const { translate } = useTranslation();
  const title = translate(
    'TICKET_DETAILS.CARDS.TIME_SPENT.TITLE',
    'Time spent'
  );

  const {
    ticket: { canAddTimeRegistration },
  } = usePermissions();

  const canAddTime = canAddTimeRegistration(data);

  const rightItem = canAddTime ? <AddButton /> : undefined;

  if (data.totalManHoursInMinutes === 0) {
    return (
      <CardWithTitle title={title} rightItem={rightItem}>
        <Typography>
          {translate(
            'TICKET_DETAILS.CARDS.TIME_SPENT.NO_REGISTRATIONS',
            'No time registrations yet'
          )}
        </Typography>
      </CardWithTitle>
    );
  }

  return (
    <CardWithTitle title={title} rightItem={rightItem}>
      <AddTimeProvider>
        <AddTimeOverview {...data} interactive={false} />
      </AddTimeProvider>
    </CardWithTitle>
  );
};

const AddButton: FC = () => {
  const { translate } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <TDWidgetAddButton
      icon={<Icons.Edit />}
      routeTo={AppRoutes.tickets.manHours(id)}
      title={translate('TICKET_DETAILS.CARDS.TIME_SPENT.BUTTON', 'Time')}
    />
  );
};
