import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { IOSSwitch } from '../components/IOSSwitch';
import { useTranslation } from '../../utility/i18n/translation';
import { useCreateTicketContext } from './create-ticket-context';
import { CreateTicketDescriptionToggles_equipment$key } from './__generated__/CreateTicketDescriptionToggles_equipment.graphql';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

interface Props {
  equipment: CreateTicketDescriptionToggles_equipment$key | null | undefined;
}

export const CreateTicketDescriptionToggles: FC<Props> = ({
  equipment: equipmentRef,
}) => {
  const { state, dispatch } = useCreateTicketContext();
  const { translate } = useTranslation();

  const equipment = useFragment(
    graphql`
      fragment CreateTicketDescriptionToggles_equipment on Equipment {
        __typename
      }
    `,
    equipmentRef ?? null
  );

  const handleRepairToggle = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch({ type: 'set_error_after_repair', payload: checked });
  };

  const handleChangeoverToggle = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch({ type: 'set_error_changeover', payload: checked });
  };

  return (
    <Grid container spacing={1} direction="column">
      {equipment?.__typename !== 'Mould' ? (
        <Grid item>
          <FormGroup style={{ alignItems: 'flex-start' }}>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <IOSSwitch
                  checked={state.errorAfterRepair}
                  onChange={handleRepairToggle}
                />
              }
              labelPlacement="start"
              label={translate(
                'CREATE_TICKET.DESCRIPTION.ERROR_REPAIR',
                'Error after repair'
              )}
            />
          </FormGroup>
        </Grid>
      ) : null}
      <Grid item>
        <FormGroup style={{ alignItems: 'flex-start' }}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={
              <IOSSwitch
                checked={state.errorDuringChangeOver}
                onChange={handleChangeoverToggle}
              />
            }
            labelPlacement="start"
            label={translate(
              'CREATE_TICKET.DESCRIPTION.ERROR_CHANGEOVER',
              'Error during change over'
            )}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
