import { hasValue } from '@lego/mst-error-utilities';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Divider,
  Grid,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, Fragment, SyntheticEvent, useState } from 'react';
import { useFragment } from 'react-relay';
import { Icons } from '../../../utility/icons';
import { skeletonify } from '../../skeleton';
import { BomSparePartRow } from './BomSparePartRow';
import { SparePartBomList_BomListNode$key } from './__generated__/SparePartBomList_BomListNode.graphql';
import { isBomOfType } from './SparePartBom';
import { decodeGlobalID } from '../../utils';

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  boxShadow: 'none',
  paddingRight: 0,
  '&.Mui-expanded': {
    marginTop: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(
  () => ({
    padding: 0,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      '&.Mui-expanded': {
        marginTop: '12px',
      },
    },
  })
);

const ActualComponent: FC<{
  id: string;
  showSeparator: boolean;
  BomListNode: SparePartBomList_BomListNode$key | null;
}> = (props) => {
  const { id, showSeparator, BomListNode: BomListNodeRef } = props;
  const [expanded, setExpanded] = useState(false);

  const data = useFragment(
    graphql`
      fragment SparePartBomList_BomListNode on BomListNode
      @argumentDefinitions(processId: { type: "ID!" })
      @relay(plural: true) {
        id
        parentId
        item {
          ... on SparePart {
            __typename
            id
            ...BomSparePartRow_sparePart @arguments(processId: $processId)
          }
          ... on BomCategory {
            __typename
            id
            description
          }
        }
      }
    `,
    BomListNodeRef
  );

  const handleChange =
    () => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded);
    };

  const filteredData = (data ?? []).filter(
    isBomOfType('BomCategory', 'SparePart')
  );
  const me = filteredData.find((x) => x.item.id === id);

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: 'relative' }}>
        {hasValue(me?.parentId) && (
          <Fragment>
            <Divider
              sx={{
                width: '18px',
                top: me?.item.__typename === 'SparePart' ? '81px' : '46px',
                left: '-20px',
                borderBottomWidth: '2px',
                zIndex: 100,
                position: 'absolute',
              }}
            />
            <Divider
              orientation="vertical"
              style={{
                borderRightWidth: '2px',
                height: '100%',
                top: '-51px',
                left: '-21px',
                position: 'absolute',
                zIndex: 0,
              }}
            />
          </Fragment>
        )}
        {showSeparator && <Divider style={{ borderBottomWidth: '2px' }} />}
        {me?.item.__typename === 'SparePart' && (
          <BomSparePartRow sparePart={me.item} />
        )}
        {me?.item.__typename === 'BomCategory' && (
          <StyledAccordion expanded={expanded} onChange={handleChange()}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                style={{
                  position: 'relative',
                  marginTop: '4px',
                  zIndex: 1,
                  backgroundColor: 'InfoBackground',
                }}
              >
                <Icons.Burger sx={{ mr: 3 }} />
              </Box>
              <Grid container direction="column">
                <Grid item>
                  <Typography sx={{ color: 'text.secondary' }}>
                    {me?.item.id}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>{me?.item.description}</Typography>
                </Grid>
              </Grid>
            </StyledAccordionSummary>
            <AccordionDetails style={{ paddingRight: 0, paddingLeft: 32 }}>
              {filteredData
                ?.filter(
                  (x) =>
                    // TODO: This is a no-go
                    x.parentId && decodeGlobalID(x.parentId).id === me?.item.id
                )
                .map((x, index) => (
                  <SparePartBomList
                    BomListNode={BomListNodeRef}
                    showSeparator={index > 0}
                    id={x.item.id}
                    key={`${x.id}:${x.parentId}:${index}`}
                  />
                ))}
            </AccordionDetails>
          </StyledAccordion>
        )}
      </Grid>
    </Grid>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={100} />;
};

export const SparePartBomList = skeletonify(
  'SparePartBomList',
  ActualComponent,
  SkeletonComponent
);
