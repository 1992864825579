import {
  Card,
  CardActionArea,
  Divider,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { AreaWithFlag } from '../../components/shared/AreaWithFlag';
import { AppRoutes } from '../../Router';
import { EquipmentLocation } from '../components/equipment/EquipmentLocation';
import { MouldCavitiesWithDimmings } from '../components/mould/MouldCavitiesWithDimmings';
import { MouldConstructionNumber } from '../components/mould/MouldConstructionNumber';
import { MouldCurrentProductionEnd } from '../components/mould/MouldCurrentProductionEnd';
import { MouldNextProductionStart } from '../components/mould/MouldNextProductionStart';
import { MouldStandardPartsPerHour } from '../components/mould/MouldStandardPartsPerHour';
import { MouldStatusChip } from '../components/mould/MouldStatusChip';
import { MouldTonSize } from '../components/mould/MouldTonSize';
import { MouldType } from '../components/mould/MouldType';
import { MouldWeight } from '../components/mould/MouldWeight';
import { skeletonify } from '../skeleton';
import { SisterMouldSpringChangeRow } from './SisterMouldSpringChangeRow';
import { SisterMouldSysCleaningRow } from './SisterMouldSysCleaningRow';
import { SisterMouldCard_mould$key } from './__generated__/SisterMouldCard_mould.graphql';

const ActualComponent: FC<{
  mould: SisterMouldCard_mould$key;
  isHighlighted?: boolean;
}> = ({ mould: mouldRef, isHighlighted = false }) => {
  const navigate = useNavigate();
  const borderColor = useTheme().palette.secondary.main;
  const data = useFragment(
    graphql`
      fragment SisterMouldCard_mould on Mould {
        ...EquipmentLocation_equipment
        ...MouldCavitiesWithDimmings_mould
        ...MouldConstructionNumber_mould
        ...MouldCurrentProductionEnd_mould
        ...MouldNextProductionStart_mould
        ...MouldStandardPartsPerHour_mould
        ...MouldStatusChip_mould
        ...MouldTonSize_mould
        ...MouldType_mould
        ...MouldWeight_mould
        ...SisterMouldSysCleaningRow_cleaning
        ...SisterMouldSpringChangeRow_springChange
        plant {
          ...AreaWithFlag_plant
        }
        equipmentNumber
      }
    `,
    mouldRef
  );

  const handleClick = () => {
    navigate(
      AppRoutes.equipment.byId(data.equipmentNumber.toString(), 'details')
    );
  };

  return (
    <Card
      sx={{
        p: 0,
        ...(isHighlighted && {
          border: 'solid 2px',
          borderColor: borderColor,
        }),
      }}
    >
      <CardActionArea onClick={handleClick} sx={{ p: 2 }}>
        <Grid container direction="column" spacing={1}>
          <Grid container item direction="row" spacing={3}>
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>
                {data.equipmentNumber}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <MouldStatusChip mould={data} />
            </Grid>
            <Grid container item justifyContent="flex-end" sm>
              <Grid item>
                <AreaWithFlag plant={data.plant} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item direction="row" spacing={2}>
            <Grid container item direction="column" xs={2} spacing={2}>
              <Grid item>
                <MouldConstructionNumber mould={data} />
              </Grid>
              <Grid item>
                <MouldWeight mould={data} />
              </Grid>
            </Grid>

            <Grid container item direction="column" xs={2.5} spacing={2}>
              <Grid item>
                <MouldType mould={data} />
              </Grid>
              <Grid item>
                <MouldTonSize mould={data} />
              </Grid>
            </Grid>

            <Grid container item direction="column" xs={2} spacing={2}>
              <Grid item>
                <MouldStandardPartsPerHour mould={data} />
              </Grid>
              <Grid item>
                <MouldCavitiesWithDimmings mould={data} />
              </Grid>
            </Grid>

            <Grid container item direction="column" xs={2.5} spacing={2}>
              <Grid item>
                <MouldCurrentProductionEnd mould={data} />
              </Grid>
              <Grid item>
                <MouldNextProductionStart mould={data} />
              </Grid>
            </Grid>

            <Grid container item direction="column" sm justifyContent="center">
              <Grid item>
                <EquipmentLocation equipment={data} />
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 2 }} />

          <Grid container item direction="column" spacing={2}>
            <Grid item p={0} width="100%">
              <SisterMouldSysCleaningRow mould={data} />
            </Grid>
            <Grid item p={0} width="100%">
              <SisterMouldSpringChangeRow mould={data} />
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="rectangular" width={100} />;
};

export const SisterMouldCard = skeletonify(
  'SisterMouldCard',
  ActualComponent,
  SkeletonComponent
);
