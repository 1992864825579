import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { GMImageWithFallback } from '../../components/shared/GMImageComponents';
import { SparePartIdWithIcon } from '../../components/ticket-card/components/SparePartIdWithIcon';
import { skeletonify } from '../skeleton';
import { SparePartsTabConsumedRow_ISparePartMovement$key } from './__generated__/SparePartsTabConsumedRow_ISparePartMovement.graphql';

const ActualComponent: FC<{
  sparePartMovement: SparePartsTabConsumedRow_ISparePartMovement$key | null;
}> = (props) => {
  const { sparePartMovement: SparePartMovementRef } = props;
  const data = useFragment(
    graphql`
      fragment SparePartsTabConsumedRow_ISparePartMovement on SparePartMovement {
        quantity
        sparePart {
          sparePartNumber
          image {
            uri
          }
          description
        }
        ... on ConsumedSparePartMovement {
          consumedFrom {
            description
            storageBin
          }
        }
      }
    `,
    SparePartMovementRef
  );
  if (!data) {
    return null;
  }
  const { quantity, sparePart, consumedFrom } = data;

  return (
    <Grid container flexDirection={'row'} item xs spacing={2}>
      <Grid container xs={2} justifyContent="center" alignItems="center">
        <GMImageWithFallback url={sparePart.image.uri} />
      </Grid>
      <Grid item container xs={9} flexDirection="column" spacing={2}>
        <Grid item>
          <SparePartIdWithIcon
            sparePartId={sparePart.sparePartNumber.toString()}
            opaque
          />
        </Grid>
        <Grid item>
          <Typography>{sparePart.description}</Typography>
        </Grid>
        {consumedFrom && (
          <Grid item>
            <Typography color={'GrayText'} variant="caption">
              {consumedFrom.description}, {consumedFrom.storageBin}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={1} alignItems="center" justifyContent={'center'}>
        <Grid item>
          <Typography variant="h3">{quantity}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SkeletonComponent: FC = () => {
  return (
    <Grid container flexDirection={'row'} item xs spacing={2}>
      <Grid container xs={2} item justifyContent="center" alignItems="center">
        <GMImageWithFallback url={''} />
      </Grid>
      <Grid item container xs={9} flexDirection="column" spacing={2}>
        <Grid item>
          <Skeleton variant="text" width={120} />
          <Grid item>
            <Skeleton variant="text" width={280} />
          </Grid>

          <Grid item>
            <Typography color={'GrayText'} variant="caption">
              <Skeleton variant="text" width={180} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={1} alignItems="center" justifyContent={'center'}>
        <Grid item>
          <Skeleton variant="text" width={40} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const SparePartsTabConsumedRow = skeletonify(
  'SparePartsTabConsumedRow',
  ActualComponent,
  SkeletonComponent
);
