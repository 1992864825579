import { Grid } from '@mui/material';
import { FC } from 'react';
import { ActivityIndicator } from './ActivityIndicator';

export const FillWidthLoading: FC = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 24 }}
    >
      <Grid item>
        <ActivityIndicator />
      </Grid>
    </Grid>
  );
};
