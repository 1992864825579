import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { addDays } from 'date-fns';
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  TicketPriorityEnum,
  UpdateTicketPriorityInput,
} from '../../../__apollo__/types';
import { useGMMutation } from '../../../apollo/customApolloHooks';
import { formatDateForMiddleware } from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { useGMSnackbar } from '../../../utility/snackbar';
import { GMDatePicker } from '../../shared/GMDatePicker';
import { SimpleDialog } from '../../shared/SimpleDialog';
import { TicketDetailsFabFragment } from '../__apollo__/TicketDetailsFabFragment';
import { useChangePriorityField } from './ChangePriorityFieldHook';
import {
  ALLOWED_TICKET_PRIORITIES,
  ChangePriorityDialogField,
  TicketTypeEnum,
} from './ChangePriorityUtils';
import {
  UpdateTicketPriority,
  UpdateTicketPriorityVariables,
} from './__apollo__/UpdateTicketPriority';

const CHANGE_TICKET_PRIORITY_MUTATION = gql`
  mutation UpdateTicketPriority($input: UpdateTicketPriorityInput!) {
    updateTicketPriority(input: $input) {
      ... on Ticket {
        id
        priority
        requiredEndDate
      }
    }
  }
`;

const DAYS_AHEAD = 14;

export const ChangePriorityDialog: FC<{
  open: boolean;
  closeDialog: () => void;
  data: TicketDetailsFabFragment;
  onUpdate?: () => void;
  ticketType: TicketTypeEnum;
}> = ({ data, open, closeDialog, onUpdate, ticketType }) => {
  const { translate } = useTranslation();

  const closeDialogWrapped = useCallback(() => {
    setPriority(data.priority);
    closeDialog();
  }, [closeDialog, data.priority]);

  const { onSavePressed, loading } = useChangePriorityMutation(
    closeDialogWrapped,
    onUpdate
  );

  const defaultDate = useMemo(() => addDays(new Date(), DAYS_AHEAD), []);

  const [requiredEndDate, setRequiredEndDate] = useState<Date>(defaultDate);

  const [priority, setPriority] = useState<TicketPriorityEnum>(data.priority);

  const onSave = useCallback(() => {
    if (hasValue(priority)) {
      onSavePressed({
        priority,
        ticketId: data.id,
        requiredEndDate: formatDateForMiddleware(requiredEndDate),
      });
    }
  }, [data.id, onSavePressed, priority, requiredEndDate]);

  const createPriorityField = useChangePriorityField();

  const onPriorityChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value) {
      setPriority(value as TicketPriorityEnum);
    }
  };

  const onDateChange = (date: Date | null) => {
    if (date) {
      setRequiredEndDate(date);
    }
  };

  return (
    <SimpleDialog
      open={open}
      title={translate('TICKET_DETAILS.FAB.CHANGE_PRIORITY', 'Change priority')}
      content={{
        type: 'node',
        node: (
          <Content
            onPriorityChange={onPriorityChange}
            onChangeDate={onDateChange}
            dateFieldValue={requiredEndDate}
            priorityFieldValue={priority}
            allowedPriorities={ALLOWED_TICKET_PRIORITIES[ticketType]
              .map((priority) => createPriorityField(priority))
              .filter(hasValue)}
          />
        ),
      }}
      onDismiss={closeDialogWrapped}
      primaryAction={{
        primaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.CHANGE_PRIORITY_DIALOG.SAVE_BUTTON',
          'SAVE'
        ),
        primaryActionPressed: onSave,
        primaryActionLoading: loading,
      }}
      secondaryAction={{
        secondaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.CHANGE_PRIORITY_DIALOG.CANCEL_BUTTON',
          'Cancel'
        ),
        secondaryActionPressed: closeDialogWrapped,
      }}
      maxWidth="xs"
    />
  );
};

type DialogContentProps = {
  onPriorityChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  onChangeDate: (date: Date | null) => void;
  priorityFieldValue: TicketPriorityEnum | undefined;
  dateFieldValue: Date | undefined;
  allowedPriorities: ChangePriorityDialogField[];
};

const Content: FC<DialogContentProps> = ({
  onPriorityChange,
  onChangeDate,
  priorityFieldValue,
  dateFieldValue,
  allowedPriorities,
}) => {
  const shouldRequireEndDate = useMemo(
    () =>
      priorityFieldValue === TicketPriorityEnum.Priority4 ||
      priorityFieldValue === TicketPriorityEnum.Priority8,
    [priorityFieldValue]
  );

  return (
    <Fragment>
      <FormControl>
        <RadioGroup value={priorityFieldValue} onChange={onPriorityChange}>
          {allowedPriorities.map((priority: ChangePriorityDialogField) => (
            <FormControlLabel
              key={priority.name}
              value={priority.value}
              control={<Radio />}
              label={priority.translation}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {shouldRequireEndDate && (
        <GMDatePicker
          title="Required end date"
          value={dateFieldValue}
          onChange={onChangeDate}
          showOptional={false}
        />
      )}
    </Fragment>
  );
};

const useChangePriorityMutation = (
  closeDialog: () => void,
  onUpdate?: () => void
) => {
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();

  const failureString = translate(
    'SCREENS.CHANGE_PRIORITY.FAILURE',
    'Failed to change priority, please try again'
  );

  const [save, { loading, error }] = useGMMutation<
    UpdateTicketPriority,
    UpdateTicketPriorityVariables
  >(CHANGE_TICKET_PRIORITY_MUTATION, {
    onCompleted: (data) => {
      if (data.updateTicketPriority.__typename === 'Ticket') {
        showSnack({
          message: translate(
            'SCREENS.CHANGE_PRIORITY.SUCCESS',
            'Priority changed successfully'
          ),
          variant: 'success',
        });
        closeDialog();

        onUpdate?.();
      } else {
        showSnack({ message: failureString, variant: 'error' });
      }
    },
  });

  const onSavePressed = useCallback(
    (input: UpdateTicketPriorityInput) => {
      save({ variables: { input } });
    },
    [save]
  );

  return { onSavePressed, loading, error };
};
