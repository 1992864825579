import { hasValue } from '@lego/mst-error-utilities';
import { Chip, Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { skeletonify } from '../../skeleton';
import { useCloseFlowStatusTranslation } from './CloseFlowStatusTranslationHook';
import {
  MouldCloseFlowStatusChip_mould$key,
  MouldFlowType,
} from './__generated__/MouldCloseFlowStatusChip_mould.graphql';

const ActualComponent: FC<{
  mould: MouldCloseFlowStatusChip_mould$key | null;
}> = (props) => {
  const { mould: equipmentRef } = props;
  const mould = useFragment(
    graphql`
      fragment MouldCloseFlowStatusChip_mould on Mould {
        closeFlowStatus {
          status
        }
      }
    `,
    equipmentRef
  );

  const closeFlowStatus = mould?.closeFlowStatus?.status;
  const translateCloseFlowStatus = useCloseFlowStatusTranslation();
  const validTicketFlowTypes: MouldFlowType[] = [
    'GatTest',
    'Green',
    'Normal',
    'YellowToMPEE',
    'YellowToMPEERejected',
    'YellowToProduction',
    'YellowToProductionRejected',
    'YellowToQA',
    'YellowToQAApproved',
    'YellowToQARejected',
  ];

  if (
    !hasValue(closeFlowStatus) ||
    validTicketFlowTypes.every((flowType) => flowType !== closeFlowStatus)
  ) {
    return null;
  }

  return (
    <Chip color="warning" label={translateCloseFlowStatus(closeFlowStatus)} />
  );
};

const SkeletonComponent: FC = () => (
  <Skeleton>
    <Chip sx={{ width: 100 }} />
  </Skeleton>
);

export const MouldCloseFlowStatusChip = skeletonify(
  'MouldCloseFlowStatusChip',
  ActualComponent,
  SkeletonComponent
);
