import { gql } from '@apollo/client';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useCallback, FC, SyntheticEvent } from 'react';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { useAreaAndProcessContext } from '../../contexts/area';
import { useTicketListSearchContext } from '../../contexts/ticket-list-search/ticket-list-search-context';
import { useTranslation } from '../../utility/i18n/translation';
import {
  FilterListLocationOptions,
  FilterListLocationOptionsVariables,
} from './__apollo__/FilterListLocationOptions';

const LOCATIONS_QUERY = gql`
  query FilterListLocationOptions(
    $locationInput: AllLocationsForPlantInput!
    $sublocationInput: SublocationsForProcessInput!
  ) {
    allLocationsForPlant(input: $locationInput) {
      id
      description
    }
    allSublocationsForPlant(input: $sublocationInput) {
      id
      description
    }
  }
`;

export type TicketListLocationFilterOption = {
  id: string;
  description: string;
};

export const TicketListLocationFilter: FC = () => {
  const { translate } = useTranslation();
  const {
    dispatch,
    state: { locationFilter, subLocationFilter },
  } = useTicketListSearchContext();
  const {
    selectedProcessId,
    selectedArea: { plantId },
  } = useAreaAndProcessContext();

  const onLocationSelected = useCallback(
    (value: TicketListLocationFilterOption | null) => {
      // setSelectedLocation(value);
      dispatch({ type: 'setLocationFilter', filter: value });
    },
    [dispatch]
  );

  const onSubLocationSelected = useCallback(
    (value: TicketListLocationFilterOption | null) => {
      dispatch({ type: 'setSubLocationFilter', filter: value });
    },
    [dispatch]
  );

  const { data, loading } = useGMQuery<
    FilterListLocationOptions,
    FilterListLocationOptionsVariables
  >(LOCATIONS_QUERY, {
    variables: {
      locationInput: { plantId },
      sublocationInput: { processId: selectedProcessId ?? '' },
    },
  });

  if (loading || !data) {
    return null;
  }

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('TICKET_LIST.FILTERS.LOCATION.TITLE', 'Hall / section')}
        </Typography>
        <ListAutocomplete
          options={data.allLocationsForPlant}
          value={locationFilter}
          onChange={onLocationSelected}
        />
      </Grid>
      {data?.allSublocationsForPlant &&
        data.allSublocationsForPlant.length > 0 && (
          <Grid item>
            <Typography variant="subtitle2">
              {translate(
                'TICKET_LIST.FILTERS.SUB_LOCATION.TITLE',
                'Room (Row / place)'
              )}
            </Typography>
            <ListAutocomplete
              options={data.allSublocationsForPlant}
              value={subLocationFilter}
              onChange={onSubLocationSelected}
            />
          </Grid>
        )}
    </Grid>
  );
};

type AutoCompleteProps = {
  options: TicketListLocationFilterOption[];
  value: TicketListLocationFilterOption | null;
  onChange: (value: TicketListLocationFilterOption | null) => void;
};

const ListAutocomplete: FC<AutoCompleteProps> = (props) => {
  const localOnChange = (
    _: SyntheticEvent<Element, Event>,
    value: TicketListLocationFilterOption | null
  ) => {
    props.onChange(value);
  };

  const labelForOption = (option: TicketListLocationFilterOption) => {
    return `${option.id}, ${option.description}`;
  };

  return (
    <Autocomplete<TicketListLocationFilterOption>
      {...props}
      onChange={localOnChange}
      disablePortal
      getOptionLabel={labelForOption}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {labelForOption(option)}
          </li>
        );
      }}
      fullWidth
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
