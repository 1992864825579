import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import  clamp  from 'lodash/clamp';
import { ChangeEvent, FC, useCallback } from 'react';
import { InputTitle } from '../../components/shared/GMDatePicker';
import { useTranslation } from '../../utility/i18n/translation';

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 499; // Maximum amount should be <500

export const CmsTicketQuantity: FC<{
  value: number | null;
  onChange: (value: number | null) => void;
}> = ({ value, onChange }) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      onChange(clamp(value, MIN_QUANTITY, MAX_QUANTITY));
    } else {
      onChange(null);
    }
  };

  const onClearIconClicked = useCallback(() => {
    onChange(null);
  }, [onChange]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <InputTitle
          title={translate('CREATE_CMS_TICKET.QUANTITY', 'Quantity')}
          showOptionalLabel={false}
          titleVariant="body2"
        />
      </Grid>
      <Grid container item direction="row">
        <Grid item xs={3}>
          <TextField
            type="number"
            value={value ?? ''}
            onChange={handleOnChange}
            fullWidth
            inputProps={{ min: MIN_QUANTITY, max: MAX_QUANTITY }}
            InputProps={{
              endAdornment: (
                <Button
                  disableRipple
                  onClick={onClearIconClicked}
                  sx={{ p: 0, minWidth: 0 }}
                >
                  <InputAdornment position="end">
                    <CloseIcon fill={palette.text.disabled} />
                  </InputAdornment>
                </Button>
              ),
            }}
            className="hide-number-input-arrows"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
