import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { TicketListLocationFilterOption } from '../../components/ticket-list/TicketListLocationFilter';
import { TicketStatus } from '../../__apollo__/types';
import {
  TicketListSearchActions,
  ticketListSearchReducer,
} from './ticket-list-search-reducer';
import { TicketFilterFragment } from './__apollo__/TicketFilterFragment';

export const TICKET_FILTER = gql`
  fragment TicketFilterFragment on TicketFilter {
    type
    count
  }
`;

export const FILTER_COUNTS = gql`
  fragment FilterCountsFragment on QueryTicketList_Connection {
    statusCounts {
      ...TicketFilterFragment
    }
    priorityCounts {
      ...TicketFilterFragment
    }
  }
  ${TICKET_FILTER}
`;

export interface TicketListSearchState {
  priorities: string[];
  searchTerm: string;
  ticketStatuses: Set<TicketStatus>;
  totalSearchResults?: number;
  locationFilter: TicketListLocationFilterOption | null;
  subLocationFilter: TicketListLocationFilterOption | null;
  filterCounts: {
    status: TicketFilterFragment[];
    priority: TicketFilterFragment[];
  };
}

export interface TicketListSearchContext {
  state: TicketListSearchState;
  dispatch: Dispatch<TicketListSearchActions>;
}

export const initialTicketListSearchState: TicketListSearchState = {
  priorities: [],
  searchTerm: '',
  ticketStatuses: new Set(),
  locationFilter: null,
  subLocationFilter: null,
  filterCounts: {
    status: [],
    priority: [],
  },
};

const Context = createContext<TicketListSearchContext | undefined>(undefined);

export const useTicketListSearchContext = (): TicketListSearchContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing TicketListSearchProvider');
  }

  return context;
};

const localStorageKey = 'MMS.TicketSearch';

const isValidState = (
  state: TicketListSearchState
): state is TicketListSearchState => {
  if (!hasValue(state) || typeof state !== 'object') {
    return false;
  }

  return (
    Array.isArray(state.priorities) &&
    state.ticketStatuses instanceof Set &&
    hasValue(state.filterCounts) &&
    hasValue(state.searchTerm)
  );
};

const getInitialState = (): TicketListSearchState => {
  const storageItem = localStorage.getItem(localStorageKey);

  if (!hasValue(storageItem)) {
    return initialTicketListSearchState;
  }
  const stateInStorage = JSON.parse(storageItem) as TicketListSearchState;
  const stateInStorageWithSet = {
    ...stateInStorage,
    ticketStatuses: new Set(stateInStorage.ticketStatuses),
  };

  return isValidState(stateInStorageWithSet)
    ? stateInStorageWithSet
    : initialTicketListSearchState;
};

export const TicketListSearchProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    ticketListSearchReducer,
    getInitialState()
  );

  useEffect(() => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...state,
        ticketStatuses: [...state.ticketStatuses],
        searchTerm: '',
      })
    );
  }, [state]);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
