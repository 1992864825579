import { Button, Container, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { AppRoutes } from '../Router';
import { Figures } from '../utility/figures';
import { useTranslation } from '../utility/i18n/translation';

export const LoginContainer: FC = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { logIn, authenticated } = useAuthContext();

  useEffect(() => {
    if (authenticated) {
      navigate(AppRoutes.root, { replace: true });
    }
  }, [authenticated, navigate]);

  return (
    <Container>
      <Grid
        container
        direction="column"
        style={{ width: '100%' }}
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Figures.SpaceMan style={{ fontSize: 300 }} />
        </Grid>
        <Grid item>
          <Typography>
            {translate(
              'LOGIN.SIGN_IN_REQUIRED',
              'You must sign in to use the MaintainIt app'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={logIn} data-cy="SignIn">
            {translate('LOGIN.SIGN_IN_BUTTON', 'Sign In')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
