import { hasValue } from '@lego/mst-error-utilities';
import { FC, useState } from 'react';
import { CloseTicketState } from '../../contexts/close-ticket/close-ticket-context';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { UpdateLocationFragment_equipment_EquipmentValue_value_subLocation } from '../close-ticket/__apollo__/UpdateLocationFragment';
import {
  EquipmentLocationSection,
  useUpdateEquipmentLocationMutations,
} from '../shared/EquipmentLocationSection';
import { SimpleDialog } from '../shared/SimpleDialog';
import { EquipmentLocationFragment } from '../shared/__apollo__/EquipmentLocationFragment';

export type LocationUpdate = CloseTicketState['location'];

type ChangeLocationComponentProps = {
  data: EquipmentLocationFragment;
  currentLocationUpdateState?: LocationUpdate;
  saveLocationCallback: (newLocationInput: LocationUpdate) => void;
};
export const ChangeLocationComponent: FC<ChangeLocationComponentProps> = ({
  data,
  saveLocationCallback,
  currentLocationUpdateState,
}) => {
  const onSublocationTextChanged = (newValue: string) => {
    saveLocationCallback({
      ...currentLocationUpdateState,
      newSublocationText: newValue,
      sublocationInputValid: isEquipmentSublocationUpdateValid({
        isSublocationMandatory: data.sublocationMetaInfo.isSublocationMandatory,
        sublocationData: data.subLocation,
        newSublocationText: newValue,
        sublocationDirty: true,
      }),
      sublocationDirty: true,
    });
  };

  const onSublocationIdChanged = (newValue: string) => {
    saveLocationCallback({
      ...currentLocationUpdateState,
      newSublocationId: newValue,
      sublocationInputValid: true,
      sublocationDirty: true,
    });
  };

  const onLocationIdChanged = (newValue: string) => {
    saveLocationCallback({
      ...currentLocationUpdateState,
      newLocationId: newValue,
      sublocationInputValid: data.sublocationMetaInfo.isSublocationMandatory
        ? false
        : true,
      sublocationDirty: true,
      newSublocationText: '',
    });
  };

  return (
    <EquipmentLocationSection
      {...data}
      onLocationSelected={onLocationIdChanged}
      onSublocationTextUpdated={onSublocationTextChanged}
      onSublocationSelected={onSublocationIdChanged}
      currentLocationUpdateState={currentLocationUpdateState}
    />
  );
};

export const TDUpdateLocationDialog: FC<{
  data: EquipmentLocationFragment;
  open: boolean;
  onDismiss: () => void;
}> = ({ open, onDismiss, data }) => {
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const { loading, updateEquipmentLocationData } =
    useUpdateEquipmentLocationMutations(data.id);
  const [locationUpdate, setLocationUpdate] = useState<LocationUpdate>();

  const onSavePressed = async () => {
    if (hasValue(locationUpdate) && locationUpdate.sublocationInputValid) {
      await updateEquipmentLocationData({
        ...locationUpdate,
        sublocationInputValid: locationUpdate.sublocationInputValid,
      });
      onDismiss();
    } else {
      showSnack({
        message: translate(
          'TICKET_DETAILS.CARDS.UPDATE_DIALOG.INPUT_INVALID_SNACK',
          'Input not valid'
        ),
        variant: 'warning',
      });
    }
  };

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={open}
      title={translate(
        'TICKET_DETAILS.CARDS.UPDATE_LOCATION_DIALOG.TITLE',
        'Change Location'
      )}
      secondaryAction={{
        secondaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.UPDATE_LOCATION_DIALOG.CANCEL_BUTTON',
          'Cancel'
        ),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      primaryAction={{
        primaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.UPDATE_LOCATION_DIALOG.SAVE_BUTTON',
          'Save'
        ),
        primaryActionPressed: onSavePressed,
        primaryActionLoading: loading,
      }}
      content={{
        type: 'node',
        node: (
          <ChangeLocationComponent
            currentLocationUpdateState={locationUpdate}
            data={data}
            saveLocationCallback={setLocationUpdate}
          />
        ),
      }}
      maxWidth={'lg'}
    />
  );
};

export const isEquipmentSublocationUpdateValid = ({
  isSublocationMandatory,
  sublocationData,
  newSublocationText,
  sublocationDirty,
}: {
  isSublocationMandatory: boolean;
  sublocationData: UpdateLocationFragment_equipment_EquipmentValue_value_subLocation | null;
  sublocationDirty: boolean;
  newSublocationText?: string;
}): boolean => {
  if (!isSublocationMandatory) {
    return true;
  }

  if (sublocationDirty) {
    return (newSublocationText?.length ?? 0) > 0;
  }

  const emptyInLocalState =
    !hasValue(newSublocationText) || newSublocationText.length === 0;

  if (hasValue(sublocationData) && emptyInLocalState) {
    return true;
  }

  return !emptyInLocalState;
};
