import { Container } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useMemo } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';
import { useRouteRootType } from '../../Router';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { skeletonify } from '../skeleton';
import { OpenTicketsTabList } from './OpenTicketsTabList';
import OpenTicketsTabContainerQuery, {
  OpenTicketsTabContainerQuery as OpenTicketsTabContainerQueryType,
  OpenTicketsTabContainerQuery$variables,
} from './__generated__/OpenTicketsTabContainerQuery.graphql';

const ActualComponent: FC<{
  query: PreloadedQuery<OpenTicketsTabContainerQueryType>;
}> = (props) => {
  const { query: queryRef } = props;
  const data = usePreloadedQuery(
    graphql`
      query OpenTicketsTabContainerQuery(
        $ticketInput: QueryTicketInput!
        $equipmentInput: QueryEquipmentInput!
        $includeEquipment: Boolean!
        $includeTicket: Boolean!
      ) {
        equipment(input: $equipmentInput) @include(if: $includeEquipment) {
          ... on QueryEquipmentSuccess {
            data {
              ...OpenTicketsTabList_equipment
            }
          }
        }
        ticket(input: $ticketInput) @include(if: $includeTicket) {
          ... on QueryTicketSuccess {
            data {
              equipment {
                ...OpenTicketsTabList_equipment
              }
            }
          }
        }
      }
    `,
    queryRef
  );

  const equipment = data.ticket?.data?.equipment ?? data.equipment?.data;
  return <OpenTicketsTabList.Suspense equipment={equipment} />;
};

const SkeletonComponent: FC = () => <OpenTicketsTabList.Skeleton />;

const OpenTicketsTabContainerPage = skeletonify(
  'OpenTicketsTabContainerPage',
  ActualComponent,
  SkeletonComponent
);

export const OpenTicketsTabContainer: FC = () => {
  const { id } = useParams() as { id: string };
  const routeRootType = useRouteRootType();
  const [queryRef, loadQuery] =
    useQueryLoader<OpenTicketsTabContainerQueryType>(
      OpenTicketsTabContainerQuery
    );

  const queryInput: OpenTicketsTabContainerQuery$variables = useMemo(() => {
    return {
      equipmentInput: { equipmentNumber: Number.parseInt(id) },
      ticketInput: { ticketNumber: Number.parseInt(id) },
      includeEquipment: routeRootType === 'equipment',
      includeTicket: routeRootType === 'ticket',
    };
  }, [id, routeRootType]);

  useEffect(
    () => loadQuery(queryInput),
    [id, loadQuery, queryInput, routeRootType]
  );

  return (
    <Container maxWidth="lg">
      <PageErrorBoundary onReset={() => loadQuery(queryInput)}>
        {queryRef ? (
          <OpenTicketsTabContainerPage.Suspense query={queryRef} />
        ) : (
          <OpenTicketsTabContainerPage.Skeleton />
        )}
      </PageErrorBoundary>
    </Container>
  );
};
