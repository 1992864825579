import { Button, Card, Grid, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from '../../utility/i18n/translation';
import { GMAvatar } from '../shared/GMImageComponents';
import { PictureWithSignOutVariantProps } from '../shared/PictureAndSignOut';

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '14vw',
  height: '14vw',
  [theme.breakpoints.down('lg')]: {
    width: '30vw',
    height: '30vw',
  },
}));

export const ProfilePictureAndSignOutCard: FC<
  PictureWithSignOutVariantProps
> = ({ me, onSignOutClicked }) => {
  const {
    firstName,
    lastName,
    profilePicture: { large: profilePicture },
  } = me;
  const { translate } = useTranslation();

  return (
    <Card>
      <Grid container alignItems="center" direction="column">
        <Grid item container justifyContent="center" alignItems="center">
          <StyledGrid item>
            <GMAvatar {...me} pictureUri={profilePicture} />
          </StyledGrid>
        </Grid>
        <Grid item style={{ marginTop: 48 }}>
          <Typography variant="h3">
            {firstName} {lastName}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          <Button onClick={onSignOutClicked} style={{ textTransform: 'none' }}>
            <Typography color="secondary">
              {translate(
                'PROFILE.PICTURE_AND_SIGN_OUT.SIGN_OUT_BUTTON',
                'Sign out'
              )}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
