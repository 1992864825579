import { gql } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Icons } from '../../../utility/icons';
import { LocationFragment } from './__apollo__/LocationFragment';

export const TICKET_CARD_LOCATION_FRAGMENT = gql`
  fragment LocationFragment on IEquipment {
    id
    location {
      ... on Location {
        id
        description
      }
    }
    subLocation {
      ... on SubLocation {
        id
        description
      }
      ... on SubLocationText {
        id
        value
      }
    }
  }
`;

export const EquipmentLocation: FC<LocationFragment> = ({
  location,
  subLocation,
}) => {
  const locationValue =
    location?.__typename === 'Location' ? location.description : '';

  const sublocationValue = useMemo(() => {
    switch (subLocation?.__typename) {
      case 'SubLocation':
        return subLocation.description;
      case 'SubLocationText':
        return subLocation.value;
      default:
        return '';
    }
  }, [subLocation]);

  return (
    <Grid container flexDirection="row">
      <Grid item>
        <Icons.Location color={'disabled'} fontSize="small" />
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {locationValue}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {sublocationValue}
        </Typography>
      </Grid>
    </Grid>
  );
};
