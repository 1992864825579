import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, styled } from '@mui/material';
import { ReactElement } from 'react';
import Div100vh from 'react-div-100vh';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MuiNavbar } from './components';
import { Banner } from './components/shared/banner/Banner';
import { BannerProvider } from './components/shared/banner/banner-context';
import { AreaProvider, RequireArea } from './contexts/area';
import { AuthContextProvider } from './contexts/AuthContext';
import { GQLProvider } from './contexts/GQLProvider';
import { UserPreferencesProvider } from './contexts/UserPreferencesContext';
import { HallMonitorSetupContainer } from './migration/hall-monitor/hall-monitor-setup/HallMonitorSetupContainer';
import { HallMonitorContainer } from './migration/hall-monitor/hall-monitor/HallMonitorContainer';
import { useAnalytics } from './migration/hooks/analytics';
import { MicrosoftADInstance } from './msal/config';
import { MainRouter } from './Router';
import { getCrashReporter } from './utility/crash-reporter';
import { initI18n } from './utility/i18n/i18n';
import { DatePickerLocalizationProvider } from './utility/i18n/translation';
import { UserPermissionsProvider } from './utility/permissions/permission-context';

initI18n();

const Div100vhContainer = styled(Div100vh)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const MainContent = styled('main')(() => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));

export const ImageCache = new Map<string, string>();

getCrashReporter().start();

export const App = (): ReactElement => {
  useAnalytics();

  return (
    <MsalProvider instance={MicrosoftADInstance}>
      <CssBaseline />
      <AuthContextProvider>
        <Div100vhContainer>
          <Router>
            <Routes>
              <Route
                path={'/hall-monitors'}
                element={<HallMonitorSetupContainer />}
              />
              <Route
                path={'/hall-monitors/:id'}
                element={<HallMonitorContainer />}
              />
              <Route path={'/auth-callback'} element={null} />
              <Route
                path="*"
                element={
                  <AreaProvider>
                    <GQLProvider>
                      <RequireArea>
                        <UserPreferencesProvider>
                          <UserPermissionsProvider>
                            <DatePickerLocalizationProvider>
                              <BannerProvider>
                                <MuiNavbar />
                                <Banner />
                                <MainContent data-cy="AppMainContent">
                                  <MainRouter />
                                </MainContent>
                              </BannerProvider>
                            </DatePickerLocalizationProvider>
                          </UserPermissionsProvider>
                        </UserPreferencesProvider>
                      </RequireArea>
                    </GQLProvider>
                  </AreaProvider>
                }
              />
            </Routes>
          </Router>
        </Div100vhContainer>
      </AuthContextProvider>
    </MsalProvider>
  );
};
