/**
 * @generated SignedSource<<7b4604403a26442a9501113e38483651>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentDetailsHeaderFragment$data = {
  readonly __typename: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentDescription_equipment" | "EquipmentGroupChip_equipment" | "EquipmentNumber_equipment">;
  readonly " $fragmentType": "EquipmentDetailsHeaderFragment";
};
export type EquipmentDetailsHeaderFragment$key = {
  readonly " $data"?: EquipmentDetailsHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentDetailsHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentDetailsHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentNumber_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentDescription_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentGroupChip_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "e82ac5af403e6dc2c9d65fb6c419b3ef";

export default node;
