import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { ReactElement } from 'react';
import { useFragment } from 'react-relay';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { TD_CURRENT_LOCATION } from '../../components/ticket-details/TDCurrentLocation';
import { ManufacturerWidget } from '../components/equipment/ManufacturerWidget';
import { WarrantyWidget } from '../components/equipment/WarrantyWidget';
import { skeletonify } from '../skeleton';
import {
  DetailsPaneWrapper,
  FullWidthGrid,
} from '../ticket-details/TicketDetailsPane';
import {
  TDCurrentLocation,
  TDCurrentLocationSkeleton,
} from '../ticket-details/widgets/TDCurrentLocation';
import { TDDimmingDetails } from '../ticket-details/widgets/TDDimmingDetails';
import { TDMouldDetails } from '../ticket-details/widgets/TDMouldDetails';
import { TDMouldStatus } from '../ticket-details/widgets/TDMouldStatus';
import { TDSpringChange } from '../ticket-details/widgets/TDSpringChange';
import { TDSysCleaning } from '../ticket-details/widgets/TDSysCleaning';
import {
  GetEquipmentDetails,
  GetEquipmentDetailsVariables,
  GetEquipmentDetails_equipment_EquipmentValue_value,
} from './__apollo__/GetEquipmentDetails';
import { EquipmentDetailsPane_equipment$key } from './__generated__/EquipmentDetailsPane_equipment.graphql';

export const EQUIPMENT_DETAILS_QUERY = gql`
  query GetEquipmentDetails($input: EquipmentByIdInput!) {
    equipment(input: $input) {
      ... on EquipmentValue {
        value {
          id
          ...TicketDetailsLocation
        }
      }
    }
  }
  ${TD_CURRENT_LOCATION}
`;

const ActualComponent = ({
  equipment: equipmentRef,
}: {
  equipment?: EquipmentDetailsPane_equipment$key;
}) => {
  const equipment = useFragment(
    graphql`
      fragment EquipmentDetailsPane_equipment on Equipment {
        equipmentNumber
        __typename
        ...ManufacturerWidget_equipment @defer
        ...WarrantyWidget_equipment @defer
        ...TDMouldDetails_equipment @defer
        ...TDMouldStatus_equipment @defer
        ...TDDimmingDetails_mould @defer
        ...TDSysCleaning_mould @defer
        ...TDSpringChange_mould @defer
        ... on Mould {
          springChangeInterval
          cleaningInterval
        }
      }
    `,
    equipmentRef ?? null
  );

  const equipmentNumber = equipment?.equipmentNumber?.toString();

  return {
    manufacturer: <ManufacturerWidget equipment={equipment} />,
    warranty: <WarrantyWidget equipment={equipment} />,
    mouldDetails:
      equipment?.__typename === 'Mould' ? (
        <TDMouldDetails equipment={equipment} />
      ) : undefined,
    mouldStatus:
      equipment?.__typename === 'Mould' ? (
        <TDMouldStatus equipment={equipment} />
      ) : undefined,
    dimmingDetails:
      equipment?.__typename === 'Mould' ? (
        <TDDimmingDetails mould={equipment} />
      ) : undefined,
    currentLocation: hasValue(equipmentNumber) ? (
      <TDCurrentLocation equipmentNumber={equipmentNumber} />
    ) : undefined,
    sysCleaning:
      equipment?.__typename === 'Mould' && equipment?.cleaningInterval !== 0 ? (
        <TDSysCleaning equipment={equipment} />
      ) : undefined,
    springChange:
      equipment?.__typename === 'Mould' &&
      equipment?.springChangeInterval !== 0 ? (
        <TDSpringChange equipment={equipment} />
      ) : undefined,
  };
};

const SkeletonComponent = () => ({
  manufacturer: <ManufacturerWidget.Skeleton />,
  warranty: <WarrantyWidget.Skeleton />,
  currentLocation: <TDCurrentLocationSkeleton />,
  mouldDetails: <TDMouldDetails.Skeleton />,
  mouldStatus: <TDMouldStatus.Skeleton />,
  dimmingDetails: <TDDimmingDetails.Skeleton />,
});

const StructureComponent = (props: {
  manufacturer: ReactElement;
  warranty: ReactElement;
  currentLocation?: ReactElement;
  mouldDetails?: ReactElement;
  mouldStatus?: ReactElement;
  dimmingDetails?: ReactElement;
  sysCleaning?: ReactElement;
  springChange?: ReactElement;
}) => {
  const {
    manufacturer,
    warranty,
    currentLocation,
    mouldDetails,
    mouldStatus,
    dimmingDetails,
    sysCleaning,
    springChange,
  } = props;
  return (
    <DetailsPaneWrapper>
      <Grid
        container
        direction="column"
        spacing={2}
        data-cy="EquipmentDetailsContainer"
      >
        {mouldDetails && <FullWidthGrid>{mouldDetails}</FullWidthGrid>}
        {mouldStatus && <FullWidthGrid>{mouldStatus}</FullWidthGrid>}
        {sysCleaning && <FullWidthGrid>{sysCleaning}</FullWidthGrid>}
        {springChange && <FullWidthGrid>{springChange}</FullWidthGrid>}
        {manufacturer}
        {warranty}
        {currentLocation && <FullWidthGrid>{currentLocation}</FullWidthGrid>}
        {dimmingDetails && <FullWidthGrid>{dimmingDetails}</FullWidthGrid>}
      </Grid>
    </DetailsPaneWrapper>
  );
};

export const EquipmentDetailsPane = skeletonify(
  'EquipmentDetailsPane',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

export const useApolloEquipmentDetailsQuery = (
  id?: string
): GetEquipmentDetails_equipment_EquipmentValue_value | undefined => {
  const { data } = useGMQuery<
    GetEquipmentDetails,
    GetEquipmentDetailsVariables
  >(EQUIPMENT_DETAILS_QUERY, {
    skip: !hasValue(id),
    variables: { input: { id: id ?? '' } },
  });

  return data?.equipment.__typename === 'EquipmentValue'
    ? data.equipment.value
    : undefined;
};
