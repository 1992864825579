/**
 * @generated SignedSource<<e337a20635c3e0b490169020d50f7827>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketCard_ticket$data = {
  readonly equipment: {
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentDescription_equipment" | "EquipmentLocation_equipment" | "EquipmentNumber_equipment" | "MouldCloseFlowStatusChip_mould">;
  } | null;
  readonly ticketNumber: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorDescription_ticket" | "PriorityPill_ticket" | "RequiredDates_ticket" | "TicketAssignStatus_ticket" | "TicketCard_Chips_ticket" | "TicketNumber_ticket">;
  readonly " $fragmentType": "TicketCard_ticket";
};
export type TicketCard_ticket$key = {
  readonly " $data"?: TicketCard_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketCard_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketCard_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentNumber_equipment"
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EquipmentLocation_equipment"
            }
          ]
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentDescription_equipment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MouldCloseFlowStatusChip_mould"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketNumber_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriorityPill_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ErrorDescription_ticket"
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TicketCard_Chips_ticket"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequiredDates_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketAssignStatus_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "572c313693b95e83ff5301d54f4bd8ee";

export default node;
