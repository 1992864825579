import {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  useContext,
  useReducer,
} from 'react';
import { MouldCleaningState } from '../../components/sister-moulds-and-cleaning/MouldCleaningContext';
import { CloseMouldTicketFlow } from '../../__apollo__/types';
import { CloseTicketActions, closeTicketReducer } from './close-ticket-reducer';

export type CloseTicketCleaningUpdateStateSlice = Pick<
  MouldCleaningState,
  'mouldId' | 'cleaningIntervalUpdate'
>;

export interface CloseTicketState {
  flowVariant: 'equipment' | 'mould' | 'cms';
  flowType?: CloseMouldTicketFlow;
  showRepairDocsRequiredWarning: boolean;
  showManHoursRequiredWarning: boolean;
  showMachineHoursRequiredWarning: boolean;
  showCleaningWarning: boolean;
  showSpringWarning: boolean;
  cleaningRequired: boolean;
  didCleanMould?: boolean;
  didSpringChange?: boolean;
  springStepEnabled: boolean;
  springInterval: number;
  springIntervalDirty: boolean;
  closeTicketLoading: boolean;
  manHoursValid: boolean;
  machineHoursValid: boolean;

  cleaningState?: CloseTicketCleaningUpdateStateSlice;

  gatInfo?: {
    approved?: boolean;
    rejectionReason?: string;
    showGATrejectionReasonMissingWarning?: boolean;
  };
  location: {
    newLocationId?: string;
    newSublocationId?: string;
    newSublocationText?: string;
    sublocationDirty?: boolean;
    sublocationInputValid: boolean;
  };
}

export interface CloseTicketContext {
  state: CloseTicketState;
  dispatch: Dispatch<CloseTicketActions>;
}

export const initialCloseTicketState: CloseTicketState = {
  flowVariant: 'mould',
  flowType: undefined,
  showRepairDocsRequiredWarning: false,
  showManHoursRequiredWarning: false,
  showMachineHoursRequiredWarning: false,
  showCleaningWarning: false,
  showSpringWarning: false,
  cleaningRequired: false,
  springStepEnabled: false,
  springInterval: 0,
  springIntervalDirty: false,
  closeTicketLoading: false,
  manHoursValid: false,
  machineHoursValid: false,
  location: {
    sublocationInputValid: false,
  },
};

const Context = createContext<CloseTicketContext | undefined>(undefined);

export const useCloseTicketContext = (): CloseTicketContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing CloseTicketProvider');
  }

  return context;
};

export const CloseTicketProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    closeTicketReducer,
    initialCloseTicketState
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
