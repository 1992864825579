import { hasValue } from '@lego/mst-error-utilities';
import { Box, Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback } from 'react';
import { usePaginationFragment } from 'react-relay';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { ExtractNodeFromConnection } from '../../utility-types';
import { useTranslation } from '../../utility/i18n/translation';
import { DocumentRow } from '../components/document/DocumentRow';
import { InfiniteList } from '../components/InfiniteList';
import { skeletonify } from '../skeleton';
import {
  EquipmentDocumentList_equipment$data,
  EquipmentDocumentList_equipment$key,
} from './__generated__/EquipmentDocumentList_equipment.graphql';

type Item = ExtractNodeFromConnection<
  EquipmentDocumentList_equipment$data['documents']
>;

const ActualComponent: FC<{
  data?: EquipmentDocumentList_equipment$key | null;
}> = (props) => {
  const { translate } = useTranslation();
  const { data: dataRef } = props;
  const { data, ...pagination } = usePaginationFragment(
    graphql`
      fragment EquipmentDocumentList_equipment on Equipment
      @refetchable(queryName: "DocumentListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 50 }
        after: { type: "ID" }
        documents: { type: "EquipmentDocumentsInput!" }
      ) {
        documents(first: $first, after: $after, input: $documents)
          @connection(key: "DocumentList_documents") {
          edges {
            node {
              id
              ...DocumentRow_document
            }
          }
        }
      }
    `,
    dataRef ?? null
  );

  const filtered = data?.documents?.edges
    .filter(hasValue)
    .map(({ node }) => node);

  const emptyLabel = translate(
    'EQUIPMENT_DOCUMENT_LIST.EMPTY',
    'No documents found'
  );

  const errorLabel = translate(
    'EQUIPMENT_DOCUMENT_LIST.ERROR',
    'Error fetching documents, please refresh to try again.'
  );

  const itemKeyExtractor = useCallback((item: Item) => item.id, []);
  const itemRender = useCallback(
    (item: Item) => (
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          p: 0,
        }}
      >
        <DocumentRow document={item} />
      </Box>
    ),
    []
  );

  return (
    <InfiniteList
      {...pagination}
      emptyLabel={emptyLabel}
      errorLabel={errorLabel}
      items={filtered}
      itemKeyExtractor={itemKeyExtractor}
      itemRender={itemRender}
      itemSpacing={0}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid item xs alignSelf="center">
      <ActivityIndicator />
    </Grid>
  </Grid>
);

export const EquipmentDocumentList = skeletonify(
  'EquipmentDocumentList',
  ActualComponent,
  SkeletonComponent
);
