import { Button, Container, Divider, Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactElement, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { PageErrorBoundary } from '../PageErrorBoundary';
import { skeletonify } from '../skeleton';
import { DashboardHeader } from './DashboardHeader';
import OperatorDashboardQuery, {
  OperatorDashboardQuery as OperatorDashboardQueryType,
} from './__generated__/OperatorDashboardQuery.graphql';

type Props = {
  query: PreloadedQuery<OperatorDashboardQueryType>;
};
const ActualComponent: FC<Props> = ({ query: queryRef }) => {
  const { translate } = useTranslation();

  const { viewer } = usePreloadedQuery(
    graphql`
      query OperatorDashboardQuery {
        viewer {
          ...DashboardHeader_viewer
        }
      }
    `,
    queryRef
  );

  return (
    <>
      <DashboardHeader viewer={viewer} />
      <Divider />
      <Grid container justifyContent="center">
        <Grid item mt={6}>
          <Button
            variant="contained"
            component={NavLink}
            to={AppRoutes.createTicket()}
          >
            {translate('OPERATOR_VIEW.BUTTON', 'Create new ticket')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const SkeletonComponent = () => <DashboardHeader.Skeleton />;

const OperatorDashboardPage = skeletonify(
  'OperatorDashboardPage',
  ActualComponent,
  SkeletonComponent
);

export const OperatorDashboard = (): ReactElement => {
  const [queryRef, loadQuery] = useQueryLoader<OperatorDashboardQueryType>(
    OperatorDashboardQuery
  );

  useEffect(
    () => loadQuery({}, { fetchPolicy: 'store-and-network' }),
    [loadQuery]
  );

  return (
    <Container maxWidth="lg" style={{ marginTop: 24 }}>
      <PageErrorBoundary onReset={() => loadQuery({})}>
        {queryRef ? (
          <OperatorDashboardPage.Suspense query={queryRef} />
        ) : (
          <OperatorDashboardPage.Skeleton />
        )}
      </PageErrorBoundary>
    </Container>
  );
};
