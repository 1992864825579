import { Dialog, Divider, Grid } from '@mui/material';
import { FC } from 'react';
import { ConfidentialityDetailsAccessLog } from '../components/confidentiality-details/ConfidentialityDetailsAccessLog';
import { ConfidentialityDetailsCard } from '../components/confidentiality-details/ConfidentialityDetailsCard';
import { ConfidentialitySearchRow } from '../components/image-search/__apollo__/ConfidentialitySearchRow';

export const ConfidentialityDetailsDialog: FC<{
  open: boolean;
  onClosePressed: () => void;
  data?: ConfidentialitySearchRow;
}> = ({ open, data, onClosePressed }) => {
  return (
    <Dialog open={open} maxWidth="xl" fullWidth onClose={onClosePressed}>
      <Grid item xs>
        {data && (
          <ConfidentialityDetailsCard
            data={data}
            onClosePressed={onClosePressed}
          />
        )}
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid item xs sx={{ mt: 4 }}>
        {data && <ConfidentialityDetailsAccessLog id={data.id} />}
      </Grid>
    </Dialog>
  );
};
