import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { FC, ReactNode, useCallback } from 'react';
import * as i18next from 'react-i18next';
import { useUserPreferencesContext } from '../../contexts/UserPreferencesContext';
import { Locale, localeToDateFnsLocale } from './i18n';

export type TranslateFunction = (
  key: string | string[],
  defaultValue: string | undefined,
  options?: Record<string, any>
) => string;

type UseTranslationResult = {
  translate: TranslateFunction;
  changeLanguage(lng: Locale): void;
  locale: Locale;
};

export const useTranslation = (): UseTranslationResult => {
  const { t: translate, i18n } = i18next.useTranslation();

  const { setLocalePreference } = useUserPreferencesContext();

  const changeLanguage = useCallback(
    (lng: Locale): void => {
      i18n.changeLanguage(lng);
      setLocalePreference(lng);
    },
    [i18n, setLocalePreference]
  );

  return {
    translate,
    changeLanguage,
    locale: i18n.language as Locale,
  };
};

export const DatePickerLocalizationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { locale } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeToDateFnsLocale(locale)}
    >
      {children}
    </LocalizationProvider>
  );
};
