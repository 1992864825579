import { Chip, Grid, useTheme } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { useAddRepairDescriptionContext } from '../../../contexts/add-repair-description/add-repair-description-context';
import { REPAIR_DESCRIPTION_ACTIVITIES } from '../../../contexts/add-repair-description/add-repair-description-gql-queries';
import {
  RepairDescriptionActivities,
  RepairDescriptionActivitiesVariables,
} from '../../../contexts/add-repair-description/__apollo__/RepairDescriptionActivities';
import { ARDNavigationButtons } from '../ARDNavigationButtons';

export const ARDChooseActivities: FC = () => {
  const {
    palette: {
      primary: { main: primaryColor },
    },
  } = useTheme();
  const { id: ticketId } = useParams() as { id: string };
  const {
    state: { chosenActivities, currentStep },
    dispatch,
  } = useAddRepairDescriptionContext();

  const { data } = useGMQuery<
    RepairDescriptionActivities,
    RepairDescriptionActivitiesVariables
  >(REPAIR_DESCRIPTION_ACTIVITIES, {
    variables: { input: { ticketId } },
  });

  if (!(currentStep === 'activities') && chosenActivities.length > 0) {
    return (
      <Grid container item spacing={2}>
        {chosenActivities.map((activity) => {
          return (
            <Grid item key={activity.id}>
              <Chip
                label={activity.description}
                style={{
                  color: 'white',
                  backgroundColor: primaryColor,
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} data-cy="ARDChooseActivities-active">
      <Grid container item spacing={3}>
        {data?.repairDescriptionActivityOptions.map((activity) => {
          const activitySelected =
            chosenActivities?.some((item) => activity.id === item.id) ?? false;

          const onClick = () => {
            dispatch({ type: 'activity_selected', activity });
          };

          return (
            <Grid item key={activity.id}>
              <Chip
                label={activity.description}
                onClick={onClick}
                variant={activitySelected ? 'filled' : 'outlined'}
                style={{
                  color: activitySelected ? 'white' : primaryColor,
                  backgroundColor: activitySelected ? primaryColor : undefined,
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item>
        <ARDNavigationButtons />
      </Grid>
    </Grid>
  );
};
