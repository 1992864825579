import { hasValue } from '@lego/mst-error-utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, Fragment, Suspense, useCallback, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { NavLink } from 'react-router-dom';
import { FillWidthLoading } from '../../../components/shared/FillWidthLoading';
import { useAuthContext } from '../../../contexts/AuthContext';
import { withRelayEnvironment } from '../../../relay/RelayEnvironment';
import { AppRoutes } from '../../../Router';
import { useTranslation } from '../../../utility/i18n/translation';
import { HallMonitorCreateDialog } from './HallMonitorCreateDialog';
import { HallMonitorSetupDeleteDialog } from './HallMonitorSetupDeleteDialog';
import { HallMonitorSetupContainerProcessQuery } from './__generated__/HallMonitorSetupContainerProcessQuery.graphql';
import { HallMonitorSetupContainerQuery } from './__generated__/HallMonitorSetupContainerQuery.graphql';

export const HallMonitorSetupContainer: FC = withRelayEnvironment(
  'none',
  () => {
    const { translate } = useTranslation();

    const [selectedProcessId, setSelectedProcessId] = useState<
      string | undefined
    >();
    return (
      <Container>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Grid container flexDirection="row">
            <Grid item container flexDirection="column" xs={6}>
              <Grid item sx={{ ml: 2 }}>
                <Typography variant="subtitle1">
                  {translate(
                    'HALL_MONITOR.PROCESS_LIST_HEADER',
                    'Choose process'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Suspense fallback={<FillWidthLoading />}>
                  <ProcessListContainer
                    setSelectedProcessId={setSelectedProcessId}
                    selectedProcessId={selectedProcessId}
                  />
                </Suspense>
              </Grid>
            </Grid>
            <Grid item container xs={6} flexDirection="column">
              <Grid item sx={{ ml: 2 }}>
                <Typography variant="subtitle1">
                  {translate(
                    'HALL_MONITOR.VARIANT_LIST_HEADER',
                    'Choose variant'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                {selectedProcessId && (
                  <Suspense fallback={<FillWidthLoading />}>
                    <HallVariantListContainer
                      selectedProcessId={selectedProcessId}
                    />
                  </Suspense>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
);

const HallVariantListContainer: FC<{ selectedProcessId: string }> = ({
  selectedProcessId,
}) => {
  const { isSuperUser } = useAuthContext();
  const { translate } = useTranslation();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const closeDialog = useCallback(() => {
    setIdToDelete(undefined);
  }, []);

  const data = useLazyLoadQuery<HallMonitorSetupContainerQuery>(
    graphql`
      query HallMonitorSetupContainerQuery($nodeId: ID!) {
        node(id: $nodeId) {
          __typename
          ... on Process {
            hallMonitorScreens {
              id
              title
            }
          }
        }
      }
    `,
    {
      nodeId: selectedProcessId,
    }
  );

  if (data.node?.__typename !== 'Process' || !data.node.hallMonitorScreens) {
    return (
      <ListItem>
        <Typography>
          {translate(
            'HALL_MONITOR.NO_VARIANTS_FOR_PROCESS',
            'No variants for the selected process'
          )}
        </Typography>
      </ListItem>
    );
  }

  return (
    <Grid container minHeight={400} flexDirection="column">
      {selectedProcessId && isSuperUser && (
        <HallMonitorCreateDialog
          closeDialog={() => setCreateDialogOpen(false)}
          open={createDialogOpen}
          processId={selectedProcessId}
        />
      )}
      <Grid item sx={{ my: 2, px: 2 }}>
        {selectedProcessId && isSuperUser && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setCreateDialogOpen(true)}
          >
            {translate(
              'HALL_MONITOR.CREATE_VARIANT_BUTTON',
              'Create new variant for selected process'
            )}
          </Button>
        )}
      </Grid>
      <Grid item container height="45vh">
        <HallMonitorSetupDeleteDialog
          closeDialog={closeDialog}
          idToDelete={idToDelete}
        />
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            maxHeight: '45vh',
            overflow: 'scroll',
          }}
        >
          {data.node.hallMonitorScreens?.filter(hasValue).map((val) => {
            const onDeleteClicked = () => {
              setIdToDelete(val.id);
            };

            return (
              <ListItem
                key={val.id}
                secondaryAction={
                  isSuperUser ? (
                    <IconButton edge="end" onClick={onDeleteClicked}>
                      <DeleteIcon />
                    </IconButton>
                  ) : undefined
                }
              >
                <ListItemButton
                  component={NavLink}
                  role={undefined}
                  dense
                  to={AppRoutes.hallMonitorWithId(val.id)}
                >
                  <ListItemText id={val.id} primary={val.title} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

const ProcessListContainer: FC<{
  selectedProcessId?: string;
  setSelectedProcessId: (id?: string) => void;
}> = ({ selectedProcessId, setSelectedProcessId }) => {
  const data = useLazyLoadQuery<HallMonitorSetupContainerProcessQuery>(
    graphql`
      query HallMonitorSetupContainerProcessQuery {
        plants {
          id
          name
          processes {
            id
            name
          }
        }
      }
    `,
    {}
  );

  return (
    <Grid container height="50vh">
      <Grid item sx={{ width: '100%' }}>
        <List
          sx={{
            bgcolor: 'background.paper',
            maxHeight: '50vh',
            overflow: 'scroll',
            pt: 0,
          }}
        >
          {data.plants?.filter(hasValue).map((plant) => {
            return (
              <Fragment key={plant.id}>
                <ListSubheader>{plant.name}</ListSubheader>
                {plant.processes?.filter(hasValue).map((process) => {
                  const onRowClicked = () => {
                    if (selectedProcessId === process.id) {
                      setSelectedProcessId(undefined);
                    } else {
                      setSelectedProcessId(process.id);
                    }
                  };

                  return (
                    <ListItem
                      key={process.id}
                      selected={process.id === selectedProcessId}
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={onRowClicked}
                        dense
                      >
                        <ListItemText id={process.id} primary={process.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Fragment>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
