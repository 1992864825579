import { hasValue } from '@lego/mst-error-utilities';
import { Card, Container, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactNode, useMemo, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router';
import { ActivityIndicator } from '../../../components/shared/ActivityIndicator';
import { useRouteRootType } from '../../../Router';
import { useTranslation } from '../../../utility/i18n/translation';
import { PageErrorBoundary } from '../../PageErrorBoundary';
import { skeletonify } from '../../skeleton';
import {
  SparePartSearchProvider,
  useSparePartSearchContext,
} from './spare-part-search-context';
import { SparePartBomHeader } from './SparePartBomHeader';
import { SparePartBomList } from './SparePartBomList';
import { SparePartSearch } from './SparePartSearch';
import SparePartBomQuery, {
  SparePartBomQuery as SparePartBomQueryType,
} from './__generated__/SparePartBomQuery.graphql';

export const isBomOfType =
  <Type extends string[]>(...args: Type) =>
  <T extends { item: { __typename: string } }>(
    obj: T
  ): obj is T & { item: { __typename: Type[number] } } =>
    args.includes(obj.item.__typename);

type Props = {
  query: PreloadedQuery<SparePartBomQueryType>;
};

const ActualComponent = (props: Props) => {
  const { query: queryRef } = props;
  const { translate } = useTranslation();
  const {
    state: { searchTerm, filterOnStock },
  } = useSparePartSearchContext();

  const { equipment } = usePreloadedQuery(
    graphql`
      query SparePartBomQuery($equipmentNumber: Int!, $processId: ID!) {
        equipment(input: { equipmentNumber: $equipmentNumber }) {
          __typename
          ... on QueryEquipmentSuccess {
            data {
              sparePartBom @required(action: THROW) {
                id
                parentId
                item {
                  ... on SparePart {
                    __typename
                    id
                    procurementInfo(input: { processId: $processId }) {
                      inStock
                    }
                    description
                    model
                    ean
                  }
                  ... on BomCategory {
                    __typename
                    id
                  }
                }
                ...SparePartBomList_BomListNode
                  @arguments(processId: $processId)
              }
            }
          }
        }
      }
    `,
    queryRef
  );
  const nodes = useMemo(() => {
    return (
      equipment.__typename === 'QueryEquipmentSuccess'
        ? equipment.data.sparePartBom
        : []
    )
      .filter(isBomOfType('SparePart', 'BomCategory'))
      .filter(
        (x) =>
          !(
            filterOnStock &&
            x.item.__typename === 'SparePart' &&
            !x.item.procurementInfo?.inStock
          )
      );
  }, [equipment, filterOnStock]);

  const rootLevel = useMemo(() => {
    return nodes.filter((x) => {
      if (hasValue(searchTerm) && searchTerm.length > 0) {
        const item = x.item;

        if (item.__typename === 'SparePart') {
          const lowercase = searchTerm.toLowerCase();
          return (
            x.id.toLowerCase().includes(lowercase) ||
            item.description.toLowerCase().includes(lowercase) ||
            item.model?.toLowerCase().includes(lowercase) ||
            item.ean?.toString().toLowerCase().includes(lowercase)
          );
        }
        return false;
      } else {
        return x.parentId === null;
      }
    });
  }, [nodes, searchTerm]);

  if (equipment.__typename !== 'QueryEquipmentSuccess') {
    throw new Error('failed to fetch bill of material');
  }

  const list = (
    <Card>
      <Grid container sx={{ padding: 2.5 }} flexDirection="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {translate('SPARE_PART_BOM.CARD.HEADER', 'Bill of material')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ overflow: 'hidden' }}>
          {rootLevel.map((x, index) => (
            <SparePartBomList
              BomListNode={nodes}
              showSeparator={index > 0}
              id={x.item.id}
              key={`${x.id}:${x.parentId}`}
            />
          ))}
        </Grid>
      </Grid>
    </Card>
  );

  return {
    list,
  };
};

const SkeletonComponent = {
  list: (
    <Grid container direction="column" spacing={2}>
      <Grid item xs alignSelf="center">
        <ActivityIndicator />
      </Grid>
    </Grid>
  ),
};

const StructureComponent = ({ list }: { list: ReactNode }) => (
  <Grid container direction="column">
    <Grid item sx={{ mx: 0.5 }}>
      {list}
    </Grid>
  </Grid>
);

const SparePartBom = skeletonify(
  'SparePartBom',
  ActualComponent,
  () => SkeletonComponent,
  StructureComponent
);

export const SparePartBomTab: FC<{
  marginTop?: number;
  processId: string;
  equipmentNumber?: number;
}> = ({ marginTop, processId, equipmentNumber }) => {
  const { id } = useParams() as { id: string };
  const routeRootType = useRouteRootType();

  const [queryRef, loadQuery] =
    useQueryLoader<SparePartBomQueryType>(SparePartBomQuery);
  useEffect(() => {
    loadQuery({
      equipmentNumber: equipmentNumber ?? Number.parseInt(id),
      processId: processId,
    });
  }, [equipmentNumber, id, loadQuery, processId, routeRootType]);

  return (
    <SparePartSearchProvider>
      <Container maxWidth="xl" sx={{ mt: marginTop }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }} alignSelf="center">
            <SparePartBomHeader />
          </Grid>
        </Grid>
        <PageErrorBoundary>
          {queryRef ? (
            <SparePartBom.Suspense query={queryRef} />
          ) : (
            <SparePartBom.Skeleton />
          )}
          <SparePartSearch />
        </PageErrorBoundary>
      </Container>
    </SparePartSearchProvider>
  );
};
