import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Box, Card, Container, Grid, styled } from '@mui/material';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from '../components/add-time/overview/time-registration-gql-fragments';
import { CTChooseFlow } from '../components/close-ticket/CTChooseFlow';
import { CTCMSStepper } from '../components/close-ticket/CTCMSStepper';
import { CTGatApproval } from '../components/close-ticket/CTgatApproval';
import { CTHeader } from '../components/close-ticket/CTHeader';
import { CTMachineHours } from '../components/close-ticket/CTMachineHours';
import { CTManhours } from '../components/close-ticket/CTManhours';
import { InnerBanner } from '../components/shared/banner/InnerBanner';
import {
  CLOSE_MOULD_FRAGMENT,
  CTMouldCleaning,
} from '../components/close-ticket/CTMouldCleaning';
import {
  CLOSE_MOULD_SPRING_FRAGMENT,
  CTMouldSpringChange,
} from '../components/close-ticket/CTMouldSpringChange';
import {
  CTRepairDocumentation,
  REPAIR_DOCUMENTATION_ACCORDION,
} from '../components/close-ticket/CTRepairDocumentation';
import { CTStepper } from '../components/close-ticket/CTStepper';
import {
  CTUpdateLocation,
  UPDATE_LOCATION_FRAGMENT,
} from '../components/close-ticket/CTUpdateLocation';
import {
  LOCATIONS_QUERY,
  SUBLOCATION_QUERY,
} from '../components/shared/EquipmentLocationSection';
import {
  GetEquipmentLocations,
  GetEquipmentLocationsVariables,
} from '../components/shared/__apollo__/GetEquipmentLocations';
import {
  GetEquipmentSublocations,
  GetEquipmentSublocationsVariables,
} from '../components/shared/__apollo__/GetEquipmentSublocations';
import { MOULD_CLEANING_CONTAINER_FRAGMENT } from '../components/sister-moulds-and-cleaning/MouldCleaningContainer';
import { MouldCleaningProvider } from '../components/sister-moulds-and-cleaning/MouldCleaningContext';
import { TDHeader } from '../components/ticket-details/TDHeader';
import { AddMachineHoursProvider } from '../contexts/add-machine-hours/add-machine-hours-context';
import { AddTimeProvider } from '../contexts/add-time/add-time-context';
import {
  CloseTicketProvider,
  useCloseTicketContext,
} from '../contexts/close-ticket/close-ticket-context';
import { RouteRequiringPermission, RouteTypes } from '../Router';
import {
  canCloseGreenCleaningMould,
  CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT,
} from '../utility/canCloseGreenCleaningMould';
import { PERMISSIONS_ALLOWED_TO_MANAGE_TICKET } from '../utility/permissions/ticket';
import { usePermissions } from '../utility/permissions/usePermissions';
import { SubLocationTypeEnum } from '../__apollo__/types';
import { useTicketQueryTypeCheck } from './TicketByIdTypecheckHook';
import {
  GetDataForCloseTicketFlow,
  GetDataForCloseTicketFlowVariables,
  GetDataForCloseTicketFlow_ticket_Ticket,
  GetDataForCloseTicketFlow_ticket_Ticket_equipment_EquipmentValue_value,
} from './__apollo__/GetDataForCloseTicketFlow';
import { ConfirmDialogProvider } from '../components/shared/ConfirmDialog';

export const CLOSE_TICKET_FLOW_QUERY = gql`
  query GetDataForCloseTicketFlow($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        orderId
        priority
        underGAT
        isCmsTicket
        manHoursRequired
        machineHoursRequired
        equipment {
          ... on EquipmentValue {
            value {
              id
              ... on Mould {
                ...MouldCleaningUpdate
                springChangeInterval
              }
            }
          }
        }
        ...RepairDocumentationAccordion
        ...TimeRegistrationOverview
        ...CleaningInfo
        ...UpdateLocationFragment
        ...IsAllowedToManageTicket
        ...CanCloseGreenCleaningMould
        ...SpringChangeInfo
      }
    }
  }
  ${REPAIR_DOCUMENTATION_ACCORDION}
  ${MOULD_CLEANING_CONTAINER_FRAGMENT}
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
  ${CLOSE_MOULD_FRAGMENT}
  ${CLOSE_MOULD_SPRING_FRAGMENT}
  ${UPDATE_LOCATION_FRAGMENT}
  ${PERMISSIONS_ALLOWED_TO_MANAGE_TICKET}
  ${CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT}
`;

export const CloseTicketContainer: FC = () => {
  const { id } = useParams() as RouteTypes['close'];

  const client = useApolloClient();

  const { data, loading } = useQuery<
    GetDataForCloseTicketFlow,
    GetDataForCloseTicketFlowVariables
  >(CLOSE_TICKET_FLOW_QUERY, {
    variables: { input: { id } },
  });

  const {
    ticket: { canCloseTicket },
  } = usePermissions();

  const checked =
    useTicketQueryTypeCheck<GetDataForCloseTicketFlow_ticket_Ticket>({
      data,
      loading,
    });

  useEffect(() => {
    // Prefetch location lists
    if (
      checked.type === 'data' &&
      checked.data.equipment?.__typename === 'EquipmentValue'
    ) {
      const equipmentId = checked.data.equipment.value.id;
      client.query<GetEquipmentLocations, GetEquipmentLocationsVariables>({
        query: LOCATIONS_QUERY,
        variables: { input: { equipmentId } },
      });

      if (
        checked.data.equipment.value.sublocationMetaInfo.sublocationType ===
        SubLocationTypeEnum.SubLocation
      ) {
        client.query<
          GetEquipmentSublocations,
          GetEquipmentSublocationsVariables
        >({
          query: SUBLOCATION_QUERY,
          variables: { input: { equipmentId } },
        });
      }
    }
  }, [checked, client]);

  const { cleaningInterval, equipmentId } = useMemo(() => {
    const equipment:
      | GetDataForCloseTicketFlow_ticket_Ticket_equipment_EquipmentValue_value
      | undefined =
      checked.type === 'data' &&
      checked.data.equipment?.__typename === 'EquipmentValue'
        ? checked.data.equipment.value
        : undefined;

    const cleaningInterval =
      equipment?.__typename === 'Mould' ? equipment.cleaningInterval : null;

    return { equipmentId: equipment?.id, cleaningInterval };
  }, [checked]);

  if (checked.type === 'errorOrLoadingComponent') {
    return checked.component;
  }

  const allowed = canCloseTicket(checked.data);

  return (
    <RouteRequiringPermission allowed={allowed}>
      <CloseTicketProvider>
        <AddMachineHoursProvider>
          <AddTimeProvider>
            <MouldCleaningProvider
              mouldId={equipmentId ?? ''}
              mouldCurrentCleaningInterval={cleaningInterval}
            >
              <Fragment>
                <TDHeader {...checked.data} showFab={false} />
                <InnerComponent data={checked.data} />
              </Fragment>
            </MouldCleaningProvider>
          </AddTimeProvider>
        </AddMachineHoursProvider>
      </CloseTicketProvider>
    </RouteRequiringPermission>
  );
};

const StyledCard = styled(Card)(() => ({
  paddingTop: 30,
  paddingBottom: 30,
  paddingLeft: 44,
  paddingRight: 44,
}));

const InnerComponent: FC<{
  data: GetDataForCloseTicketFlow_ticket_Ticket;
}> = ({ data }) => {
  const { step } = useParams() as RouteTypes['close'];
  const { dispatch } = useCloseTicketContext();

  useEffect(() => {
    if (data.isCmsTicket) {
      dispatch({
        type: 'setFlowVariant',
        variant: 'cms',
      });
    } else if (data.equipment?.__typename === 'EquipmentValue') {
      const variant =
        data.equipment.value.__typename === 'Equipment' ? 'equipment' : 'mould';

      dispatch({
        type: 'setFlowVariant',
        variant,
      });

      const isPreventiveCleaning = canCloseGreenCleaningMould(data);
      if (isPreventiveCleaning) {
        dispatch({ type: 'setCleaningRequired', required: true });
      }

      if (
        data.equipment.value.__typename === 'Mould' &&
        data.equipment.value.springChangeInterval &&
        data.equipment.value.springChangeInterval > 0
      ) {
        dispatch({ type: 'setSpringChangeStepEnabled', payload: true });
      }
    }
  }, [data, data.equipment, data.priority, dispatch]);

  const component = useMemo(() => {
    switch (step) {
      case 'repair':
        return <CTRepairDocumentation {...data} />;
      case 'manhours':
        return <CTManhours {...data} />;
      case 'cleaning':
        return <CTMouldCleaning {...data} />;
      case 'spring':
        return <CTMouldSpringChange {...data} />;
      case 'location':
        return <CTUpdateLocation {...data} />;
      case 'machine-hours':
        return <CTMachineHours {...data} />;
      case 'flow':
        return (
          <CTChooseFlow
            ticketNumber={data.orderId ? Number.parseInt(data.orderId) : null}
          />
        );
      case 'gat':
        return <CTGatApproval />;
    }
  }, [data, step]);

  return (
    <Fragment>
      <Grid style={{ marginTop: 10 }} container>
        <InnerBanner />
      </Grid>
      <Container
        maxWidth="lg"
        style={{
          marginTop: 10,
          marginBottom: 100,
        }}
      >
        <StyledCard>
          <Grid container direction="column">
            <Grid item>
              <CTHeader />
            </Grid>
            <Grid item>{component}</Grid>
          </Grid>
        </StyledCard>
      </Container>
      <Box style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        {data.isCmsTicket ? (
          <CTCMSStepper {...data} />
        ) : (
          <ConfirmDialogProvider>
            <CTStepper {...data} />
          </ConfirmDialogProvider>
        )}
      </Box>
    </Fragment>
  );
};
