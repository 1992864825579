/**
 * @generated SignedSource<<63990657595271e7a6d6234838743e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketDetailsPane_ticket$data = {
  readonly __typename: string;
  readonly equipment: {
    readonly __typename: string;
    readonly cleaningInterval?: number | null;
    readonly equipmentNumber: number;
    readonly springChangeInterval?: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"ManufacturerWidget_equipment" | "TDDimmingDetails_mould" | "TDMouldDetails_equipment" | "TDMouldStatus_equipment" | "TDSpringChange_mould" | "TDSysCleaning_mould" | "WarrantyWidget_equipment">;
  } | null;
  readonly ticketNumber: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"TDCreatedBy_ticket">;
  readonly " $fragmentType": "TicketDetailsPane_ticket";
};
export type TicketDetailsPane_ticket$key = {
  readonly " $data"?: TicketDetailsPane_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketDetailsPane_ticket">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketDetailsPane_ticket",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equipmentNumber",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TDMouldDetails_equipment"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TDMouldStatus_equipment"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TDDimmingDetails_mould"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ManufacturerWidget_equipment"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WarrantyWidget_equipment"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TDSysCleaning_mould"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TDSpringChange_mould"
            }
          ]
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "springChangeInterval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cleaningInterval",
              "storageKey": null
            }
          ],
          "type": "Mould",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TDCreatedBy_ticket"
        }
      ]
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};
})();

(node as any).hash = "1ebe0d5f10032bfdab70af9734ba4e1a";

export default node;
