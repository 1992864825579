import graphql from 'babel-plugin-relay/macro';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { Card, Grid, Typography } from '@mui/material';
import SparePartSearchQuery, {
  SparePartSearchQuery as SparePartSearchQueryType,
} from './__generated__/SparePartSearchQuery.graphql';
import { FC, ReactElement, useEffect } from 'react';
import { ActivityIndicator } from '../../../components/shared/ActivityIndicator';
import { skeletonify } from '../../skeleton';
import { useSparePartSearchContext } from './spare-part-search-context';
import { useAreaAndProcessContext } from '../../../contexts/area';
import { SparePartSearchList } from './SparePartSearchList';
import { useTranslation } from '../../../utility/i18n/translation';
import { Figures } from '../../../utility/figures';
import { useParams } from 'react-router-dom';

const ActualComponent = (props: {
  query: PreloadedQuery<SparePartSearchQueryType>;
}) => {
  const { query: queryRef } = props;
  const { node } = usePreloadedQuery(
    graphql`
      query SparePartSearchQuery(
        $input: ProcessSparePartSearchInput!
        $processId: ID!
      ) {
        node(id: $processId) {
          ... on Process {
            ...SparePartSearchList_process
              @defer
              @arguments(input: $input, processId: $processId)
          }
        }
      }
    `,
    queryRef
  );

  const list = node ? (
    <SparePartSearchList.Suspense query={node} />
  ) : (
    <SparePartSearchList.Skeleton />
  );

  return {
    list,
  };
};

const SkeletonComponent = () => ({
  list: (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Grid item>
        <ActivityIndicator />
      </Grid>
    </Grid>
  ),
});

const StructureComponent = ({ list }: { list: ReactElement }) => {
  const { translate } = useTranslation();
  return (
    <Grid container direction="column" sx={{ mt: 2 }}>
      <Grid item sx={{ mx: 0.5 }}>
        <Card>
          <Grid container sx={{ padding: 2.5 }} flexDirection="row">
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {translate('SPARE_PART_SEARCH.HEADER', 'Spare parts')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {list}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export const SparePartSearchLoader = skeletonify(
  'SparePartSearchLoader',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);

// search term must be at least 3 characters long or contain chinese characters
const shouldSearch = (searchTerm: string) =>
  searchTerm.length >= 3 || /[\u4E00-\u9FFF]/.test(searchTerm);

export const SparePartSearch: FC = () => {
  const [queryRef, loadQuery] =
    useQueryLoader<SparePartSearchQueryType>(SparePartSearchQuery);
  const { id } = useParams() as { id: string };
  const { relayProcessId: processId } = useAreaAndProcessContext();
  const {
    state: { searchTerm },
  } = useSparePartSearchContext();

  useEffect(() => {
    if (!processId) {
      return;
    }

    if (!shouldSearch(searchTerm || '')) {
      return;
    }

    return loadQuery(
      { input: { searchTerm }, processId },
      {
        fetchPolicy: 'store-and-network',
      }
    );
  }, [loadQuery, processId, searchTerm]);

  if (!id && !shouldSearch(searchTerm || '')) {
    return <NoQuery />;
  }
  return queryRef && searchTerm && searchTerm.length > 0 ? (
    <SparePartSearchLoader.Suspense query={queryRef} />
  ) : null;
};

const NoQuery: FC = () => {
  const { translate } = useTranslation();
  return (
    <Grid container direction="column" spacing={2} alignItems="center">
      <Grid item>
        <Typography fontWeight={700} align="center">
          {translate(
            'SPAREPART_LIST.SEARCH_SUBTITLE',
            'Please search to see list of spare parts'
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Figures.SearchingUpwards />
      </Grid>
    </Grid>
  );
};
