import {
  CrashReporter,
  redactValues,
  SentryBreadcrumb,
  SentryContext,
  SentryEvent,
  SentryOptions,
  SentryPrimitive,
} from '@lego/mst-error-utilities';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getNodeEnvironment } from './environment';

export const API_TIMEOUT_THRESHOLD_IN_MS = 30000;

class SentryCrashReporter implements CrashReporter {
  private static sharedInstance: SentryCrashReporter | undefined = undefined;

  static get sharedReporter(): SentryCrashReporter {
    if (this.sharedInstance === undefined) {
      this.sharedInstance = new SentryCrashReporter();
    }

    return this.sharedInstance;
  }

  start(overrides: SentryOptions = {}) {
    const environment = getNodeEnvironment();

    if (environment === 'local' || environment === 'e2e') {
      return;
    }

    Sentry.init({
      ...overrides,
      dsn: 'https://7cd0dd59fdb842798b1d0ca5a7f4e441@buggy.apps.lego.com/82',
      integrations: [new Integrations.BrowserTracing()],
      environment,
    });
  }

  captureException({
    context = {},
    exception,
    fingerprint,
    tags,
  }: {
    exception: Error;
    context?: Partial<SentryContext>;
    fingerprint?: string[];
    tags?: {
      [key: string]: SentryPrimitive;
    };
  }): string {
    return Sentry.captureException(redactValues(exception), {
      ...context,
      fingerprint,
      tags,
    });
  }

  captureEvent(event: SentryEvent): string {
    return Sentry.captureEvent(redactValues(event));
  }

  addBreadcrumb(breadcrumb: SentryBreadcrumb) {
    Sentry.addBreadcrumb(redactValues(breadcrumb));
  }

  addBlocEvent(blocName: string, eventType: string, data: any) {
    this.addBreadcrumb({
      category: 'BlocEvent',
      message: `${eventType} => ${blocName}`,
      data: {
        event: {
          type: eventType,
          data,
        },
      },
    });
  }

  addEventProcessor(processor: (event: SentryEvent) => SentryEvent) {
    Sentry.configureScope((scope) => scope.addEventProcessor(processor));
  }
}

export const getCrashReporter = (): CrashReporter =>
  SentryCrashReporter.sharedReporter;
