import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Typography } from '@mui/material';
import { ComponentProps, FC, useState } from 'react';
import { useGMMutation } from '../../apollo/customApolloHooks';
import { useTranslation } from '../../utility/i18n/translation';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { useGMSnackbar } from '../../utility/snackbar';
import { useUpdateEquipmentLocationMutations } from '../shared/EquipmentLocationSection';
import { SimpleDialog } from '../shared/SimpleDialog';
import { TD_ASSIGN_MUTATION, TD_UNASSIGN_MUTATION } from './TDAssignedTo';
import {
  ChangeLocationComponent,
  LocationUpdate,
} from './TDUpdateLocationDialog';
import { AssignToMe, AssignToMeVariables } from './__apollo__/AssignToMe';
import { TDAssignmentDialogFragment } from './__apollo__/TDAssignmentDialogFragment';
import {
  UnassignTicket,
  UnassignTicketVariables,
} from './__apollo__/UnassignTicket';

export const TDAssignmentDialog: FC<{
  data: TDAssignmentDialogFragment;
  open: boolean;
  onDismiss: () => void;
  variant: 'assign' | 'unassign';
}> = ({ open, onDismiss, data, variant }) => {
  const {
    ticket: { canEditEquipmentLocation },
  } = usePermissions();

  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const { loading: locationUpdatesLoading, updateEquipmentLocationData } =
    useUpdateEquipmentLocationMutations(
      data.equipment?.__typename === 'EquipmentValue'
        ? data.equipment.value.id
        : undefined
    );
  const [locationUpdate, setLocationUpdate] = useState<LocationUpdate>();

  const {
    assign: { assignLoading, assignMutation },
    unassign: { unassignLoading, unassignMutation },
  } = useAssignmentMutations();

  const loading = locationUpdatesLoading || assignLoading || unassignLoading;

  const onSavePressed = async () => {
    if (hasValue(locationUpdate) && !locationUpdate.sublocationInputValid) {
      showSnack({
        message: translate(
          'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.INPUT_INVALID_SNACK',
          'Location input not valid'
        ),
        variant: 'warning',
      });
    } else {
      if (hasValue(locationUpdate) && locationUpdate.sublocationInputValid) {
        await updateEquipmentLocationData({
          ...locationUpdate,
          sublocationInputValid: locationUpdate.sublocationInputValid,
        });
      }
      if (variant === 'assign') {
        await assignMutation({ variables: { input: { ticketId: data.id } } });
      }
      if (variant === 'unassign') {
        await unassignMutation({ variables: { input: { ticketId: data.id } } });
      }
      onDismiss();
    }
  };

  const content: ComponentProps<typeof SimpleDialog>['content'] =
    data.equipment?.__typename === 'EquipmentValue' &&
    data.equipment?.value.__typename !== 'Mould' &&
    canEditEquipmentLocation(data.equipment.value)
      ? {
          type: 'node',
          node: (
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography fontStyle={{ fontWeight: '700' }}>
                  {translate(
                    'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.SUBTITLE',
                    'Location'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {translate(
                    'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.LOCATION_QUESTION',
                    'Is the location correct?'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                {data.equipment?.__typename === 'EquipmentValue' && (
                  <ChangeLocationComponent
                    currentLocationUpdateState={locationUpdate}
                    data={data.equipment.value}
                    saveLocationCallback={setLocationUpdate}
                  />
                )}
              </Grid>
            </Grid>
          ),
        }
      : {
          type: 'text',
          body:
            variant === 'assign'
              ? translate(
                  'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.ASSIGN_TO_YOU',
                  'Assign the ticket to you?'
                )
              : translate(
                  'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.UNASSIGN',
                  'Are you sure you want to unassign the ticket?'
                ),
        };

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={open}
      title={
        variant === 'assign'
          ? translate(
              'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.TITLE',
              'Assign to me'
            )
          : translate(
              'TICKET_DETAILS.CARDS.UNASSIGN_DIALOG.TITLE',
              'Unassign ticket'
            )
      }
      secondaryAction={{
        secondaryActionLabel: translate(
          'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.CANCEL_BUTTON',
          'Cancel'
        ),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      primaryAction={{
        primaryActionLabel:
          variant === 'assign'
            ? translate(
                'TICKET_DETAILS.CARDS.ASSIGN_DIALOG.SAVE_BUTTON',
                'Save & Assign'
              )
            : translate(
                'TICKET_DETAILS.CARDS.UNASSIGN_DIALOG.SAVE_BUTTON',
                'Save & Unassign'
              ),
        primaryActionPressed: onSavePressed,
        primaryActionLoading: loading,
      }}
      content={content}
      maxWidth={'lg'}
    />
  );
};

export const useAssignmentMutations = () => {
  const [assignMutation, { loading: assignLoading }] = useGMMutation<
    AssignToMe,
    AssignToMeVariables
  >(TD_ASSIGN_MUTATION);

  const [unassignMutation, { loading: unassignLoading }] = useGMMutation<
    UnassignTicket,
    UnassignTicketVariables
  >(TD_UNASSIGN_MUTATION);

  return {
    assign: {
      assignMutation,
      assignLoading,
    },
    unassign: {
      unassignMutation,
      unassignLoading,
    },
  };
};
