import { Grid } from '@mui/material';
import { FC } from 'react';
import { useAddTimeContext } from '../../contexts/add-time/add-time-context';
import { useTranslation } from '../../utility/i18n/translation';
import { FlowHeaderWithDiscardDialog } from '../shared/FlowHeaderWithDiscardDialog';

export const AddTimeHeader: FC = () => {
  const { translate } = useTranslation();
  const { state } = useAddTimeContext();

  const totalTimeInAdditions = state.additions
    .map((addition) => addition.minutesSpent)
    .reduce((prev, curr) => prev + curr, 0);

  const dirty =
    (state.additions.length > 0 && totalTimeInAdditions > 0) ||
    state.deletions.length > 0;

  return (
    <Grid style={{ marginBottom: 60 }}>
      <FlowHeaderWithDiscardDialog
        dirty={dirty}
        title={translate('ADD_TIME.HEADER.TITLE', 'Add time spent')}
      />
    </Grid>
  );
};
