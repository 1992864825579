import { Ref, useCallback, useState } from 'react';

export const useClientRect = <E extends HTMLElement>(): [
  rect: DOMRect | undefined,
  ref: Ref<E>
] => {
  const [rect, setRect] = useState<DOMRect>();
  const ref = useCallback((node: E | null) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
};
