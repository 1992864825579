import { FC, ReactNode } from 'react';

export interface GMTabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export const GMTabPanel: FC<GMTabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
};
