/**
 * @generated SignedSource<<3c78e54554caf5af3f9e80a099b32116>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryTab_equipment$data = {
  readonly ticketConnections: {
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCountsWidget_ticketsConnection" | "EquipmentHistoryFilters_ticketsConnection">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryList_equipment">;
  readonly " $fragmentType": "EquipmentHistoryTab_equipment";
};
export type EquipmentHistoryTab_equipment$key = {
  readonly " $data"?: EquipmentHistoryTab_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryTab_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "filters"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryTab_equipment",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryList_equipment"
        }
      ]
    },
    {
      "alias": "ticketConnections",
      "args": (v0/*: any*/),
      "concreteType": "TicketsConnection",
      "kind": "LinkedField",
      "name": "tickets",
      "plural": false,
      "selections": [
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EquipmentHistoryCountsWidget_ticketsConnection"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EquipmentHistoryFilters_ticketsConnection"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "35ac3512ddd2e002b421524373389ef8";

export default node;
