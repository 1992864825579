import { hasValue } from '@lego/mst-error-utilities';
import { Card, CardActionArea, CardContent, Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, ReactElement } from 'react';
import { useFragment } from 'react-relay';
import { NavLink } from 'react-router-dom';
import { ChipWithDefaultBackground } from '../../components/ticket-card/components/TicketCardMouldChips';
import { AppRoutes } from '../../Router';
import { Icons } from '../../utility/icons';
import { EquipmentLocation } from '../components/equipment/EquipmentLocation';
import { PriorityPill } from '../components/ticket/PriorityPill';
import { RequiredDates } from '../components/ticket/RequiredDates';
import { TicketNumber } from '../components/ticket/TicketNumber';
import { skeletonify } from '../skeleton';
import { EquipmentHistoryCardDescription } from './EquipmentHistoryCardDescription';
import { EquipmentHistoryCardRepairDocumentation } from './EquipmentHistoryCardRepairDocumentation';
import { useLabels } from './useLabels';
import { EquipmentHistoryCard_ticket$key } from './__generated__/EquipmentHistoryCard_ticket.graphql';
import { useLabels_ticket$key } from './__generated__/useLabels_ticket.graphql';

type StructureComponentProps = {
  routeTo?: string;
  priority: ReactElement;
  ticketNumber: ReactElement;
  description: ReactElement;
  closedDate: ReactElement;
  equipmentLocation?: ReactElement;
  repairDocumentation?: ReactElement;
  chips: ReactElement;
};

const ActualComponent = (props: {
  ticket: EquipmentHistoryCard_ticket$key;
}) => {
  const { ticket: ticketRef } = props;

  const ticket = useFragment(
    graphql`
      fragment EquipmentHistoryCard_ticket on Ticket {
        __typename
        ticketNumber
        equipment {
          ...EquipmentLocation_equipment
        }
        repairDocumentation {
          ...EquipmentHistoryCardRepairDocumentation_repairDocumentation
        }
        ...useLabels_ticket
        ...PriorityPill_ticket
        ...TicketNumber_ticket
        ...RequiredDates_ticket
        ...EquipmentHistoryCardDescription_ticket
      }
    `,
    ticketRef
  );

  return {
    routeTo: ticket.ticketNumber
      ? `${AppRoutes.tickets.byTicketNumber(ticket.ticketNumber, 'details')}`
      : undefined,
    priority: <PriorityPill ticket={ticket} />,
    ticketNumber: <TicketNumber ticket={ticket} />,
    description: <EquipmentHistoryCardDescription ticket={ticket} />,
    closedDate: <RequiredDates ticket={ticket} />,
    equipmentLocation: ticket.equipment ? (
      <EquipmentLocation equipment={ticket.equipment} />
    ) : undefined,
    repairDocumentation: ticket.repairDocumentation ? (
      <EquipmentHistoryCardRepairDocumentation
        repairDocumentation={ticket.repairDocumentation}
      />
    ) : undefined,
    chips: <Chips ticket={ticket} />,
  };
};

const SkeletonComponent = () => ({
  priority: <PriorityPill.Skeleton />,
  ticketNumber: <TicketNumber.Skeleton />,
  description: <EquipmentHistoryCardDescription.Skeleton />,
  closedDate: <RequiredDates.Skeleton />,
  equipmentLocation: <EquipmentLocation.Skeleton />,
  repairDocumentation: <EquipmentHistoryCardRepairDocumentation.Skeleton />,
  chips: <></>,
});

const StructureComponent = (props: StructureComponentProps) => {
  const {
    routeTo,
    priority,
    ticketNumber,
    description,
    closedDate,
    equipmentLocation,
    repairDocumentation,
    chips,
  } = props;
  return (
    <Card sx={{ flex: 1, p: 0 }}>
      <CardActionArea
        component={NavLink}
        to={routeTo ?? ''}
        disabled={!hasValue(routeTo)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Grid container direction="row" sx={{ p: 2 }}>
          {/* Prio pill */}
          <Grid item container direction="column" xs={1} sx={{ mr: 1 }}>
            <Grid item xs={6}>
              {priority}
            </Grid>
          </Grid>

          {/* Main content (descriptions, ids) */}
          <Grid item xs>
            <Grid
              container
              item
              direction="column"
              spacing={2}
              alignItems="flex-start"
            >
              <Grid item>{ticketNumber}</Grid>
              <Grid item>{description}</Grid>
            </Grid>
          </Grid>

          {/* Date and place */}
          <Grid item xs={3}>
            <Grid
              container
              direction="column"
              alignItems="flex-end"
              justifyContent="space-between"
              spacing={2}
              sx={{ height: '100%' }}
            >
              <Grid item>{closedDate}</Grid>
              {hasValue(equipmentLocation) && (
                <Grid item>{equipmentLocation}</Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
      <CardContent>
        <Grid container item direction="row">
          <Grid item xs={1} />
          <Grid container item direction="column" spacing={2} xs>
            {hasValue(repairDocumentation) && (
              <Grid item>{repairDocumentation}</Grid>
            )}
            <Grid item>{chips}</Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Chips: FC<{ ticket: useLabels_ticket$key }> = ({ ticket }) => {
  const { sparePartLabel, timeLabel, costLabel } = useLabels(ticket);

  return (
    <Grid container spacing={1}>
      <Grid item>
        {costLabel && (
          <ChipWithDefaultBackground
            icon={<Icons.MoneyBag />}
            label={costLabel}
          />
        )}
      </Grid>
      <Grid item>
        {timeLabel && (
          <ChipWithDefaultBackground
            icon={<Icons.Calendar />}
            label={timeLabel}
          />
        )}
      </Grid>
      <Grid item>
        {sparePartLabel && (
          <ChipWithDefaultBackground
            icon={<Icons.SparePartCogs />}
            label={sparePartLabel}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const EquipmentHistoryCard = skeletonify(
  'EquipmentHistoryCard',
  ActualComponent,
  SkeletonComponent,
  StructureComponent
);
