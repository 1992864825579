import { FormGroup, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../utility/i18n/translation';
import { TicketSearchBooleanFilterWithFacet } from './TicketSearchBooleanFilterComponents';
import { useTicketSearchFilterContext } from './TicketSearchFilters';
import {
  TicketSearchPriorityFilter_ticketsConnectionFacets$data,
  TicketSearchPriorityFilter_ticketsConnectionFacets$key,
} from './__generated__/TicketSearchPriorityFilter_ticketsConnectionFacets.graphql';
import { TicketsPriorityInput } from './__generated__/TicketSearchQuery.graphql';

type Facets = NonNullable<
  TicketSearchPriorityFilter_ticketsConnectionFacets$data['priority']
>;

const Priorities: TicketsPriorityInput[] = [
  'Priority1',
  'Priority2',
  'Priority3',
  'Priority4',
  'Priority5',
  'Priority6',
  'Priority7',
  'Priority8',
  'Priority9',
];

export interface TicketSearchPriorityFilterProps {
  ticketsConnectionFacets: TicketSearchPriorityFilter_ticketsConnectionFacets$key | null;
}

export const TicketSearchPriorityFilter: FC<TicketSearchPriorityFilterProps> = (
  props
) => {
  const { ticketsConnectionFacets: facetsRef } = props;
  const data = useFragment(
    graphql`
      fragment TicketSearchPriorityFilter_ticketsConnectionFacets on TicketsConnectionFacets {
        priority {
          count
          value
        }
      }
    `,
    facetsRef
  );

  const {
    filters: { priority: selectedPriorities = [] },
    onChange,
  } = useTicketSearchFilterContext();
  const facets =
    data?.priority?.map(({ count, value }) => ({ count, value })) ?? [];

  const handleChange =
    (priority: TicketsPriorityInput) => (enabled: boolean) => {
      const filteredPriorities =
        selectedPriorities?.filter((p) => p !== priority) ?? [];
      onChange((prev) => ({
        ...prev,
        priority: enabled
          ? filteredPriorities.concat(priority)
          : filteredPriorities,
      }));
    };

  const { translate } = useTranslation();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('TICKET_LIST.FILTERS.PRIORITY.TITLE', 'Priority')}
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <FormGroup>
          {Priorities.map((priority) => (
            <TicketSearchBooleanFilterWithFacet<Facets[0]>
              key={priority}
              label={priority.substring('Priority'.length)}
              enabled={selectedPriorities.includes(priority)}
              onChange={handleChange(priority)}
              facetValue={{ facets: facets, value: priority }}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};
