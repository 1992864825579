import { Box, Grid, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { CollapsibleWidget } from '../../components/shared/CollapsibleWidget';
import { Icons } from '../../utility/icons';
import { TicketRepairDocumentationStandardRow } from './TicketRepairDocumentationStandardRow';
import { TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$key } from './__generated__/TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation.graphql';

export const TicketRepairDocumentationPackingRow: FC<{
  subEquipmentRepairDocumentation: TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$key;
  padding?: number;
}> = ({
  subEquipmentRepairDocumentation: subEquipmentRepairDocumentationRef,
  padding,
}) => {
  const { description, components } = useFragment(
    graphql`
      fragment TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation on SubEquipmentRepairDocumentation {
        description
        components {
          description
          damages {
            ...TicketRepairDocumentationStandardRow_damage
          }
        }
      }
    `,
    subEquipmentRepairDocumentationRef
  );

  return (
    <CollapsibleWidget
      componentWhenClosed={<RowHeader title={description} />}
      padding={padding ?? 8}
    >
      <Grid
        container
        style={{ paddingLeft: 20, paddingRight: 40, marginBottom: 20 }}
        spacing={2}
      >
        <Grid item>
          <Grid container direction="column" spacing={1} borderLeft={1}>
            {components.map((component, index) => {
              return (
                <Grid
                  key={`${index}:${component}`}
                  container
                  direction="row"
                  sx={{ mt: 2 }}
                >
                  {/* Horizontal divider */}
                  <Grid item>
                    <Box
                      width={25}
                      borderTop={1}
                      sx={{
                        mt: 1.5,
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ ml: 2, flex: 1 }}>
                    <Grid item>
                      <Typography>{component.description}</Typography>
                    </Grid>

                    <Grid sx={{ mt: 1 }}>
                      {component.damages.map((damage, index) => {
                        return (
                          <TicketRepairDocumentationStandardRow
                            isCollapsible={false}
                            key={`${index}:${damage}`}
                            damage={damage}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </CollapsibleWidget>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid container spacing={2} style={{ flex: 1 }}>
      <Grid item>
        <Icons.SubEquipment />
      </Grid>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
    </Grid>
  );
};
