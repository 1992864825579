import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAreaAndProcessContext } from '../../../contexts/area';
import { useTranslation } from '../../../utility/i18n/translation';
import { SparePartDetailsDialogQueryContainer } from './SparePartDetailsDialogQueryContainer';

export const SparepartDetailsDialog: FC<{
  sparePartId: string;
  open: boolean;
  onCloseClicked: () => void;
}> = ({ onCloseClicked, open, sparePartId }) => {
  const { translate } = useTranslation();
  const { relayProcessId } = useAreaAndProcessContext();
  const theme = useTheme();

  return (
    <Dialog open={open} maxWidth="xl" fullWidth onClose={onCloseClicked}>
      <Grid container flexDirection="row" justifyContent={'space-between'}>
        <Grid item sx={{ pt: 2, pl: 2 }}>
          <Typography variant="subtitle1">
            {translate('SPAREPART_DETAILS.TITLE', 'Spare part details')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onCloseClicked}>
            <CloseIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 2, overflowY: 'scroll' }}>
        {relayProcessId ? (
          <SparePartDetailsDialogQueryContainer
            sparePartId={sparePartId}
            processId={relayProcessId}
          />
        ) : (
          <Typography color={theme.palette.error.main}>
            {translate(
              'SPAREPART_DETAILS.NO_PROCESS',
              'No process selected, unable to show spare part details'
            )}
          </Typography>
        )}
      </Grid>
    </Dialog>
  );
};
