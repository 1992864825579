import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from '../../utility/i18n/translation';

interface Props {
  submit: (assign: boolean) => void;
  loading: boolean;
  disabled?: boolean;
}

export const CmsTicketSubmitButtons: FC<Props> = ({
  submit,
  loading,
  disabled,
}) => {
  const { translate } = useTranslation();
  const { isRepairman } = useAuthContext();

  const handleSubmit = () => {
    submit(false);
  };

  const handleSubmitWithAssign = () => {
    submit(true);
  };

  return (
    <Grid container alignItems="center" justifyContent="end">
      {loading && (
        <Grid item mr={2}>
          <ActivityIndicator />
        </Grid>
      )}
      {isRepairman && (
        <Grid item mr={2}>
          <Button
            disabled={loading || disabled}
            size="large"
            color="primary"
            variant="outlined"
            onClick={handleSubmitWithAssign}
            sx={{
              borderWidth: '2px',
              '&:hover': { borderWidth: '2px' },
              '&:disabled': { borderWidth: '2px' },
            }}
          >
            {translate(
              'CREATE_TICKET.CREATE_ASSIGN',
              'Create and assign to me'
            )}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          disabled={loading || disabled}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {translate('CREATE_TICKET.CREATE', 'Create')}
        </Button>
      </Grid>
    </Grid>
  );
};
