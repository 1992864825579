import { hasValue } from '@lego/mst-error-utilities';
import { Grid } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { usePaginationFragment } from 'react-relay';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { skeletonify } from '../skeleton';
import { TicketList } from '../ticket-list/TicketList';
import { OpenTicketsList_baseEquipment$key } from './__generated__/OpenTicketsList_baseEquipment.graphql';

const ActualComponent: FC<{ equipment: OpenTicketsList_baseEquipment$key }> = (
  props
) => {
  const { equipment: equipmentRef } = props;

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(
    graphql`
      fragment OpenTicketsList_baseEquipment on Equipment
      @refetchable(queryName: "OpenTicketsListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "ID" }
      ) {
        openTickets: tickets(
          first: $first
          after: $after
          input: { status: [Released, NotStarted, InProgress] }
        ) @connection(key: "OpenTickets_openTickets") {
          edges {
            node {
              ...TicketList_ticket
            }
          }
        }
      }
    `,
    equipmentRef
  );

  const { openTickets } = data;

  const tickets = openTickets?.edges.filter(hasValue).map(({ node }) => node);

  return (
    <TicketList
      disableOverscrolling={true}
      tickets={tickets}
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
    />
  );
};

const SkeletonComponent: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid item xs alignSelf="center">
      <ActivityIndicator />
    </Grid>
  </Grid>
);

export const OpenTicketsList = skeletonify(
  'OpenTicketsList',
  ActualComponent,
  SkeletonComponent
);
