import { gql } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { useTranslation } from '../../utility/i18n/translation';
import {
  EquipmentLocationSection,
  EQUIPMENT_LOCATION_FRAGMENT,
} from '../shared/EquipmentLocationSection';
import { isEquipmentSublocationUpdateValid } from '../ticket-details/TDUpdateLocationDialog';
import {
  UpdateLocationFragment,
  UpdateLocationFragment_equipment_EquipmentValue_value,
} from './__apollo__/UpdateLocationFragment';

export const UPDATE_LOCATION_FRAGMENT = gql`
  fragment UpdateLocationFragment on Ticket {
    id
    equipment {
      ... on EquipmentValue {
        value {
          id
          ...EquipmentLocationFragment
        }
      }
    }
  }
  ${EQUIPMENT_LOCATION_FRAGMENT}
`;

export const CTUpdateLocation: FC<UpdateLocationFragment> = (props) => {
  const { translate } = useTranslation();

  if (!props.equipment || props.equipment.__typename === 'NotFound') {
    return (
      <Typography color="error">
        {translate(
          'CLOSE_TICKET.LOCATION_STEP.ERROR',
          'Could not find equipment'
        )}
      </Typography>
    );
  }

  return <Checked {...props.equipment.value} />;
};

const Checked: FC<UpdateLocationFragment_equipment_EquipmentValue_value> = (
  data
) => {
  const { translate } = useTranslation();

  const {
    state: {
      location: {
        newSublocationText,
        newLocationId,
        newSublocationId,
        sublocationDirty,
      },
    },
    dispatch,
  } = useCloseTicketContext();

  useEffect(() => {
    const valid = isEquipmentSublocationUpdateValid({
      isSublocationMandatory: data.sublocationMetaInfo.isSublocationMandatory,
      sublocationData: data.subLocation,
      sublocationDirty: sublocationDirty ?? false,
      newSublocationText,
    });
    dispatch({ type: 'setSublocationInputValid', valid });
  }, [
    data.subLocation,
    data.sublocationMetaInfo.isSublocationMandatory,
    dispatch,
    newSublocationText,
    sublocationDirty,
  ]);

  const onLocationSelected = (newLocationId: string) => {
    dispatch({ type: 'chooseNewLocation', locationId: newLocationId });
  };

  const onSublocationSelected = (newSublocation: string) => {
    dispatch({ type: 'chooseNewSublocation', sublocationId: newSublocation });
  };

  const onSublocationFreeTextUpdated = (newText: string) => {
    dispatch({ type: 'setNewSublocationText', sublocationText: newText });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">
          {translate('CLOSE_TICKET.LOCATION_STEP.CARD_HEADER', 'Location')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {translate(
            'CLOSE_TICKET.LOCATION_STEP.QUESTION',
            'Is the location of the equipment correct?'
          )}
        </Typography>
      </Grid>
      <Grid item>
        <EquipmentLocationSection
          {...data}
          onLocationSelected={onLocationSelected}
          onSublocationSelected={onSublocationSelected}
          onSublocationTextUpdated={onSublocationFreeTextUpdated}
          initialSublocationText={newSublocationText}
          initialLocationId={newLocationId}
          initialSublocationId={newSublocationId}
        />
      </Grid>
    </Grid>
  );
};
