import { Box, Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import {
  CARD_DATE_FORMAT,
  useDateFromMiddlewareWithLocale,
} from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { ErrorDescription_ticket$key } from './__generated__/ErrorDescription_ticket.graphql';

const ActualComponent: FC<{ ticket: ErrorDescription_ticket$key }> = (
  props
) => {
  const { ticket: ticketRef } = props;
  const { errorDescription } = useFragment(
    graphql`
      fragment ErrorDescription_ticket on Ticket
      @argumentDefinitions(
        includeTimestamp: { type: "Boolean", defaultValue: false }
      ) {
        errorDescription {
          description
          timestamp @include(if: $includeTimestamp) {
            date
            time
          }
        }
      }
    `,
    ticketRef
  );

  const { format } = useDateFromMiddlewareWithLocale();
  const { translate } = useTranslation();

  return (
    <Grid container direction="column">
      {errorDescription?.map(({ description, timestamp }, index) => (
        <Grid item key={index}>
          <Typography>{description}</Typography>
          {timestamp && (
            <Typography variant="caption" color="textSecondary">
              {`${translate('MISC.CREATED_PREFIX', 'Created {{ timestamp }}', {
                timestamp: format(
                  `${timestamp.date}T${timestamp.time}`,
                  CARD_DATE_FORMAT
                ),
              })}`}
            </Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

const SkeletonComponent: FC = () => (
  <Box sx={{ flex: 1 }}>
    <Skeleton width={75} />
    <Skeleton width={75} height={20} />
  </Box>
);

export const ErrorDescription = skeletonify(
  'ErrorDescription',
  ActualComponent,
  SkeletonComponent
);
