/**
 * @generated SignedSource<<45688d46d85c486cdcabd9cd609fb8c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProcessSparePartSearchInput = {
  searchTerm?: string | null;
};
export type SparePartSearchQuery$variables = {
  input: ProcessSparePartSearchInput;
  processId: string;
};
export type SparePartSearchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SparePartSearchList_process">;
  } | null;
};
export type SparePartSearchQuery = {
  response: SparePartSearchQuery$data;
  variables: SparePartSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "processId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "processId"
  }
],
v2 = {
  "kind": "Variable",
  "name": "input",
  "variableName": "input"
},
v3 = {
  "kind": "Variable",
  "name": "processId",
  "variableName": "processId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "Defer",
                "selections": [
                  {
                    "args": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "SparePartSearchList_process"
                  }
                ]
              }
            ],
            "type": "Process",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SparePartSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "if": null,
                "kind": "Defer",
                "label": "SparePartSearchQuery$defer$SparePartSearchList_process_15yJ1M",
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "SparePartSearchConnection",
                    "kind": "LinkedField",
                    "name": "sparePartSearch",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SparePartSearchEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SparePart",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "fields": [
                                      (v3/*: any*/)
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "input"
                                  }
                                ],
                                "concreteType": "SparePartProcurementInfo",
                                "kind": "LinkedField",
                                "name": "procurementInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "inStock",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "model",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "uri",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sparePartNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ean",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "filters": [
                      "input"
                    ],
                    "handle": "connection",
                    "key": "SparePartSearch_sparePartSearch",
                    "kind": "LinkedHandle",
                    "name": "sparePartSearch"
                  },
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "Process",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc280abd9f8303f6fc5c8ab7dc417855",
    "id": null,
    "metadata": {},
    "name": "SparePartSearchQuery",
    "operationKind": "query",
    "text": "query SparePartSearchQuery(\n  $input: ProcessSparePartSearchInput!\n  $processId: ID!\n) {\n  node(id: $processId) {\n    __typename\n    ... on Process {\n      ...SparePartSearchList_process_15yJ1M @defer(label: \"SparePartSearchQuery$defer$SparePartSearchList_process_15yJ1M\")\n    }\n    id\n  }\n}\n\nfragment BomSparePartRow_sparePart_cvzgy on SparePart {\n  id\n  description\n  model\n  image {\n    uri\n  }\n  sparePartNumber\n  procurementInfo(input: {processId: $processId}) {\n    inStock\n  }\n  ean\n}\n\nfragment SparePartSearchList_process_15yJ1M on Process {\n  sparePartSearch(first: 30, input: $input) {\n    edges {\n      node {\n        id\n        procurementInfo(input: {processId: $processId}) {\n          inStock\n        }\n        ...BomSparePartRow_sparePart_cvzgy\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "f98ff9c36a78606fc7ffd60ac9883d08";

export default node;
