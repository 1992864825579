import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
import { SimpleDialog } from '../../components/shared/SimpleDialog';
import { localeToRelayLanguage } from '../../utility/i18n/i18n';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { PrintDialogContent } from './PrintDialogContent';
import { PrintSparePartsLabelDialogMutation } from './__generated__/PrintSparePartsLabelDialogMutation.graphql';

export const PrintSparePartLabelDialog: FC<{
  open: boolean;
  onDismiss: () => void;
  sparePartId: string;
  storageLocationId: string;
}> = ({ onDismiss, open, sparePartId, storageLocationId }) => {
  const { translate, locale } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const [chosenPrinterId, setChosenPrinterId] = useState<string | undefined>(
    undefined
  );

  const [printMutation, loading] =
    useMutation<PrintSparePartsLabelDialogMutation>(graphql`
      mutation PrintSparePartsLabelDialogMutation(
        $input: MutationPrintSparePartLabelInput!
      ) {
        printSparePartLabel(input: $input) {
          ... on MutationPrintSparePartLabelSuccess {
            __typename
          }
        }
      }
    `);

  const onPrintPressed = useCallback(() => {
    if (chosenPrinterId && storageLocationId) {
      printMutation({
        variables: {
          input: {
            sparePartId,
            storageLocationId,
            printerId: chosenPrinterId,
            language: localeToRelayLanguage(locale),
          },
        },
        onCompleted: () => {
          showSnack({
            message: translate(
              'SPAREPART_PRINT_DIALOG.ON_SUCCESS',
              'Printing label on selected printer'
            ),
            variant: 'success',
          });
          setChosenPrinterId(undefined);
          onDismiss();
        },
      });
    }
  }, [
    chosenPrinterId,
    locale,
    onDismiss,
    printMutation,
    showSnack,
    sparePartId,
    storageLocationId,
    translate,
  ]);

  return (
    <SimpleDialog
      onDismiss={onDismiss}
      open={open}
      title={translate(
        'SPAREPART_PRINT_DIALOG.TITLE',
        'Print spare part label'
      )}
      secondaryAction={{
        secondaryActionLabel: translate(
          'SPAREPART_PRINT_DIALOG.CANCEL_BUTTON',
          'Cancel'
        ),
        secondaryActionPressed: onDismiss,
        secondaryActionDisabled: loading,
      }}
      primaryAction={{
        primaryActionLabel: translate(
          'SPAREPART_PRINT_DIALOG.PRINT_BUTTON',
          'Print'
        ),
        primaryActionPressed: onPrintPressed,
        primaryActionLoading: loading,
      }}
      content={{
        type: 'node',
        node: <PrintDialogContent onPrinterSelected={setChosenPrinterId} />,
      }}
      maxWidth={'sm'}
    />
  );
};
