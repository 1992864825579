import { gql } from '@apollo/client';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import graphql from 'babel-plugin-relay/macro';
import {
  CSSProperties,
  FC,
  Fragment,
  MouseEvent,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { useFragment, useMutation } from 'react-relay';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes, RouteTypes } from '../../Router';
import { getTimestampForRelay } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import {
  PERMISSIONS_ALLOWED_TO_MANAGE_TICKET,
  PERMISSIONS_CAN_ADD_ERROR_DESCRIPTION,
  PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION,
  PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION,
  PERMISSIONS_CAN_ADD_TIME_REGISTRATION,
  PERMISSIONS_CAN_CLOSE_FRAGMENT,
  PERMISSIONS_CAN_EDIT_TICKET_PRIORITY,
  PERMISSIONS_CAN_UNSASSIGN,
} from '../../utility/permissions/ticket';
import { usePermissions } from '../../utility/permissions/usePermissions';
import { useGMSnackbar } from '../../utility/snackbar';
import { ActivityIndicator } from '../shared/ActivityIndicator';
import {
  ALLOWED_TICKET_PRIORITIES,
  TicketTypeEnum,
} from './change-priority/ChangePriorityUtils';
import { ChangePriorityDialog } from './change-priority/TDChangePriorityDialog';
import { AddErrorDescriptionDialog } from './TDAddErrorDescriptionDialog';
import { TD_ASSIGNMENT_DIALOG_FRAGMENT } from './TDAssignmentDialogFragment';
import { TDAssignmentDialog } from './TDAssignmentDialogs';
import { TD_ERROR_DESCRIPTION_FRAGMENT } from './TDErrorDescription';
import { TicketDetailsFabFragment } from './__apollo__/TicketDetailsFabFragment';
import { TDMenuFragment$key } from './__generated__/TDMenuFragment.graphql';
import { TDMenuToggleStatusCodeMutation } from './__generated__/TDMenuToggleStatusCodeMutation.graphql';

export const TICKET_DETAILS_FAB_FRAGMENT = gql`
  fragment TicketDetailsFabFragment on Ticket {
    id
    isCmsTicket
    isOpen
    ...CanAddRepairDescription
    ...CanAddTimeRegistration
    ...IsAllowedToManageTicket
    ...CanCloseMouldGreenTicket
    ...TDAssignmentDialogFragment
    ...CanAddErrorDescription
    ...TicketDetailsErrorDescription
    ...CanUnassign
    ...CanEditTicketPriority
    ...CanAddMachineTimeRegistration
  }
  ${PERMISSIONS_CAN_EDIT_TICKET_PRIORITY}
  ${PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION}
  ${PERMISSIONS_CAN_ADD_TIME_REGISTRATION}
  ${PERMISSIONS_ALLOWED_TO_MANAGE_TICKET}
  ${PERMISSIONS_CAN_ADD_ERROR_DESCRIPTION}
  ${PERMISSIONS_CAN_CLOSE_FRAGMENT}
  ${TD_ASSIGNMENT_DIALOG_FRAGMENT}
  ${TD_ERROR_DESCRIPTION_FRAGMENT}
  ${PERMISSIONS_CAN_UNSASSIGN}
  ${PERMISSIONS_CAN_ADD_MACHINE_TIME_REGISTRATION}
`;

const iconStyle: CSSProperties = {
  fontSize: 32,
  fill: 'black',
};

type ActionType = {
  icon: ReactElement;
  name: string;
  onPress: (ticketId: string) => void;
};

const ActionIcon: FC<{
  IconComponent: FC<SvgIconProps<'svg', unknown>>;
}> = ({ IconComponent }) => {
  return <IconComponent color="action" style={iconStyle} />;
};

const useMouldSwitchWaitingForSpareParts = (
  mouldId?: string,
  statusCode?: number
): ActionType | undefined => {
  const { translate } = useTranslation();
  const { showSnack } = useGMSnackbar();
  const isValidStatusCodeForButton =
    !!statusCode &&
    (statusCode.toString().endsWith('42') ||
      statusCode.toString().endsWith('48'));

  const name = statusCode?.toString().endsWith('48')
    ? translate(
        'TICKET_DETAILS.FAB.SPAREPART_RECEIVED',
        'Mould spare part received (x42)'
      )
    : translate(
        'TICKET_DETAILS.FAB.SPAREPART_WAITING',
        'Mould waiting for spare parts (x48)'
      );

  const [commit, isInFlight] =
    useMutation<TDMenuToggleStatusCodeMutation>(graphql`
      mutation TDMenuToggleStatusCodeMutation(
        $input: MutationMouldUpdateWaitingForSparePartsInput!
      ) {
        mouldUpdateWaitingForSpareParts(input: $input) {
          ... on MutationMouldUpdateWaitingForSparePartsSuccess {
            data {
              statusCode
            }
          }
        }
      }
    `);

  const onPress = useCallback(() => {
    if (!isInFlight && mouldId && isValidStatusCodeForButton) {
      commit({
        variables: {
          input: {
            mouldId,
            isWaitingForSpareParts: statusCode.toString().endsWith('42')
              ? true
              : false,
            updatedDate: getTimestampForRelay(),
          },
        },
        onCompleted: (_, errors) => {
          if (!errors || errors.length === 0) {
            showSnack({
              message: translate(
                'TICKET_DETAILS.FAB.CHANGED_STATUS_SNACK',
                'Mould status code changed successfully'
              ),
              variant: 'success',
            });
          }
        },
      });
    }
  }, [
    commit,
    isInFlight,
    isValidStatusCodeForButton,
    mouldId,
    showSnack,
    statusCode,
    translate,
  ]);

  if (!mouldId || !statusCode || !isValidStatusCodeForButton) {
    return undefined;
  }

  return {
    icon: isInFlight ? (
      <ActivityIndicator />
    ) : (
      <ActionIcon IconComponent={Icons.SparePartCogs} />
    ),
    name,
    onPress,
  };
};

const getTicketType = (data: TicketDetailsFabFragment): TicketTypeEnum => {
  return data.isCmsTicket
    ? TicketTypeEnum.CMS
    : isEquipmentOfTypeMould(data)
    ? TicketTypeEnum.Mould
    : TicketTypeEnum.Equipment;
};

const isEquipmentOfTypeMould = (data: TicketDetailsFabFragment): boolean => {
  return (
    data.equipment?.__typename === 'EquipmentValue' &&
    data.equipment.value.__typename === 'Mould'
  );
};

const useFabActions = ({
  data,
  openDialog,
  mouldStatusCode,
  relayMouldId,
}: {
  data: TicketDetailsFabFragment;
  openDialog: (variant: DialogVariants) => void;
  relayMouldId?: string;
  mouldStatusCode?: number;
}): ActionType[] => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const {
    ticket: {
      canAddRepairDescription,
      canAddTimeRegistration,
      canCloseTicket,
      canAssignTicket,
      canUnassignTicket,
      canEditTicketPriority,
      canAddErrorDescription,
      canAddMachineTimeRegistration,
    },
  } = usePermissions();

  const isAllowedToAddRepair = canAddRepairDescription(data);
  const isAllowedToAddTime = canAddTimeRegistration(data);
  const isAllowedToClose = canCloseTicket(data);
  const isAllowedToAssign = canAssignTicket(data) && data.isOpen;
  const isAllowedToUnassign = canUnassignTicket(data) && data.isOpen;
  const isAllowedToAddErrorDescription = canAddErrorDescription(data);
  const isAllowedToChangePriority = canEditTicketPriority(data);
  const isAllowedToAddMachineHours = canAddMachineTimeRegistration(data);

  const actions: ActionType[] = [];

  const mouldStatusCodeAction = useMouldSwitchWaitingForSpareParts(
    relayMouldId,
    mouldStatusCode
  );

  const ticketType = getTicketType(data);

  if (isAllowedToAddRepair) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.AddRepairDocs} />,
      name: translate(
        'TICKET_DETAILS.FAB.ADD_REPAIR_DOCS',
        'Add Repair Documentation'
      ),
      onPress: (ticketId: string) => {
        navigate(AppRoutes.tickets.repair(ticketId));
      },
    });
  }

  if (isAllowedToAddTime) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.Clock} />,
      name: translate('TICKET_DETAILS.FAB.ADD_TIME', 'Add/Delete Time'),
      onPress: (ticketId: string) => {
        navigate(AppRoutes.tickets.manHours(ticketId));
      },
    });
  }

  if (isAllowedToAddErrorDescription) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.AddErrorDescription} />,
      name: translate(
        'TICKET_DETAILS.FAB.ADD_ERROR_DESCRIPTION',
        'Add Error Description'
      ),
      onPress: () => {
        openDialog('addErrorDescription');
      },
    });
  }

  if (isAllowedToClose) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.CloseTicket} />,
      name: translate('TICKET_DETAILS.FAB.CLOSE_TICKET', 'Close ticket'),
      onPress: (ticketId: string) => {
        if (data.isCmsTicket) {
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'manhours'));
        } else {
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'repair'));
        }
      },
    });
  }

  if (isAllowedToAssign) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.Assign} />,
      name: translate('TICKET_DETAILS.FAB.ASSIGN_TICKET', 'Assign ticket'),
      onPress: () => {
        openDialog('assign');
      },
    });
  }

  if (isAllowedToUnassign) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.Unassign} />,
      name: translate('TICKET_DETAILS.FAB.UNASSIGN_TICKET', 'Unassign ticket'),
      onPress: () => {
        openDialog('unassign');
      },
    });
  }

  if (isAllowedToAddMachineHours) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.Clock} />,
      name: translate('TICKET_DETAILS.FAB.MACHINE_HOURS', 'Add machine hours'),
      onPress: (ticketId: string) => {
        navigate(AppRoutes.tickets.machineHours(ticketId));
      },
    });
  }

  if (
    isAllowedToChangePriority &&
    ALLOWED_TICKET_PRIORITIES[ticketType].includes(data.priority)
  ) {
    actions.push({
      icon: <ActionIcon IconComponent={Icons.Edit} />,
      name: translate('TICKET_DETAILS.FAB.CHANGE_PRIORITY', 'Change priority'),
      onPress: () => {
        openDialog('changePriority');
      },
    });
  }

  if (mouldStatusCodeAction) {
    actions.push(mouldStatusCodeAction);
  }

  return actions;
};

const TDMenuButton = styled(IconButton)(({ theme }) => ({
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.75),
  },
  marginRight: '75%',
  backgroundColor: theme.palette.primary.main,
  height: 56,
  width: 56,
}));

type DialogVariants =
  | 'assign'
  | 'unassign'
  | 'addErrorDescription'
  | 'changePriority';

export const TDMenu: FC<{
  apolloData: TicketDetailsFabFragment;
  ticket?: TDMenuFragment$key;
  onUpdate?: () => void;
}> = ({ apolloData: data, ticket: ticketRef, onUpdate }) => {
  const { id: ticketId } = useParams() as RouteTypes['ticket'];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const ticket = useFragment(
    graphql`
      fragment TDMenuFragment on Ticket {
        equipment {
          __typename
          ... on Mould {
            id
            statusCode
          }
        }
      }
    `,
    ticketRef ?? null
  );

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [unassignDialogOpen, setUnassignDialogOpen] = useState(false);
  const [errorDescriptionDialogOpen, setErrorDescriptionDialogOpen] =
    useState(false);
  const [changePriorityDialogOpen, setChangePriorityDialogOpen] =
    useState(false);

  const openDialog = useCallback((variant: DialogVariants) => {
    switch (variant) {
      case 'assign':
        setAssignDialogOpen(true);
        break;
      case 'unassign':
        setUnassignDialogOpen(true);
        break;
      case 'addErrorDescription':
        setErrorDescriptionDialogOpen(true);
        break;
      case 'changePriority':
        setChangePriorityDialogOpen(true);
    }
  }, []);

  const actions = useFabActions({
    data,
    openDialog,
    relayMouldId:
      ticket?.equipment?.__typename === 'Mould'
        ? ticket.equipment.id
        : undefined,
    mouldStatusCode:
      ticket?.equipment?.__typename === 'Mould' && ticket.equipment.statusCode
        ? ticket.equipment.statusCode
        : undefined,
  });

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const priorityChangeDialog = (
    <ChangePriorityDialog
      data={data}
      open={changePriorityDialogOpen}
      onUpdate={onUpdate}
      closeDialog={() => setChangePriorityDialogOpen(false)}
      ticketType={getTicketType(data)}
    />
  );

  if (actions.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <TDMenuButton
        onClick={handleClick}
        sx={{
          boxShadow: 3,
          zIndex: 100,
        }}
        style={{ marginTop: 70 }}
        disableTouchRipple
        data-cy="TDMenu"
      >
        {open ? <Icons.IconClose /> : <Icons.BulletList />}
      </TDMenuButton>
      <TDAssignmentDialog
        data={data}
        onDismiss={() => setAssignDialogOpen(false)}
        open={assignDialogOpen}
        variant="assign"
      />
      <TDAssignmentDialog
        data={data}
        onDismiss={() => setUnassignDialogOpen(false)}
        open={unassignDialogOpen}
        variant="unassign"
      />
      <AddErrorDescriptionDialog
        data={data}
        open={errorDescriptionDialogOpen}
        closeDialog={() => setErrorDescriptionDialogOpen(false)}
      />
      {priorityChangeDialog}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 24,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {actions.map((action, index) => {
          const showDivider = index < actions.length - 1;
          const onPress = () => {
            action.onPress(ticketId);
          };

          return (
            <div key={action.name}>
              <MenuItem
                onClick={onPress}
                style={{ justifyContent: 'flex-start' }}
                data-cy={`TDMenuItem-${action.name.replaceAll(' ', '-')}`}
              >
                <ListItemIcon>{action.icon}</ListItemIcon>
                <Typography sx={{ ml: 2 }}>{action.name}</Typography>
              </MenuItem>
              {showDivider && <Divider />}
            </div>
          );
        })}
      </Menu>
    </Fragment>
  );
};
