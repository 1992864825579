import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { FC } from 'react';

type UseSnackbarArgs = {
  message: string;
  variant: Pick<OptionsObject, 'variant'>['variant'];
  showDismissButton?: boolean;
  durationInMs?: number;
};

const DEFAULT_DURATION = 3000;

type ShowSnackType = (
  args: UseSnackbarArgs & Omit<OptionsObject, 'variant'>
) => SnackbarKey;

type HookReturnType = {
  showSnack: ShowSnackType;
};

export const useGMSnackbar = (): HookReturnType => {
  const { enqueueSnackbar } = useSnackbar();

  const action = (snackbarKey: SnackbarKey) => (
    <SnackBarDismissButton snackbarKey={snackbarKey} />
  );

  const showSnack = ({
    message,
    variant,
    durationInMs = DEFAULT_DURATION,
    showDismissButton,
  }: UseSnackbarArgs & Omit<OptionsObject, 'variant'>) => {
    return enqueueSnackbar(message, {
      autoHideDuration: durationInMs,
      variant,
      action: showDismissButton ? action : undefined,
    });
  };

  return { showSnack };
};

const SnackBarDismissButton: FC<{ snackbarKey: SnackbarKey }> = ({
  snackbarKey,
}) => {
  const { closeSnackbar } = useSnackbar();

  const onClick = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButton onClick={onClick} size="large">
      <CloseIcon style={{ color: 'white' }} />
    </IconButton>
  );
};
