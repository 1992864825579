import { hasValue } from '@lego/mst-error-utilities';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Dialog, Grid, IconButton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import { GMImageWithFallback } from '../../components/shared/GMImageComponents';
import {
  ImageRowImages_imageInformation$data,
  ImageRowImages_imageInformation$key,
} from './__generated__/ImageRowImages_imageInformation.graphql';

export const ImageRow: FC<{ images: ImageRowImages_imageInformation$key }> = ({
  images: imagesRef,
}) => {
  const [dialogInfo, setDialogInfo] = useState<
    { open: boolean; indexToOpenOn?: number } | undefined
  >();
  const openDialogOnIndex = (index: number) => {
    setDialogInfo({ open: true, indexToOpenOn: index });
  };

  const images = useFragment(
    graphql`
      fragment ImageRowImages_imageInformation on ImageInformation
      @relay(plural: true) {
        id
        image {
          uri
        }
      }
    `,
    imagesRef
  );

  return (
    <Grid container direction="row" spacing={2}>
      {dialogInfo?.open && (
        <ImageCarouselDialog
          images={images}
          onClosePressed={() => setDialogInfo({ open: false })}
          openOnIndex={dialogInfo?.indexToOpenOn}
        />
      )}
      {images.map((image, index) => {
        const onClick = () => {
          openDialogOnIndex(index);
        };

        return (
          <Grid item key={image.id} onClick={onClick}>
            <GMImageWithFallback
              url={image.image.uri}
              style={{
                height: 100,
                width: 100,
                borderRadius: 12,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const ImageCarouselDialog: FC<{
  onClosePressed: () => void;
  images: ImageRowImages_imageInformation$data;
  openOnIndex?: number;
}> = ({ images, onClosePressed, openOnIndex }) => {
  const [activeImage, setActiveImage] = useState(
    hasValue(openOnIndex) ? openOnIndex : 0
  );

  const canGoForwards = activeImage < images.length - 1;
  const canGoBackwards = activeImage > 0;

  const stepForwards = useCallback(() => {
    if (canGoForwards) {
      setActiveImage(activeImage + 1);
    }
  }, [activeImage, canGoForwards]);

  const stepBackwards = useCallback(() => {
    if (canGoBackwards) {
      setActiveImage(activeImage - 1);
    }
  }, [activeImage, canGoBackwards]);

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      switch (event.key) {
        case 'ArrowLeft':
          stepBackwards();
          break;
        case 'ArrowRight':
          stepForwards();
          break;
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [stepBackwards, stepForwards]);

  return (
    <Dialog open maxWidth="xl" fullWidth onClose={onClosePressed}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        margin="auto"
      >
        <Grid item xs={1}>
          <IconButton disabled={!canGoBackwards} onClick={stepBackwards}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          {images.length > 0 && (
            <GMImageWithFallback
              url={images[activeImage].image.uri}
              style={{
                height: '75vh',
                borderRadius: 12,
                objectFit: 'contain',
              }}
            />
          )}
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          <IconButton disabled={!canGoForwards} onClick={stepForwards}>
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};
